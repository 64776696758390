<template>
<div>
	<van-popup 
		v-model="show" 
		class="popup" 
		:close-on-click-overlay="false" 
		@click-overlay="close">
		<div ref="downloadImg" class="popup-box border-box image" >
			<!-- :style="{
				backgroundImage: 'url('+ bgImg +')'
			}">
			<p class="popup-title text-center bold">{{ title }}</p>
			<div 
				class="popup-content" 
				:style="{
					height: height
				}"> -->
				<slot></slot>
			<!-- </div> -->
		</div>
	</van-popup>
</div>
</template>

<script>
export default {
	props: {
		show: {
			type: Boolean,
			default: false
		}, // 显示弹框
		title: {
			type: String,
			default: ''
		}, // 标题
		height: {
			type: Number,
			default: 300
		}, // 白色区域高度
	},
	data() {
		return {
			bgImg: require('../assets/image/bg_blue@2x.png'),
		}
	},
	methods: {
		close() {
			this.$emit('close');
		},
		downLoad() { // 保存图片
			// console.log(this.$refs.downloadImg)
			this.api.downLoad(this.$refs.downloadImg);
		},
	}
};
</script>

<style scoped>
.popup {
	width: 320px;
	height: 487px;
	background: none;
	/*right: 1.35rem;
	left: 1.4rem;*/
	right: 50%;
	left: 50%;
	transform: translate3d(-50%,-50%,0);
}
.popup-box {
	/*background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center;
	border-radius: 1rem;
	padding: 1.7rem 1rem 1.1rem 1rem;*/
	position: relative;
}
/*.popup-title {
	font-size: 1.1rem;
	color: #fff;
}
.popup-content {
	height: 19.35rem;
	border-radius: .7rem;
	background-color: #fff;
	margin-top: .7rem;
}*/
</style>