<template>
  <div class="all_sign_in">
    <div class="white_bg">
      <!-- <div class="shadow"></div> -->

      <div class="white_top" v-if="Object.keys(signInData.top).length !== 0">

        <div class="top_title title">
          {{signInData.top.topTitle}}
        </div>
        <div class="top_item" v-for="(item, index) in signInData.top.topMessage" :key='index' >
          <div class="top_item_left grey_font">
            {{item.topLeft}}
          </div>
          <div class="top_item_right">
            {{item.topRight}}
          </div>
        </div>
        <div><van-divider :style="{ borderColor: '#F1F5F8', padding: '5px 16px' }" /></div>
      </div>
      
      <div class="white_center" v-if="Object.keys(signInData.center).length !== 0">
        <div class="center_title title">
          {{signInData.center.centerTitle}}
        </div>
        <div class="center_item" v-for="(item, index) in classData" :key='index'>
          <div class="center_item_left grey_font">
            <span v-if="item.dateType == '1'">上午</span>
            <span v-else-if="item.dateType == '2'">下午</span>
            <span v-else-if="item.dateType == '3'">晚上</span>
          </div>
          <div class="center_item_right">
            {{item.startDate}}
            &nbsp;
            至
            &nbsp;
            {{item.endDate}}
          </div>
        </div>
        <div><van-divider :style="{ borderColor: '#F1F5F8', padding: '5px 16px' }" /></div>
      </div>

      <div class="white_bottom">
        <div class="bottom_title title">
          {{signInData.bottom.bottomTitle}}
        </div>
        <van-form :model="signInData.bottom.signForm">
          <van-field
          v-if="signInData.bottom.signNameShow"
          v-model="signInData.bottom.signForm.username"
          label="姓名" 
          placeholder="请输入" 
          readonly 
          required
        />
        <van-field
          v-if="signInData.bottom.signPhoneShow"
          v-model="signInData.bottom.signForm.phoneNum"
          label="手机号" 
          placeholder="请输入" 
          readonly 
          required
        />
        <van-field
          v-if="signInData.bottom.signIdShow"
          v-model="signInData.bottom.signForm.idNum"
          label="身份证号"
          maxlength="18"
          placeholder="请输入" 
          :readonly="signInData.bottom.signForm.idDis" 
          required
        />
        <van-field name="sex" label="性别" v-if="signInData.bottom.signSexShow">
          <template #input>
            <van-radio-group v-model="signInData.bottom.signForm.sex" direction="horizontal">
              <van-radio name="0" style="margin-right: 30px">男</van-radio>
              <van-radio name="1">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-if="signInData.bottom.signCompanyShow"
          v-model="signInData.bottom.signForm.company"
          label="公司"
          placeholder="请输入" 
          :readonly="signInData.bottom.signForm.companyDis"
        />
        </van-form>
      </div>

    </div>

    <div class="time_slot" v-if="signInData.timeSlot.isShow">
      <div class="time_slot_title">
        {{signInData.timeSlot.timeSlotTitle}}
      </div>
      <div class="down_option">
        <select class="time_select" v-model="signInData.bottom.signForm.timeValue" @change="onChange">
          <option v-for="(item, index) in signInData.timeSlot.option" :key="index" :value="item.value">{{item.text}}</option>
        </select>
      </div>
    </div>

    <div class="sign_button">
      <van-button 
        class="btn_margin" 
        round 
        block 
        :disabled="disabled" 
        type="info" 
        @click="signIn"
      >
        {{signInData.button.signInBtn}}
      </van-button>
      <van-button 
        v-if="signInData.button.signUpShow" 
        class="btn_margin" 
        round 
        block 
        :disabled="disabledUp" 
        type="info" 
        @click="signUp"
      >
        {{signInData.button.signUpBtn}}
      </van-button>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }, // 签到 按钮不可点击
    disabledUp: {
      type: Boolean,
      default: false
    }, // 签退 按钮不可点击
    classData: {
      type: Array,
      default: null
    }, // 上课时间
    signInData: {
      type: Object,
      default() {
        return {
          top: {
            topTitle: '',
            topMessage: [
              {
                topLeft: '',
                topRight: ''
              }
            ]
          },

          center: {
            centerTitle: '',
            centerMessage: []
          },

          bottom: {
            bottomTitle: '',
            signNameShow: false,
            signPhoneShow: false,
            signIdShow: false,
            signSexShow: false,
            signCompanyShow: false,
            signForm: {
              username: '',
              phoneNum: '',
              idNum: '',
              company: '',
              sex: '',
              timeValue: 0
            },
          },

          timeSlot: {
            isShow: false,
            timeSlotTitle: '',
            option: [
              { text: '', value: 0 },
              { text: '', value: 1 },
              { text: '', value: 2 },
            ],
          },

          button: {
            signInBtn: '签到',
            signUpBtn: '签退',
            signUpShow: false
          },
        }
      }
    }
  },
  components: {

  },
  data() {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
    signIn() { // 签到
      this.$emit('signIn')
    },
    signUp() { // 签退
      this.$emit('signUp')
    },
    onChange() { // 切换时段
      this.$emit('onChange')
    },
  }
};
</script>

<style scoped>
div /deep/.van-field__label {
  width: 64px;
  margin-right: 19px;
  font-size: 16px;
  font-family: PingFang SC;
  line-height: 22px;
  color: #999;
  opacity: 1;
}
div /deep/.van-field__control {
  font-size: 16px;
  font-family: PingFang SC;
  line-height: 22px;
  color: #242424;
  opacity: 1;
}
div /deep/.van-cell {
  padding: 10px 0;
}
div /deep/.van-cell--required::before {
  left: -8px;
}
.white_bg {
  margin: 45% auto 30px auto;
  padding:18px 18px 10px 18px;
  width: 80%;
  background-color: #fff;
  border-radius: 13px;
  position: relative;
}
.shadow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}
.title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: #242424;
  opacity: 1;
}
.grey_font {
  font-size: 16px;
  font-family: PingFang SC;
  line-height: 22px;
  color: #999999;
  opacity: 1;
}

.top_item {
  display: flex;
  align-items: baseline;
  margin: 15px 0;
}
.top_item_left {
  width: 80px;
  flex-shrink: 0;
  /*margin-right: 26px;*/
  margin-right: 5px;
}
.top_item_right {
  /*width: 170px;*/
  width: 100%;
  font-size: 16px;
  overflow: scroll;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: #242424;
  opacity: 1;
}
.top_item_right::-webkit-scrollbar {
  display: none;
}

.center_item {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.center_item_left {
  width: 40px;
  margin-right: 30px;
}
.center_item_right {
  font-size: 12px;
  font-weight: bold;
  color: #242424;
  opacity: 1;
}
.time_slot {
  margin: 0 auto;
  width: 85%;
  display: flex;
  align-items: baseline;
  justify-content: space-between
}

.time_slot_title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: #242424;
  opacity: 1;
}
.down_option {
  width: 60%;
}
.time_select {
  width: 100%;
  height: 31px;
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  opacity: 1;
  border-radius: 6px;
  text-align: center;
}
.sign_button {
  display: flex;
  margin: 20px 0;
}
.btn_margin {
  margin: 0 20px;
}
.sign_button >>> .van-button--disabled {
  background: #ccc;
  border: 1px solid #ccc;
  color: #666;
}
</style>