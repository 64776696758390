<template>
<div class="image">
	<download @download="clickDownload" />

	<!-- <div v-show="isShow" class="modelZbzContent" @click="isShow = false">
		<img class="modelZbz" src="../../assets/image/zbz.png" alt="">
	</div> -->
</div>
</template>

<script>
import download from '@/components/dislog/Download';
export default {
	components: {
		download
	},
	data() {
		return {
			isShow: false, // 显示打开浏览器的提示
		}
	},
	created() {
		// 阻止页面回退
		history.pushState(null, null, document.URL);
		window.addEventListener("popstate", function () {
			history.pushState(null, null, document.URL);
		});
	},
	methods: {
		clickDownload(type) { // 检测app是否有安装，有安装就打开app，没安装就下载
			// console.log(type)
			// return
			/*var browser = {
				versions: function () {
					let u = navigator.userAgent, app = navigator.appVersion;
					return {//移动终端浏览器版本信息
						trident: u.indexOf('Trident') > -1, //IE内核
						presto: u.indexOf('Presto') > -1, //opera内核
						webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
						gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
						mobile: !!u.match(/AppleWebKit.*Mobile/i) || !!u.match(/MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/), //是否为移动终端
						ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
						android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
						iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
						iPad: u.indexOf('iPad') > -1, //是否iPad
						webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
					};
				}(),
				language: (navigator.browserLanguage || navigator.language).toLowerCase()
			}
			var ua = window.navigator.userAgent.toLowerCase();
			var u = navigator.userAgent;*/
			// console.log(ua)
			// if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 是微信浏览器
			// 	console.log("wx")
			// 	this.isShow = true;
			// } else { // 不是微信浏览器
				// this.isShow = false;
				// if (browser.versions.iPhone || browser.versions.iPad || browser.versions.ios) {
				if (type == 'ios') { // 苹果手机
					// this.$toast('苹果手机');
					console.log('苹果手机');
					window.location.href = `kaoZhengTongN://kzt/app?type=null&id=null`;
					window.setTimeout(function () { //如果没有安装app,便会执行setTimeout跳转下载页
						let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden || window.document.webkitHidden;
						if (typeof hidden == "undefined" || hidden == false) {
							window.location.href = 'https://apps.apple.com/cn/app/%E8%80%83%E8%AF%81%E9%80%9A-%E6%B5%B7%E9%87%8F%E8%A7%86%E9%A2%91-%E9%A2%98%E5%BA%93/id1476512182'
						}
					}, 500);
					// alert(123)
				}else
				// if (browser.versions.android) 
				{ // 安卓手机
					// this.$toast('安卓手机');
					console.log('安卓手机');
					let frame = document.createElement("frame");
					frame.src = `kaoZhengTongN://kzt/app?type=null&id=null`;
					document.body.appendChild(frame);
					window.setTimeout(function () {
						window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=zz.md.gcl.examination_practice'
					}, 500);
					// alert(321)
				}
			// }
		},
	}
};
</script>


<style scoped>
.modelZbzContent {
	background: #FFFFFF;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	/* height: 100%; */
	z-index: 60;
}

.modelZbz {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	/* height: 100%; */
	z-index: 50;
	object-fit: fill;
}
</style>