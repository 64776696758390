<template>
<div>
	<titles :isShow="titleShow" isBack :titleName="titleName" />

	<!-- 学员信息 -->
	<item-select :show="payDisabled" label="学员信息" :isSelected="isXy" @onSelect="onXy" />
	<div 
		v-if="payDisabled" 
		:class="[
			{'hide': !isXy},
			{'shadow': isUser }
		]">
		<information ref="information" />
	</div>

	<item-select label="报读信息" :isSelected="isBd" @onSelect="onBd" />
	<ul 
		:class="[
			'bd-info',
			'border-box',
			{'hide': !isBd},
			{'shadow': isShadow}
		]">
		<li class="bd-info-item flex">
			<label class="bd-info-item-label shrink">业务类型</label>
			<div class="bd-info-item-content">
				<p>{{ detail.type }}</p>
			</div>
		</li>

		<!-- 学历 -->
		<li v-if="detail.orderType != 2" class="bd-info-item flex">
			<label class="bd-info-item-label shrink">形式</label>
			<div class="bd-info-item-content">
				<p>{{ detail.grade }}</p>
			</div>
		</li>
		<li v-if="detail.orderType != 2" class="bd-info-item flex">
			<label class="bd-info-item-label shrink">层次</label>
			<div class="bd-info-item-content">
				<p>{{ detail.level }}</p>
			</div>
		</li>
		<!--  -->

		<!-- 技能 -->
		<li v-if="detail.orderType == 2" class="bd-info-item flex">
			<label class="bd-info-item-label shrink">工种</label>
			<div class="bd-info-item-content">
				<p>{{ detail.grade }}</p>
			</div>
		</li>
		<li v-if="detail.orderType == 2" class="bd-info-item flex">
			<label class="bd-info-item-label shrink">等级</label>
			<div class="bd-info-item-content">
				<p>{{ detail.level }}</p>
			</div>
		</li>
		<!--  -->

		<li class="bd-info-item flex">
			<label class="bd-info-item-label shrink">院校</label>
			<div class="bd-info-item-content">
				<p>{{ detail.school }}</p>
			</div>
		</li>

		<!-- 学历 -->
		<li v-if="detail.orderType != 2" class="bd-info-item flex">
			<label class="bd-info-item-label shrink">专业</label>
			<div class="bd-info-item-content">
				<p>{{ detail.specialty }}</p>
			</div>
		</li>
		<li v-if="detail.orderType != 2" class="bd-info-item flex">
			<label class="bd-info-item-label shrink">报名批次</label>
			<div class="bd-info-item-content">
				<p>{{ detail.applyBatch }}</p>
			</div>
		</li>
		<li v-if="detail.orderType != 2" class="bd-info-item flex">
			<label class="bd-info-item-label shrink">学制</label>
			<div class="bd-info-item-content">
				<p>{{ detail.educationalSystemName }}</p>
			</div>
		</li>
		<!--  -->

		<!-- 技能 -->
		<li v-if="detail.orderType == 2" class="bd-info-item flex">
			<label class="bd-info-item-label shrink">学习有效期</label>
			<div class="bd-info-item-content">
				<p>{{ detail.studyValidDate }}</p>
			</div>
		</li>
		<!--  -->

		<li class="bd-info-item flex">
			<label class="bd-info-item-label shrink">班级</label>
			<div class="bd-info-item-content">
				<p>{{ detail.className }}</p>
			</div>
		</li>

		<!-- 学历 -->
		<li v-if="detail.orderType != 2" class="bd-info-item flex">
			<label class="bd-info-item-label shrink">入学时间</label>
			<div class="bd-info-item-content">
				<p>{{ detail.applyDate }}</p>
			</div>
		</li>
		<li v-if="detail.orderType != 2" class="bd-info-item flex">
			<label class="bd-info-item-label shrink">预计毕业时间</label>
			<div class="bd-info-item-content">
				<p>{{ detail.graduateDate }}</p>
			</div>
		</li>
		<li v-if="detail.orderType != 2" class="bd-info-item flex">
			<label class="bd-info-item-label shrink">预计取证时间</label>
			<div class="bd-info-item-content">
				<p v-for="(item,index) of detail.certificateDate" :key="index">{{ item }}</p>
				<!-- <p>{{ detail. }}</p> -->
			</div>
		</li>
		<!--  -->

		<!-- 技能 -->
		<li v-if="detail.orderType == 2" class="bd-info-item flex">
			<label class="bd-info-item-label shrink">复审时间</label>
			<div class="bd-info-item-content">
				<p>{{ detail.graduateDate }}</p>
			</div>
		</li>
		<!--  -->

		<li class="bd-info-item flex">
			<label class="bd-info-item-label shrink">上课方式</label>
			<div class="bd-info-item-content">
				<p v-if="detail.teachType == 1">线上</p>
				<p v-if="detail.teachType == 2">线下</p>
				<p v-if="detail.teachType == 3">线上+线下</p>
			</div>
		</li>
		<li class="bd-info-item flex">
			<label class="bd-info-item-label shrink">考证通APP课程</label>
			<div class="bd-info-item-content">
				<a class="bd-info-item-content-link" href="javascript:;" @click="classClick()">查看</a>
			</div>
		</li>
	</ul>
	<!-- 考证通app课程 -->
	<classDetail ref="classDetail" :orderId="orderId" :data="classList" :classDetailShow="classShow" @close="classClose" />

	<item-select label="缴费信息" :isSelected="isJf" @onSelect="onJf" />
	<div 
		:class="[
			'jf-info',
			'border-box',
			{'hide': !isJf},
			{'shadow': isShadow}
		]">
		<ul>
			<li class="bd-info-item flex">
				<label class="bd-info-item-label shrink">学费</label>
				<div class="bd-info-item-content">
					<p>￥{{ detail.tuitionFee }}</p>
				</div>
			</li>
			<li class="bd-info-item flex">
				<label class="bd-info-item-label shrink">报考费</label>
				<div class="bd-info-item-content align-center between">
					<p>￥{{ detail.applyFee }}</p>
					<!-- <div class="jf-info-detail-item-check jf-info-detail-item-right align-center">
						<input id="stages0" type="radio" value="0" v-model="stages" :disabled="detail.isSplit == 1" @change="stagesChange()">
						&nbsp;
						<label for="stages0">
							交
						</label>
						&nbsp;&nbsp;&nbsp;
						<input id="stages1" type="radio" value="1" v-model="stages" @change="stagesChange()">
						&nbsp;
						<label for="stages1">
							不交
						</label>
					</div> -->
				</div>
			</li>

			<!-- 学历 -->
			<li v-if="detail.orderType != 2" class="bd-info-item flex">
				<label class="bd-info-item-label shrink">网络平台学习费</label>
				<div class="bd-info-item-content">
					<p>￥{{ detail.otherFee }}</p>
				</div>
			</li>
			<li v-if="detail.orderType != 2" class="bd-info-item flex">
				<label class="bd-info-item-label shrink">成考辅导班培训费</label>
				<div class="bd-info-item-content">
					<p>￥{{ detail.cultivateFee }}</p>
				</div>
			</li>
			<!--  -->

			<!-- 技能 -->
			<li v-if="detail.orderType == 2" class="bd-info-item flex">
				<label class="bd-info-item-label shrink">实操工具费</label>
				<div class="bd-info-item-content">
					<p>￥{{ detail.otherFee }}</p>
				</div>
			</li>
			<!--  -->

			<li class="bd-info-item flex">
				<label class="bd-info-item-label shrink">总费用</label>
				<div class="bd-info-item-content">
					<p>￥{{ detail.totalPrice }}</p>
				</div>
			</li>

			<!-- 学历 -->
			<li v-if="detail.orderType != 2" class="bd-info-item flex">
				<label class="bd-info-item-label shrink">预缴金额</label>
				<div class="bd-info-item-content">
					<p>￥{{ detail.advancePrice }}</p>
				</div>
			</li>

			<!-- 技能 -->
			<li v-if="detail.orderType == 2" class="bd-info-item flex">
				<label class="bd-info-item-label shrink">当前应付金额</label>
				<div class="bd-info-item-content">
					<p>￥{{ detail.advancePrice }}</p>
				</div>
			</li>
		</ul>
		<div class="jf-info-detail">
			<div class="jf-info-detail-item align-center between">
				<label class="jf-info-detail-item-title bd-info-item-label shrink">剩余应付金额</label>
				<div class="jf-info-detail-item-right">
					<span class="color-red">￥{{ detail.surplusPrice }}</span>
				</div>
			</div>

			<!-- 学历 -->
			<div v-if="detail.orderType != 2" class="jf-info-detail-item align-center between">
				<label class="jf-info-detail-item-title bd-info-item-label shrink">是否分期</label>
				<div class="jf-info-detail-item-check jf-info-detail-item-right align-center">
					<!-- <input 
						v-if="detail.initIsSplit == 0" 
						id="stages0" 
						type="radio" 
						value="0" 
						v-model="stages" 
						:disabled="detail.initIsSplit == 1" 
						@change="stagesChange()"> -->
					<label 
						v-if="detail.initIsSplit == 0" 
						for="stages0">
						是
					</label>
					<!-- <input 
						v-if="detail.initIsSplit == 1" 
						id="stages1" 
						type="radio" 
						value="1" 
						v-model="stages" 
						:disabled="detail.initIsSplit == 0" 
						@change="stagesChange()"> -->
					<label 
						v-if="detail.initIsSplit == 1" 
						for="stages1">
						否
					</label>
				</div>
			</div>
			<ul v-if="detail.orderType != 2 && detail.initIsSplit == 0" class="jf-detail">
				<li v-for="(item,index) of detail.appOrderSplitList" :key="index">
                  第<span v-if="item.semesterNumber == 1">一</span><span v-if="item.semesterNumber == 2">二</span><span v-if="item.semesterNumber == 3">三</span>学期缴费日期{{ item.payDate }} ，缴费金额
                  <span class="color-red">￥{{ item.payPrice }}</span>
				</li>
			</ul>

			<!-- 技能 -->
			<div v-if="detail.orderType == 2" class="jf-info-detail-item align-center between">
				<div class="jf-info-detail-item-check jf-info-detail-item-right align-center">
					<input id="stages0" type="radio" value="1" v-model="stages" @change="stagesChange()">
					<label for="stages0">
						先交
					</label>
					<input id="stages1" type="radio" value="0" v-model="stages" @change="stagesChange()">
					<label for="stages1">
						后交
					</label>
				</div>
				<label v-if="detail.lastPayDate" class="jf-info-detail-item-label shrink">{{ detail.lastPayDate }}需缴该笔费用</label>
			</div>
		</div>
	</div>

	<!-- 学历: 学位/论文/教材 -->
	<!-- 技能: 教材 -->
	<item-select :show="detail.orderType != 2 && /^[0]+$/.test(detail.isDegree) || detail.orderType != 2 && detail.paperFee > 0 || /^[0]+$/.test(detail.isTextbook)" :label="detail.orderType != 2?'学位/论文/教材':'教材'" :isSelected="isXw" @onSelect="onXw" />
	<div v-if="detail.orderType != 2 && /^[0]+$/.test(detail.isDegree) || detail.orderType != 2 && detail.paperFee > 0 || /^[0]+$/.test(detail.isTextbook)" 
		:class="[
			'xw-info',
			'border-box',
			{'hide': !isXw},
			{'shadow': isShadow}
		]">
		<div v-if="detail.orderType != 2">
			<p v-if="detail.paperFee > 0" class="xw-info-title align-center">
				<label>论文指导费：</label>
				<span class="color-red">￥{{ detail.paperFee }}</span>
			</p>
			<!-- <p class="xw-info-line"></p> -->

			<div v-if="/^[0]+$/.test(detail.isDegree)" class="xw-info-item">
				<!-- <div class="xw-info-item-status align-center between">
					<div class="align-center">
						<a class="xw-info-item-check" href="javascript:;" @click="xwClick()">
							<img v-if="xwChecked" class="image" :src="icon_checked">
							<img v-else class="image" :src="icon_unchecked">
						</a>
						<span class="xw-info-item-status-label">是否需要学位</span>
					</div>
				</div> -->
				<div class="xw-info-item-detail align-center between">
					<!-- <p class="xw-info-item-detail-lebal"> -->
					<p class="xw-info-title align-center">
						<label>学位费：</label>
						<span class="color-red">￥{{ detail.degreeFee }}</span>
					</p>
					<div 
						:class="[
							'jf-info-detail-item-check jf-info-detail-item-right align-center',
							{'shadow': payDisabled}
						]">
						<input id="degree0" type="radio" value="0" v-model="degree" @change="degreeChange()">
						<label for="degree0">
							先交
						</label>
						<input id="degree1" type="radio" value="1" v-model="degree" @change="degreeChange()">
						<label for="degree1">
							后交
						</label>
					</div>
				</div>
			</div>

			<!-- <p class="xw-info-line"></p> -->
		</div>

		<!-- 技能只显示这个 -->
		<div v-if="/^[0]+$/.test(detail.isTextbook)" class="xw-info-item">
			<!-- <div class="xw-info-item-status align-center between">
				<div class="align-center">
					<a class="xw-info-item-check" href="javascript:;" @click="jcClick()">
						<img v-if="jcChecked" class="image" :src="icon_checked">
						<img v-else class="image" :src="icon_unchecked">
					</a>
					<span class="xw-info-item-status-label">是否需要教材</span>
				</div>
				<a class="xw-info-item-into" href="javascript:;" @click="jsClick()">
					<img class="image" :src="icon_into">
				</a>
			</div> -->
			<div class="xw-info-item-detail align-center between">
				<!-- <p class="xw-info-item-detail-lebal"> -->
				<p class="xw-info-title align-center">
					<label>教材资料费：</label>
					<span class="color-red">￥{{ detail.textbookPrice }}</span>
				</p>
				<a class="xw-info-item-into" href="javascript:;" @click="jsClick()">
					<img class="image" :src="icon_into">
				</a>
			</div>
		</div>
	</div>
	<!-- 查看教材 -->
	<van-popup 
		v-model="jcShow" 
		class="jc-pop border-box" 
		:style="'background-image: url('+ jcBg +');'">
		<div class="jc-pop-top flex between">
			<span class="jc-pop-close"></span>
			<span class="jc-pop-title">查看教材</span>
			<img class="jc-pop-close" src="../../assets/image/icon_close_white.png" @click="jcShow = false">
		</div>
		<ul class="jc-list border-box bgfff">
			<li v-for="(item,index) in jcData" :key="index">
				<div class="jc-item">
					<p class="jc-item-name bold">{{ item.textbookName }}</p>
					<div class="jc-item-price color-red">￥{{ item.textbookPrice }}</div>
				</div>
				<p class="jc-line"></p>
			</li>
		</ul>
	</van-popup>

	<item-select :show="/^[0]{1}$/.test(detail.isInvoice) || /^[0-9]+$/.test(detail.contractId) && detail.contractId != 0" label="签署合同/开发票" :isSelected="isHt" @onSelect="onHt" />
	<ul v-if="/^[0]{1}$/.test(detail.isInvoice) || /^[0-9]+$/.test(detail.contractId) && detail.contractId != 0" 
		:class="[
			'ht-info',
			'border-box',
			{'hide': !isHt},
			{'shadow': isShadow}
		]">
		<li v-if="/^[0-9]+$/.test(detail.contractId) && detail.contractId != 0" class="ht-info-item align-center between">
			<label>签署合同</label>
			<!-- <router-link 
				class="ht-info-into align-center" 
				:to="{
					path: '/productContract',
					query: {
						type: 1,
						orderId,
						url: detail.contractUrl
					}
				}"> -->
			<router-link 
				class="ht-info-into align-center" 
				:to="{
					path: '/productContract',
					query: {
						type: 1,
						orderId
					}
				}">
				<span v-if="detail.isContract == 0">已签字</span>
				<span v-else>未签字</span>
				<img class="block" :src="icon_into">
			</router-link>
		</li>
		<li v-if="/^[0]{1}$/.test(detail.isInvoice)" class="ht-info-item align-center between">
			<label>开发票</label>
			<router-link 
				class="ht-info-into align-center" 
				:to="{
					path: '/productInvoice',
					query: {
						orderId,
						invoiceId: detail.invoiceId,
						isPay: payDisabled
					}
				}">
				<span v-if="detail.invoiceId">已填写</span>
				<span v-else>填写开票信息</span>
				<img class="block" :src="icon_into">
			</router-link>
		</li>
	</ul>

	<item-select :show="true" label="缴费方式" :isSelected="isWay" @onSelect="onWay" />
	<!-- v-if="!payDisabled"  -->
	<div 
		:class="[
			'way-info',
			'border-box',
			{'hide': !isWay},
			{'shadow': payDisabled && isUser}
		]">
		<!-- <p class="way-info-check align-center">
			<input id="onLine0" type="radio" value="1" v-model="onLine">
			&nbsp;&nbsp;
			<label for="onLine0">线上缴费</label>
			&nbsp;&nbsp;&nbsp;&nbsp;
			<input id="onLine1" type="radio" value="2" v-model="onLine">
			&nbsp;&nbsp;
			<label for="onLine1">线下缴费</label>
		</p>
		<p class="way-info-label align-center">支付方式<span v-if="onLine == 1">：银行卡/信用卡</span></p> -->
		<!-- 线上 -->
		<!-- <div v-if="onLine == 1" class="way-info-pay">
			<div 
				class="way-item align-center between" 
				v-for="(item,index) in wayData" 
				:key="index" 
				@click="wayChange(item)">
				<div class="way-item-name align-center">
					<img :src="item.icon" />
					<span>{{ item.name }}</span>
				</div>
				<div class="way-item-checked">
					<img class="image" :src="item.status == wayStatus?icon_selected:icon_unselected">
				</div>
			</div>
		</div> -->
		<!-- 线下 -->
		<!-- <div v-else class="way-upload">
			<p class="way-upload-title">上传缴费凭证</p>
			<div class="way-upload-box">
				<uploadFile @upload="uploadFile" />
			</div>
		</div> -->

		<div class="way-info-status align-center f-wrap">
			<a 
				:class="[
					'way-info-status-btn align-center',
					{'active': wayStatus2 == item.status}
				]" 
				href="javascript:;" 
				v-for="(item,index) of wayData2" 
				:key="index" 
				@click="wayClick(item.status)">
				<img :src="item.icon">
				&nbsp;
				{{ item.name }}
			</a>
		</div>
		<!-- 微信/支付宝 -->
		<div :class="[{'hide': wayStatus2 == 9}]">
			<div id="qrcode" class="qrcode">
				<img class="image" :src="qrcodeImg">
			</div>
			<p class="way-info-hint">请长按识别/扫码支付费用</p>
		</div>
		<!-- 银行卡 -->
		<ul v-if="wayStatus2 == 9" class="way-info-card">
			<li class="align-center">
				<label>转账账户：</label>
				<div>{{ detail.appPayee?detail.appPayee.bankCardNumber:'' }}</div>
			</li>
			<li class="align-center">
				<label>开户行：</label>
				<div>{{ detail.appPayee?detail.appPayee.openBank:'' }}</div>
			</li>
		</ul>
		<div class="way-info-upload">
			<upload-file :src="voucherImage" :disabled="payDisabled && isUser" isExample :width="235" :height="135" @upload="exampleUpload" />
		</div>
		<p class="way-info-example">
			<router-link class="way-info-example-into" to="/productExample">缴费凭证示例</router-link>
		</p>
	</div>

	<div class="mt-10"></div>

	<!-- 新的订单 -->
	<div v-if="!payDisabled" class="way-online-null">
		<div class="way-online align-center between">
			<div class="way-online-total align-center">
				合计：<p class="color-red align-end">￥<span>{{ totalPrice }}</span></p>
			</div>
			<a class="confirm-btn" href="javascript:;" @click="onPay">立即缴费</a>
		</div>
	</div>
	<!-- 已缴费的订单 -->
	<fixedBtn :show="payDisabled && !isUser" btnText="提交" @confirm="onConfirm" />
	<!-- 线下支付-底部提交 -->
	<!-- <div v-else class="way-offline-null">
		<div class="way-offline fixed-bottom bgfff align-center">
			<a class="confirm-btn" href="javascript:;">确定</a>
		</div>
	</div> -->
</div>
</template>

<script>
import titles from '@/components/titles';
import classDetail from '@/components/dislog/ClassDetail';
import fixedBtn from '@/components/fixed-button';
import uploadFile from '@/components/upload-file';
import itemSelect from '@/views/product/components/item-select';
import information from './user-info';
export default {
	components: {
		titles,
		classDetail,
		fixedBtn,
		uploadFile,
		itemSelect,
		information
	},
	data() {
		return {
			titleName: '信息确认和合同签字',
			titleShow: true, // 显示标题
			userInfo: JSON.parse(sessionStorage.getItem('userInfo')),

			// productId: this.$route.query.productId, // 产品id
			// productParams: {}, // 产品报名的参数
			orderId: this.$route.query.orderId*1, // 订单id
			clueId: this.$route.query.clueId, // 线索所有者编号
			detail: {}, // 订单详情
			// bottomShow: true, // 显示地步提交按钮

			icon_checked: require('../../assets/image/icon_checked.png'), // 选中
			icon_unchecked: require('../../assets/image/icon_check.png'), // 未选中
			icon_into: require('../../assets/image/icon_into.png'), // 向右箭头

			icon_selected: require('../../assets/image/icon_selected.png'), // 支付方式-选中图标
			icon_unselected: require('../../assets/image/icon_unselected.png'), // 支付方式-未选中图标

			isXy: true, // 显示 学员信息
			isBd: true, // 显示 报读信息
			isJf: true, // 显示 缴费信息
			isXw: true, // 显示 学位/论文/教材
			isHt: true, // 显示 签署合同/开发票
			isWay: true, // 显示 缴费方式

			classShow: false, // 显示 考证通app课程
			classList: [], // 课程列表
			stages: 0, // 是否分期: 0 是,1 否
			sDisaBled: false, // 是否分期: true 不可选
			degree: 0, // 0 先交,1 后交
			// xwChecked: false, // 是否需要学位
			// jcChecked: false, // 是否需要教材
			jcShow: false, // 查看教材
			jcPage: 1, // 页码
			jcTotal: 0, // 总教材数量

			jcBg: require('../../assets/image/bg_check.png'),
			jcData: [], // 教材列表

			onLine: 1, // 缴费方式: 1 线上,2 线下
			wayData: [
				{
					name: '微信',
					icon: require('../../assets/image/ic_wechat.png'),
					status: 0
				},
				{
					name: '支付宝',
					icon: require('../../assets/image/ic_alipay.png'),
					status: 1
				}
			], // 支付方式
			wayStatus: 0, // 选中的支付方式: 0 微信,1 支付宝
			wayData2: [
				{
					name: '支付宝',
					icon: require('../../assets/image/ic_alipay.png'),
					status: 7
				},
				{
					name: '微信',
					icon: require('../../assets/image/ic_wechat.png'),
					status: 8
				},
				{
					name: '银行卡/信用卡',
					icon: require('../../assets/image/ic_card.png'),
					status: 9
				}
			], // 支付方式
			wayStatus2: 7, // 选中的支付方式: 7 支付宝,8 微信,9 银行卡
			qrcodeImg: '', // 微信/支付宝 付款码
			semester: [
				{name: '一',type: 1},
				{name: '二',type: 2},
				{name: '三',type: 3}
			], // 学期
			// signUrl: undefined, // 签名
			voucherImage: undefined, // 缴费凭证
			payDisabled: false, // 支付状态: true 已支付不可以编辑,false 未支付，可以编辑订单
			isUser: false, // 学员信息是否禁止编辑: true 禁止,false 不禁止
			isShadow: false, // 内容不可点击
			totalPrice: 0, // 合计
			isPay: undefined, // 是否支付：0-是，1-否（默认）
			userMsg: {}, // 订单返回的学员信息
		}
	},
	mounted() {
		// console.log(this.userInfo)
		// this.$store.commit('getSign',null);
		this.orderInfo();
	},
	methods: {
		orderInfo() { // 订单详情
			if(/^[0-9]+$/.test(this.orderId)) {
				this.api.orderInfo(this.orderId).then(res=> {
					if(res.code === 0) {
						this.getInfo(res.data);
					}else {
						this.$toast(res.msg);
					}
				})
			}/*else if(/^[0-9]+$/.test(this.productId)) { // 产品详情
				this.productParams = JSON.parse(sessionStorage.getItem('productParams'));
				// console.log(productParams);
				this.api.productInfo(this.productId).then(res=> {
					if(res.code === 0) {
						this.getInfo(res.data);
					}else {
						this.$toast(res.msg);
					}
				})
			}*/
		},
		getInfo(data) {
			let {
				isSplit,
				isBeforeDegree,
				payType,
				sumPrice,
				getCertificateDateArray,
				textbookPrice,
				// isPay,

				studentName, // 姓名
				studentSex, // 性别
				studentWechatNo, // 微信号
				studentPhone, // 手机号
				studentEmail, // 有限
				studentAddress, // 地址
				studentIdCard, //身份证号
				frontalImage, // 身份证人面
				reversedImage, // 身份证国徽面
				blueImage, // 蓝底照
				diplomaImage, // 毕业照

				paymentTypeId, // 支付方式
				voucherImage, // 缴费凭证

				// isSubmit,
				auditStatus // 审核状态：1-待审核，2-已审核，3-驳回
			} = data;
			this.stages = isSplit || 0;
			// this.sDisaBled = data.isSplit == 0 ?false:true;
			// this.xwChecked = data.isDegree == 0?true:false;
			this.degree = isBeforeDegree || 0;
			// this.jcChecked = data.isTextbook == 0?true:false;
			this.onLine = payType || 1;
			this.totalPrice = sumPrice;
			this.detail = data;

			let {
				status,
				img
			} = this.$store.state.payWay;
			// console.log(status,img);
			this.voucherImage = voucherImage || img;
			this.wayStatus2 = paymentTypeId || status;
			this.wayClick(this.wayStatus2);

			if(data.orderType != 2) {
				if(!/^[0-9]+$/.test(textbookPrice) || textbookPrice <= 0) {
					if(data.isTextbook && data.isTextbook != '' ) {
						this.getTextbookPrice();
					}
				}
			}

			this.userMsg = {
				studentName,
				studentSex,
				studentWechatNo,
				studentPhone,
				studentEmail,
				studentAddress,
				studentIdCard,
				frontalImage,
				reversedImage,
				blueImage,
				diplomaImage
			}
			
			if(/,/g.test(getCertificateDateArray)) {
				this.$set(this.detail,'certificateDate',getCertificateDateArray.split(','));
			}else {
				let arr = [];
				arr.push(getCertificateDateArray);
				this.$set(this.detail,'certificateDate',arr);
			}
			this.$emit('orderInfo',this.detail);

			// 学员信息是否禁止编辑
			if(!auditStatus || /^[0]{1}$/.test(auditStatus)) { // 新的订单，没缴费/没提交
				this.payDisabled = false;
			}else {
				this.payDisabled = true;

				this.titleName = '订单详情';
				setTimeout(()=> {
					// console.log(this.$refs.information)
					this.$refs.information.isShow = false;
					this.$refs.information.messageForm = {
						username: studentName,
						sex: studentSex,
						wxID: studentWechatNo,
						phoneNum: studentPhone,
						email: studentEmail,
						address: studentAddress,
						studentIdCard: studentIdCard,
						frontalImage: frontalImage,
						reversedImage: reversedImage,
						blueImage: blueImage,
						diplomaImage: diplomaImage,
					}
				},1000);

				if(/^[12]{1}$/.test(auditStatus)) { // 待审核/已审核（什么都不能操作，显示学员信息）
					this.isUser = true;
				}else { // 驳回
					this.isUser = false;
				}
			}
		},
		classClick() { // 加载课程
			this.$refs.classDetail.getData(true);
		},
		classClose(show) { // 隐藏课程
			// console.log(show,11111)
			this.classShow = show;
		},
		jsClick() { // 加载教材
			this.api.orderTextbookList(this.orderId).then(res=> {
				if(res.code === 0) {
					this.jcData = res.rows;
					this.jcTotal = res.total;
					this.jcShow = true;
				}else {
					this.$toast(res.msg);
				}
			})
		},
		orderUpdate(callBack = ()=>{}) { // 提交订单/更新订单信息
			let params = {
				orderId: this.orderId,
				isSplit: this.stages,
				// isTextbook: this.jcChecked == true ?0:1,
				// isDegree: this.xwChecked == true ?0:1,
				isBeforeDegree: this.degree,
				// contractUrl: this.signUrl,
				isInvoice: this.detail.isInvoice,
				// clueId: this.clueId,
				voucherImage: /^[0]{1}$/.test(this.isPay)?this.voucherImage:undefined,
				isPay: 1,
				kztUserId: /^[0]{1}$/.test(this.isPay)?this.userInfo.studentId:undefined,
				paymentTypeId: this.wayStatus2
			}
			this.api.orderUpdate(params).then(res=> {
				if(res.code === 0) {
					this.isPay = undefined;
					if(callBack) {
						callBack(params);
					}
				}else {
					this.$toast(res.msg);
				}
			})
		},
		onXy() { // 展开 学员信息
			this.isXy = !this.isXy;
		},
		onBd() { // 展开 报读信息
			this.isBd = !this.isBd;
		},
		onJf() { // 展开 缴费信息
			this.isJf = !this.isJf;
		},
		stagesChange() { // 是否分期
			// console.log(this.stages)
			this.orderUpdate(this.orderInfo);
		},
		onXw() { // 展开 学位/论文/教材
			this.isXw = !this.isXw;
		},
		xwClick() { // 是否需要学位
			// this.xwChecked = !this.xwChecked;
			// this.orderUpdate(this.orderInfo);
		},
		degreeChange() { // 学位 先付/后付
			// console.log(this.degree);
			this.orderUpdate(this.orderInfo);
		},
		jcClick() { // 是否需要教材
			// this.jcChecked = !this.jcChecked;
			// this.orderUpdate(this.orderInfo);
			// this.getTextbookPrice();
		},
		onHt() { // 展开 签署合同/开发票
			this.isHt = !this.isHt;
		},
		onWay() { // 展开 缴费方式
			this.isWay = !this.isWay;
		},
		// wayChange(obj) { // 切换支付方式: 支付宝/微信
		// 	this.wayStatus = obj.status;
		// },
		wayClick(status) { // 切换 缴费方式
			this.wayStatus2 = status;
			if(status != 9) { // 判断不是 银行卡 支付
				let list = {
					7: this.detail.appPayee?this.detail.appPayee.aliPayImage:'',
					8: this.detail.appPayee?this.detail.appPayee.wechatPayImage:''
				}
				// this.api.getCode('qrcode',155,155,list[status]);
				this.qrcodeImg = list[status];
			}
			this.getWay();
		},
		getWay() { // 把缴费方式数据存起来
			let payWay = {
				status: this.wayStatus2,
				img: this.voucherImage
			}
			this.$store.commit('getWay',payWay);
		},
		getTextbookPrice() { // 查询订单教材金额
			let params = {
				type: 0,
				objectId: this.orderId,
				// isTextbook: this.jcChecked===true?0:1,
				isTextbook: this.detail.isTextbook,
			}
			this.api.getTextbookPrice(params).then(res=> {
				if(res.code === 0) {
					let {
						textbookPrice
					} = res.data;
					// if(this.jcChecked===true) {
					if(/^[0]+$/.test(this.detail.isTextbook)) {
						this.totalPrice = this.detail.sumPrice + textbookPrice;
					}
					this.detail.textbookPrice = textbookPrice;
				}else {
					this.$toast(res.msg);
				}
			});
		},
		uploadFile(data) { // 上传缴费凭证
			console.log(data)
		},
		exampleUpload(data) { // 上传缴费凭证
			console.log(data)
			this.voucherImage = data;
			this.getWay();
		},
		onPay() { // 线上缴费-立即缴费
			// console.log(this.signUrl)
			if(this.detail.contractUrl && this.detail.isContract != 0) {
				this.$toast('请先签署合同');
				return;
			}
			if(this.detail.isInvoice == 0 && !/^[0-9]+$/.test(this.detail.invoiceId)) {
				this.$toast('请填写发票信息');
				return;
			}
			if(!this.voucherImage) {
				this.$toast('请上传缴费凭证');
				return;
			}
			this.isPay = 0;
			this.orderUpdate(this.jump);
		},
		onConfirm() { // 已支付-提交
			// console.log('提交');
			if(this.detail.contractUrl && this.detail.isContract != 0) {
				this.$toast('请先签署合同');
				return;
			}
			if(this.detail.isInvoice == 0 && !/^[0-9]+$/.test(this.detail.invoiceId)) {
				this.$toast('请填写发票信息');
				return;
			}
			if(!this.voucherImage) {
				this.$toast('请上传缴费凭证');
				return;
			}
			let params = {
				orderId: this.orderId,
				isSplit: this.stages,
				isBeforeDegree: this.degree,
				isInvoice: this.detail.isInvoice,
				voucherImage: this.voucherImage,
				isPay: 1,
				kztUserId: this.userInfo.studentId,
				paymentTypeId: this.wayStatus2,
				ownerId: this.detail.ownerId,
				clueId: this.clueId
			}
			sessionStorage.setItem('formData',JSON.stringify(params)); 

			let information = this.$refs.information;
			// this.$set(information.params,'ownerId',this.detail.ownerId);
			information.onConfirm();
		},
		jump(params) {
			// this.$toast('提交成功');
			// 提交订单的参数
			this.$set(params,'ownerId',this.detail.ownerId);
			this.$set(params,'clueId',this.clueId);
			sessionStorage.setItem('formData',JSON.stringify(params));
			// 学员信息
			sessionStorage.setItem('userMsg',JSON.stringify(this.userMsg));
			setTimeout(()=> {
				this.$router.push({
					path: '/productUserInfo',
					query: {
						orderId: this.orderId,
						clueId: this.clueId,
						way: this.wayStatus2
					}
				});
				// this.$router.push('/productDownload');
			},1000);
		},
	},
};
</script>

<style scoped>
.shadow {
	position: relative;
}
.shadow:after {
	content: '';
	/*background: rgba(0,0,0,.5);*/
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999;
}
.bd-info {
	background-color: #fff;
	padding: .35rem 0 .65rem;
}
.bd-info-item {
	padding: .35rem 1rem;
	font-size: .7rem;
}
.bd-info-item-label {
	width: 5.65rem;
	color: #999;
}
.bd-info-item-content {
	color: #101010;
}
.bd-info-item-content p + p {
	margin-top: .3rem;
}
.bd-info-item-content-link {
	color: #1E85F4;
}
.hide {
	height: 0 !important;
	padding: 0 !important;
	overflow: hidden !important;
}

.jf-info {
	background-color: #fff;
	padding: .25rem 0 1.5rem;
}
.jf-info .bd-info-item-label {
	width: 6.35rem;
}
.jf-info .bd-info-item-content {
	width: 100%;
	color: #FF5858;
}
.jf-info-detail {
	background-color: #EFF5FC;
	border: 1px solid #B8D2EE;
	border-radius: .3rem;
	padding: .75rem .95rem .65rem 1.05rem;
	margin: .65rem 1.35rem 0 .65rem;
	font-size: .7rem;
}
.jf-info-detail-item + .jf-info-detail-item {
	margin-top: .7rem;
}
input[type="radio"] {
	width: .9rem;
	height: .9rem;
	/*margin: 0;*/
	margin: 0 .45rem;
}
.jf-info-detail-item-check {
	color: #242424;
}
.jf-detail {
	margin-top: 1rem;
	font-size: .6rem;
	color: #242424;
}
.jf-detail li + li {
	margin-top: .4rem;
}

.xw-info {
	background-color: #fff;
	padding-bottom: .8rem;
}
.xw-info-title {
	height: 2.6rem;
	padding: 0 1rem 0 .8rem;
	font-size: .8rem;
}
.xw-info-title label {
	color: #101010;
}
.xw-info-line {
	height: 1px;
	background-color: #F1F5F8;
	margin: 0 1.2rem 0 2rem;
}

.xw-info-item {
	/*padding: .7rem 1rem .7rem .8rem;*/
}
.xw-info-item-status-label {
	font-size: .8rem;
	color: #101010;
}
.xw-info-item-check {
	width: .8rem;
	height: .8rem;
	margin-right: .45rem;
}
.xw-info-item-into {
	width: 1rem;
	height: 1rem;
}
.xw-info-item-detail {
	/*margin-top: .65rem;*/
	padding-right: 1rem;
}
.xw-info-item-detail-lebal {
	font-size: .7rem;
}
.xw-info-item-detail-lebal label {
	margin-left: 1.25rem;
	color: #999;
}
.xw-info-item-detail .jf-info-detail-item-check label {
	font-size: .7rem;
	color: #242424;
}

.jc-pop {
	min-height: 23.75rem;
	border-radius: 1rem;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center center;
	transform: translate3d(0,-50%,0);
	right: 1.4rem;
	left: 1.35rem;
	overflow: hidden;
}
.jc-pop-top {
	padding: .95rem 1.4rem 0;
}
.jc-pop-close {
	width: .7rem;
	height: .7rem;
}
.jc-pop-title {
	line-height: 1.4rem;
	margin-top: .25rem;
	font-weight: bold;
	font-size: 1rem;
	color: #fff;
}
.jc-list {
	height: 18.5rem;
	border-radius: .7rem;
	padding: .75rem 0 1.5rem;
	margin: 1.3rem 1rem 0;
	overflow-y: scroll;
}
.jc-item {
	padding: .6rem .75rem .65rem .95rem;
}
.jc-item-name {
	font-size: .8rem;
	color: #242424;
}
.jc-item-price {
	margin-top: .5rem;
	font-size: .7rem;
}
.jc-line {
	height: 1px;
	background-color: #F1F5F8;
	margin: 0 .75rem 0 .95rem;
}

.ht-info {
	background-color: #fff;
	padding: .85rem 1rem 1.5rem 1.3rem;
}
.ht-info-item + .ht-info-item {
	margin-top: .95rem;
}
.ht-info-item > label {
	font-size: .8rem;
	color: #101010;
}
.ht-info-into span {
	font-size: .7rem;
	color: #999;
}
.ht-info-into img {
	width: 1rem;
	height: 1rem;
	margin-left: .5rem;
}

.way-info {
	background-color: #fff;
	padding: 1rem 0 1.5rem;
	font-size: .7rem;
	color: #242424;
}
.way-info-check {
	padding: .95rem 1rem 0;
}
.way-info-label {
	padding: .9rem 1rem 0;
}
.way-info-pay {
	padding: .175rem 0 1.075rem;
}
.way-item {
	padding: 0.425rem 1rem;
}
.way-item-name img {
	width: 1.55rem;
	height: 1.55rem;
	margin-right: .75rem;
}
.way-item-checked {
	width: .9rem;
	height: .9rem;
}

.way-info-status {
	padding: 0 1rem;
}
.way-info-status-btn {
	padding: .25rem .6rem;
	border: 1px solid #1E85F4;
	background: linear-gradient(180deg, #FFFFFF 0%, #E1EFFF 100%);
	border-radius: 1.25rem;
	white-space: nowrap;
	font-size: .7rem;
	color: #1E85F4;
}
.way-info-status-btn + .way-info-status-btn {
	margin-left: .6rem;
}
.way-info-status-btn.active {
	border: none;
	background: linear-gradient(180deg, #55A5FB 0%, #1E85F4 100%);
	color: #fff;
}
.way-info-status-btn img {
	width: 1rem;
	height: 1rem;
}

.qrcode {
	width: 7.75rem;
	height: 7.75rem;
	margin: 1.5rem auto 0;
}
.way-info-hint {
	margin-top: .5rem;
	text-align: center;
	font-size: .7rem;
	color: #242424;
}

.way-info-card {
	margin-top: 1.5rem;
	font-size: .8rem;
}
.way-info-card li + li {
	margin-top: .8rem;
}
.way-info-card label {
	width: 5.85rem;
	padding-left: 1.6rem;
	box-sizing: border-box;
	flex-shrink: 0;
	color: #999;
}
.way-info-card div {
	color: #242424;
}

.way-info-upload {
	width: 235px;
	height: 135px;
	margin: 2rem auto 0;
}
.way-info-example {
	margin-top: .6rem;
	text-align: center;
}
.way-info-example-into {
	font-size: .6rem;
	color: #1E85F4;
}

.way-online-null,.way-online {
	height: 2.75rem;
}
.way-online {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;
	padding: 0 .8rem 0 1.3rem;
	z-index: 99;
}
.way-online-total {
	font-size: .6rem;
	color: #242424;
}
.way-online-total p span {
	line-height: 1;
	font-size: .9rem;
}
.way-online .confirm-btn {
	width: 8.6rem;
	height: 2rem;
	line-height: 2rem;
	border-radius: .3rem;
	font-size: .7rem;
}

.way-upload {
	margin-top: 1rem;
	padding: 0 1rem 1.75rem;
}
.way-upload-box {
	margin-top: .8rem;
}
</style>