<template>
  <div class="download">
    <img :src="downloadBgURL" class="image_bg">
    <!-- <van-dialog v-model="downloadShow" :showConfirmButton="false" ref="dialog" :overlay="false"> -->
      <div class="download-bg" :style="'background-image: url('+ bgWhite +');'">
        <div class="download_logo">
          <img :src="logoImgURL" alt="" class="logo_img">
        </div>
        <div class="download_message">
          <div class="message_title text-center">
            {{messageTitle}}
          </div>
          <div class="message_main">
            {{messageMain}}
          </div>
        </div>
        <div style="margin: 20px; ">
          <a block type="info" class="ad_btn block align-center" @click="download('and')">
            <img class="block" src="../../assets/image/ic_andrord.png">
            安卓系统下载
          </a>
          <a block class="ios_btn block align-center" icon="" @click="download('ios')">
            <img class="block" src="../../assets/image/ic_ios.png">
            苹果系统下载
          </a>
        </div>
        <div class="download_bottom">
          <!-- <div class="bottom_left">
            <img src="../../assets/image/left.png">
          </div>
          <div class="bottom_right">
            <img src="../../assets/image/right.png">
          </div> -->
        </div>
      </div>
    <!-- </van-dialog> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      // downloadShow: true,
      downloadBgURL: require('../../assets/image/bg_blue@2x.png'),
      bgWhite: require('../../assets/image/white_bg.png'),
      logoImgURL: require('../../assets/image/APPlogo@2x.png'),
      messageTitle: '恭喜您！ 报读成功！',
      messageMain : '请等待平台审核，您可以先下载考证通APP，待审核通过后，即可在APP上进行学习。'
    }
  },
  methods: {
    download(type) { // 下载
      this.$emit('download',type)
    },
  },
  mounted() {
    
  }
};
</script>

<style scoped>
.image_bg {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%; 
} 
div /deep/ .van-dialog {
  top: 50%;
  overflow: visible;
}
.download-bg {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  position: absolute;
  top: 50%;
  right: 1.4rem;
  left: 1.4rem;
  transform: translateY(-50%);
}

.download_logo {
  width: 100%;
  height: 35px;
}
.download_logo .logo_img{
  position: absolute;
  width: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.download_message .message_title {
  line-height: 60px;
  font-size: 22px;
  font-weight: 700;
}
.download_message .message_main {
  padding: 10px 40px;
  font-size: 15px;
  line-height: 21px;
  color: #898989;
  text-align: justify;
  text-align-last: j;
}
.ad_btn,.ios_btn {
  height: 52px;
  border-radius: 5px;
  padding: 0 25px;
}
.ad_btn img,.ios_btn img {
  width: 36px;
  height: 36px;
  margin-right: 23px;
}
.ad_btn {
  background-color: #1E85F4;
  margin-bottom: 30px;  
  font-size: 16px;
  color: #fff;
}
.ios_btn {
  border: 2px solid #D9D9D9;
  background-color: #fff;
  font-size: 16px;
  color: #666;
}
.download_bottom {
  height: 70px;
}
/*.bottom_left {
  position: absolute;
  bottom: 0;
  right: 0;
}
.bottom_right {
  position: absolute;
  bottom: 0;
  left: 0;
}*/
</style>
