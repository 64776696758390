<template>
  <div class="login_data">
    <van-dialog v-model="loginShow" :showConfirmButton="false" ref="dialog">
    
      <div class="title">{{ titleName }}</div>
      <div class="form">
        <van-form :model="form">
          <van-field
            v-model="form.name"
            type="string"
            placeholder="请填写真实姓名"
            class="name_input new_input"
          />
          <van-field
            v-model="form.phoneNum"
            type="number"
            placeholder="请填写手机号码"
            class="phone_input new_input"
            clearable
            maxlength="11"
            inputmode="tel"
          />
          <div style="margin: 22px auto; width: 80%; ">
            <van-button round block type="info" @click="loginData">确认</van-button>
          </div>
        </van-form>
      </div>
    </van-dialog>
  </div>

</template>

<script>
export default {
  props: {
    loginShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      titleName: '输入个人信息后查看资料',
      form: {
        name: '',
        phoneNum: ''
      }
    }
  },
  methods: {
    loginData() {
      let { name, phoneNum } = this.form;
      if(!name){
        this.$toast('请输入姓名');
        return
      }
      if(!/^[0-9]{11}$/.test(phoneNum)){
        this.$toast('请输入正确的手机号');
        return
      }
      let params = {
        ownerId: this.$route.query.ownerId,
        name: name,
        mobileNumber: phoneNum
      }
      this.api.clueAdd(params).then(res=> {
        if(res.code === 0) {
          sessionStorage.setItem('isLogin',JSON.stringify(true));
          this.$emit('loginData')
        }else {
          this.$toast(res.msg);
        }
      })
    }
  }
}
</script>

<style scoped>
.login_data {
  text-align: center;
}
.title {
  margin: 30px 0;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: #242424;
  opacity: 1;
}
.new_input {
  margin: 0 auto;
  width: 80%;
  background: #F8FBFF;
  border: 1px solid #E2E2E2;
  border-radius: 6px;
  margin-top: 15px;
}
</style>