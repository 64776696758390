<template>
  <div class="name">
    <van-dialog v-model="nameMessage.nameShow" :showConfirmButton="false" ref="dialog">
    
      <div class="name_title">{{nameMessage.nameTitle}}</div>
      <div class="name_form">
        <van-form :model="nameForm">
          <van-field
            v-model="nameForm.name"
            type="string"
            :placeholder="nameMessage.placeholder"
            class="new_input"
          />
          <van-field
            v-show="nameMessage.phoneShow"
            v-model="nameForm.phone"
            maxlength="11"
            inputmode="tel"
            type="number"
            placeholder="请输入手机号"
            class="new_input"
          />
          <div style="margin: 20px">
            <van-button round block type="info" @click="nameConfirm">确认</van-button>
          </div>
        </van-form>
      </div>
    </van-dialog>
  </div>

</template>

<script>
export default {
  props: {
    nameMessage: {
      type: Object,
      default() {
        return {
          nameShow: false,
          nameTitle: '输入个人信息',
          placeholder: '输入真实姓名',
          phoneShow: false
        }
      }
    }
  },
  data () {
    return {
      nameForm: {
        name: '',
        phone: ''
      }
    }
  },
  methods: {
    nameConfirm() {
      if(this.nameForm.name){
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        let params = {
          studentName: this.nameForm.name,
          studentId: userInfo.studentId
        }
        this.api.changeName_kzt(params).then(res=> {
          if(res.code === 0) {
            this.$toast(res.msg);
            sessionStorage.setItem('userInfo',JSON.stringify(res.data));
            this.$emit('nameConfirm', res.data)
          }else {
            this.$toast(res.msg)
          }
        });
      }else {
        this.$toast('请输入真实姓名')
      }
    }
  }
}
</script>

<style scoped>
.name_title {
  margin: 30px 0;
  text-align: center;
}
.new_input {
  margin: 10px auto;
  width: 80%;
  padding: 6px !important;
  background: #F8FBFF;
  border: 1px solid #E2E2E2;
  border-radius: 6px;
}
</style>
