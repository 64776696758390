import axios from 'axios';
import qs from 'qs';
import {
	Toast
} from 'vant';

// const baseUrl = 'http://test.crm.admin.kaozhengtong.net';
// const baseUrl = 'http://192.168.1.118:8082';

let http = new axios.create({
	timeout: 1000 * 30
});

// 请求拦截
http.interceptors.request.use(config=> {
	if(config.method == 'get') {
		config.data = {
			unused: 0
		}
	}
	return config;
},error=> {
	return Promise.reject(error);
});

// 相应拦截
http.interceptors.response.use(res=> {
	return res;
},error=> {
	return Promise.reject(error);
});

const post = (url,data,type)=> {
	let headers = {
		'content-type': 'application/json;charset=UTF-8'
	}
	if(type == 'file') { // 上传文件/图片
		headers = {
			'content-type': 'multipart/form-data'
		}
	}else if(type == 1) {
		data = qs.stringify(data);
		headers = {
			'content-type': 'application/x-www-form-urlencoded'
		}
	}
	return new Promise((resolve,reject)=> {
		Toast.loading({
			message: type == 'file'?'上传中...':'加载中...',
			duration: 1000 * 30,
			forbidClick: true
		});
		http({
			url,
			method: 'post',
			headers,
			data
		}).then(res=> {
			Toast.clear();
			resolve(res.data);
		}).catch(err=> {
			Toast.clear();
			reject(err);
		});
	});
}
const get = (url,params)=> {
	let headers = {
		'content-type': 'application/json;charset=UTF-8'
	}
	return new Promise((resolve,reject)=> {
		Toast.loading({
			message: '加载中...',
			duration: 1000 * 30,
			forbidClick: true
		});
		http({
			url,
			method: 'get',
			headers,
			params
		}).then(res=> {
			Toast.clear();
			resolve(res.data);
		}).catch(err=> {
			Toast.clear();
			reject(err);
		});
	});
}

export default {
	post,
	get
}