import axios from './axios';
import Axios from 'axios';
import QRCode from 'qrcodejs2';
import html2canvas from 'html2canvas';
import requestUrl from './baseUrl';
// import apiConfig from '../../config/api.config';
// console.log(requestUrl);

let isPro = process.env.NODE_ENV == 'production' ? true : false;
// let isPro = true;
console.log(isPro?'线上环境':'本地环境');

const api =  {
    kzt: isPro ? requestUrl.kzt : '/kzt', // 发送验证码、验证码登录、密码登录、设置姓名 用这个地址
	crm: isPro ? requestUrl.crm : '/crm',
	// crm: requestUrl.crm,
    crm2: requestUrl.crm2,
    
	downloadUrl: 'https://kzt.live.kaozhengtong.net/download.html', // 考证通app下载地址
	oss: requestUrl.files,
	files: isPro ? requestUrl.files : '/files',
	
	/*LoadBaiduMapScript() { // 加载百度地图
      //console.log("初始化百度地图脚本...");
      // const AK = 'sQDI8aOUnAIDkwZbdn4PPHY3r12o5hDj';
      const AK = 'rHcKgBozU6jRoq8p5uUKdckQ0YGXellG';
      const BMap_URL = "https://api.map.baidu.com/api?v=2.0&ak="+ AK +"&s=1&callback=onBMapCallback";
      return new Promise((resolve, reject) => {
        // 如果已加载直接返回
        console.log(BMap);
        if(typeof BMap !== "undefined") {
            resolve(BMap);
            return true;
        }
        // 百度地图异步加载回调处理
        window.onBMapCallback = function () {
            console.log("百度地图脚本初始化成功...");
        	// console.log(BMap)
            resolve(BMap);
        };
        // 插入script脚本
        let scriptNode = document.createElement("script");
        scriptNode.setAttribute("type", "text/javascript");
        scriptNode.setAttribute("src", BMap_URL);
        document.body.appendChild(scriptNode);
      });
    },*/
	base64toFile (dataurl, filename = 'file') { // 把base64图片转化为文件流
		let arr = dataurl.split(',')
		let mime = arr[0].match(/:(.*?);/)[1]
		let suffix = mime.split('/')[1]
		let bstr = atob(arr[1])
		let n = bstr.length
		let u8arr = new Uint8Array(n)
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n)
		}
		return new File([u8arr], `${filename}.${suffix}`, {
			type: mime
		})
	},
	getBlob(url) { // 跨域图片转成blob文件
		let baseUrl = api.crossDomain(url);
		// let baseUrl = url;
		console.log(baseUrl);
		return new Promise((resolve)=> {
			Axios({
				url: baseUrl,
				methods: 'get',
				responseType: 'blob',
				headers: {
					// Origin: 'anonymous'
				}
			}).then(res=> {
				if(res.status === 200) {
					let url = URL.createObjectURL(res.data);
					resolve(url);
				}
			});
		});
	},
	into(type,signIn,signOut) { // 判断是否在签到时段内
      var myDate = new Date();
      let ys = myDate.getHours();
      var ms = myDate.getMonth() + 1;
      var rs = myDate.getDay();
      let obj = {
		disabledIn: true,
		inBtn: '签到',
		up: true,
		upBtn: '签退',
      }

      switch(type) {
        case 1:
          // console.log('早上签到')
          if (signIn) { // 上午已签到
            obj.inBtn = '已签到';
            if (signOut) { //以签退
              obj.up = true;
              obj.upBtn = '已签退';
            } else {
              if(api.toMiner(ys, ms, rs) < api.toMiner(14, '00', '00')) {
                obj.up = false;
                obj.upBtn = '签退';
              }
            }
          }else { //没有签到
            if (api.toMiner(ys, ms, rs) < api.toMiner(12, '00', '00') && api.toMiner(ys, ms, rs) > api.toMiner('00', '00', '00')) {
				obj.disabledIn = false;
				obj.inBtn = '签到';
            }

          }
        break;
        case 2:
          // console.log('下午签到')
          if (signIn) {
            obj.inBtn = '已签到';
            if (signOut) { //以签退
              obj.up = true;
              obj.upBtn = '已签退';
            }
            if (!signOut && api.toMiner(ys, ms, rs) < api.toMiner(20, '00', '00')) {
              obj.up = false;
              obj.upBtn = '签退';
            }
          }else { //没有签到
            if (api.toMiner(ys, ms, rs) < api.toMiner(19, '00', '00') && api.toMiner(ys, ms, rs) > api.toMiner(11, '00', '00')) {
				obj.disabledIn = false;
				obj.inBtn = '签到';
            }

          }
        break;
        case 3:
          // console.log('晚上签到')
          if (signIn) {
            obj.inBtn = '已签到';
            if (signOut) { //以签退
              obj.up = true;
              obj.upBtn = '已签退';
            } else if (!signOut && api.toMiner(ys, ms, rs) < api.toMiner(23, '00', '00')) {
              obj.up = false;
              obj.upBtn = '签退';
            }
          }else { //没有签到
            if (api.toMiner(ys, ms, rs) < api.toMiner(22, '00', '00') && api.toMiner(ys, ms, rs) > api.toMiner(17, '00', '00')) { //假如在签到时间内
				obj.disabledIn = false;
				obj.inBtn = '签到';
            }
          }
        break;
      }
      return obj;
    },
    toMiner(hour, min, seconds) {
      var miner;
      miner = hour * (60 * 60 * 1000) + min * (60 * 1000) + seconds * 1000;
      return miner;
    },
    getCode(node,width,height,text) { // 生成二维码
		document.getElementById(node).innerHTML = "";
		new QRCode(node,{
			width, // 设置宽度，单位像素
			height, // 设置高度，单位像素
			text // 设置二维码内容或跳转地址
		});
	},
	downLoadImg(canvas) { // 保存图片（从canvas里面获取图片路径保存）
		let image = canvas.toDataURL("image/png");
		// console.log(image)
		let a = document.createElement('a');
		a.setAttribute('href',image);
		a.setAttribute('download','');
		let event = new MouseEvent('click');
		a.dispatchEvent(event);
	},
	isCardNo(val) { // 验证身份证
		var pattern = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
		return pattern.test(val);
	},
	downLoad(node) { // 保存图片（html标签转成图片保存）
		var width = node.offsetWidth;//dom宽
		var height = node.offsetHeight;//dom高
		var scale = 5;//放大倍数
		var canvas = document.createElement('canvas');
		canvas.width = width*scale;//canvas宽度
		canvas.height = height*scale;//canvas高度
		// var ctx = canvas.getContext("2d");
		// ctx.fillStyle = 'transparent';
		// ctx.scale(scale,scale);
		// var rect = node.getBoundingClientRect();//获取元素相对于视察的偏移量
		// console.log(width,height)
		// ctx.translate(-rect.left,-rect.top);//设置context位置，值为相对于视窗的偏移量负值，让图片复位

		html2canvas(node, {
			// width: node.clientWidth, //dom 原始宽度
			// height: node.clientHeight,
			scrollY: 0,
			scrollX: 0,

			dpi: window.devicePixelRatio*2,
			scale:scale,
			canvas:canvas,
			width:width,
			heigth:height,

			// useCors: true,
			allowTaint: true,
			taintTest: true,
			// crossOrigin: "anonymous",
        }).then((canvas) => {
			let image = canvas.toDataURL("image/png");
			// console.log(image)
			let a = document.createElement('a');
			a.setAttribute('href',image);
			a.setAttribute('download','');
			let event = new MouseEvent('click');
			a.dispatchEvent(event);
        });
	},
	getDPR(){
        if (window.devicePixelRatio && window.devicePixelRatio > 1) {
            return window.devicePixelRatio;
        }
        return 1;
    },
	getPath(node) { // 获取图片url（html标签转成图片）
		window.pageYOffset = 0;
		document.documentElement.scrollTop = 0
		document.body.scrollTop = 0
		return new Promise((resolve)=> {
			var width = 793; // node.offsetWidth;//dom宽
			var height = 1122; // node.offsetHeight;//dom高
			/*var scale = 2.1147;//放大倍数
			var canvas = document.createElement('canvas');
			canvas.width = width*scale;//canvas宽度
			canvas.height = height*scale;//canvas高度
			var content = canvas.getContext("2d");
			// content.fillStyle = 'transparent';
			content.scale(scale,scale);
			var rect = node.getBoundingClientRect();//获取元素相对于视察的偏移量
			// console.log(width,height)
			// content.translate(-rect.left,-rect.top);//设置context位置，值为相对于视窗的偏移量负值，让图片复位*/

			setTimeout(()=> {
				html2canvas(node, {
					/*dpi: window.devicePixelRatio*2,
					scale: scale,
					canvas: canvas,*/
					width: width,
					heigth: height,

					useCors: true,
					// crossOrigin: "anonymous",
				}).then((canvas) => {
					// console.log(canvas)
					let image = canvas.toDataURL();
					resolve(image);
				});
			},500);
		});
	},
	copyLink(Node) { // 复制链接
		let input = document.getElementById(Node);
		input.select();
		document.execCommand('copy');
		// this.$toast('复制成功');
	},
    crossDomain(url) { // 处理文件地址跨域问题
		let link = '';
		let baseUrl = api.oss;
		// console.log(url,baseUrl)
		// 处理跨域
		if(!isPro && url.indexOf(baseUrl) !== -1) {
			link = url.replace(baseUrl,api.files);
			return link;
		}else {
			return url;
		}
		// console.log(link)
	},

    upload(params) { // 上传文件
		// file
		return axios.post(api.crm+'/common/upload',params,'file')
    },

	sendCode_kzt(mobileNumber) { // 发送验证码
		return axios.get(api.kzt+'/send/code',{mobileNumber});
	},
	codeLogin_kzt(params) { // 验证码登录
		// mobileNumber
		// code
		return axios.post(api.kzt+'/scan/code/login',params)
	},
	// passLogin_kzt(params) { // 密码登录
	// 	return axios.post(api.kzt+'/',params)
	// },
	changeName_kzt(params) { // 修改真实姓名
		// studentName
		// studentId
		return axios.post(api.kzt+'/change/name',params)
	},
	clueAdd(params) { // 添加个人线索
		// ownerId 线索所有者编号
		// name 姓名
		// mobileNumber 手机号
		return axios.post(api.crm+'/mobile/clue/add',params,1)
	},
	addClue(params) { // 添加公共线索
		// name
		// mobileNumber
		// companyName
		// source
		// studentIdCardNo

		return axios.get(api.crm + '/publicClue/publicClue/kzt/add/clue/plus',params);
	},
	productClue(params) { // 添加线索
		// name
		// mobileNumber
		// companyName
		// source
		// studentIdCardNo
		// shareUserId
		
		return axios.get(api.crm + '/publicClue/publicClue/product/add/clue',params);
	},

	checkPhone(orderId,phone) { // 验证是不是该订单手机号
		// orderId 订单ID
		// phone 手机号
		return axios.get(api.crm+'/mobile/order/checkPhone',{orderId,phone});
	},

	userSigin(params) { // 用户签到
		// signInType 1=代表公开课,3=代表项目实施
		// userName 姓名
		// userSex 性别
		// userPhone 手机号
		// userIdCard 身份证号
		// userCompany 公司
		// objectId 对象ID
		// longitude 经度
		// latitude 纬度
		return axios.post(api.crm+'/mobile/signIn/user',params,1);
	},
	changeIdCard(params) { // 修改用户 身份证/公司
		// studentId 学员编号	
		// studentIdCardNo 学员身份证号	
		// workUnit 公司
		// personalSign 签名图片
		return axios.post(api.kzt+'/change/studentIdCardNo',params);
	},
	
	classInfo(params) { // 公开课签到信息
		// openClassId 公开课ID
		// studentPhone 学员手机号
		return axios.get(api.crm+'/mobile/signIn/open/class/info',params)
	},

	checkStudentSignIn(params) { // 是否已签到
		// dateType 时间类型（1代表上午 2代表下午 3代表晚上）
		// objectId 对象ID
		// signInType 签到类型（1代表公开课 2代表排班 3代表项目实施
		// studentId 学员ID
		// userPhone 学员手机号
		return axios.get(api.crm+'/mobile/signIn/checkStudentSignIn',params);
	},
	schedulingInfo(params) { // 排班签到信息
		// openClassId 公开课ID
		// studentPhone 学员手机号
		return axios.get(api.crm+'/mobile/signIn/scheduling/info',params)
	},
	schedulingSignIn(params) { // 排班 签到/签退
		// objectId 排班ID
		// studentId 学员ID
		// dateType 时间类型（1代表上午 2代表下午 3代表晚上）
		// type 类型（1代表签到 2代表签退）
		// studentIdCard 学员身份证
		// longitude 经度
		// latitude 纬度
		// signInType 签到类型（1代表公开课 2代表排班 3代表项目实施）
		return axios.post(api.crm+'/mobile/signIn/schedulingSignIn',params,1);
	},
	siginInRanking(params) { // 排班排名
		// schedulingId 排班ID
		// dateType 时间类型（1代表上午 2代表下午 3代表晚上）
		// page 1
        // limit 10
		return axios.get(api.crm+'/mobile/signIn/ranking',params);
	},

	implementInfo(params) { // 项目实施签到信息
		// projectSessiontId 项目实施场次ID
		// studentPhone 学员手机号
		return axios.get(api.crm+'/mobile/signIn/project/implement/info',params);
	},
	implementSignIn(params) { // 项目实施签到
		return axios.get(api.crm+'/mobile/signIn/project/implementSignIn',params)
	},


	customerList(type) { // 查询合作方
		// type 类型：1=合作企业；2=合作渠道；3=合作项目
		return axios.get(api.crm+'/mobile/queryCustomerList',{type})
	},
	partnerList(type) { // 查询合伙人/提报人
		// type 类型：1-合伙人（默认），2-提报人
		return axios.get(api.crm+'/mobile/partner/queryList',{type})
	},
	policyList(type,productId,specialtyId) { // 查询合伙人政策/提报人政策
		// type 类型：1-合伙人（默认），2-提报人
		// productId 产品ID
		return axios.get(api.crm+'/mobile/partner/policy/queryList',{type,productId,specialtyId})
	},
	getSharePoster(params) { // 海报分享
		// isPartner 是否提报人：0-是，1-否
		// partnerId 提报人ID
		// policyId 提报政策ID
		// tuitionFee 学费
		// cooperationType 合作类型：
		// cooperationId 合作人ID
		// productId 产品ID
		// isInvoice 是否开票：0-是，1-否
		return axios.get(api.crm+'/mobile/product/getSharePoster',params)
	},
	specialtyListSystem(productId) { // 专业
		// productId 产品ID
		return axios.post(api.crm+'/mobile/product/querySpecialtyList',{productId},1)
	},
	classListSystem(params) { // 班级
		// productId 产品ID
		// specialtyId 专业ID
		return axios.post(api.crm+'/mobile/product/queryClassList',params,1)
	},

	productInfo(productId) { // 产品信息
		// productId 产品ID
		return axios.get(api.crm+'/mobile/product/queryInfo',{productId})
	},
	createOrder(params) { // 报名-相当于创建订单
		// productId 产品ID
		// isPartner 是否合伙人
		// partnerId 合伙人ID
		// policyId 合伙销售政策ID
		// tuitionFee 学费
		// cooperationType 合作类型：1=合作企业；2=合作渠道；3=合作项目
		// isInvoice 是否开票：0-是，1-否
		// cooperationId 合作方ID
		// ownerId
		// studentName 学员姓名
		// studentPhone 学员手机号
		return axios.post(api.crm+'/mobile/product/createOrder',params,1);
	},
	orderInfo(orderId) { // 订单详情
		// orderId 订单ID
		return axios.get(api.crm+'/mobile/order/queryInfo',{orderId});
	},
	courseList(orderId,page) { // 教程列表
		let params = {
			orderId,
			page,
			limit: 100
		}
		// orderId 订单ID
		return axios.get(api.crm+'/mobile/order/queryKztCourseList',params);
	},
	orderTextbookList(orderId) { // 教材列表
		// orderId 订单ID
		return axios.get(api.crm+'/mobile/order/queryOrderTextbookList',{orderId});
	},
	orderUpdate(params) { // 提交订单/更新订单信息
		// orderId 订单ID
		// isSplit 是否分期（0代表是 1代表否）
		// isTextbook 是否要教材（0代表是 1代表否）
		// isDegree 是否要学位（0代表是 1代表否）
		// isBeforeDegree 学位费是否先交（0代表是 1代表否）
		// contractUrl 合同图片
		// isInvoice 是否开票（0代表是 1代表否）
		// clueId 线索ID
		// studentSex 性别
		// studentIdCard 身份证号
		// studentWechatNo 微信号
		// studentPhone 手机号
		// studentEmail 邮箱
		// studentAddress 联系地址
		// frontalImage 身份证正面
		// reversedImage 身份证反面
		// blueImage 蓝底照
		// diplomaImage 毕业证照
		// isPay 是否支付：0-是，1-否（默认）
		return axios.post(api.crm+'/mobile/order/update',params,1);
	},
	getOrderContract(orderId) { // 返回合同文件
		// orderId
		return axios.get(api.crm+'/mobile/contract/getOrderContract',{orderId})
	},
	contractSignIn(params) {
		// let params = {
		// 	file, // 签名图片文件
		// 	orderId
		// };
		return axios.post(api.crm+'/mobile/contract/contractSignIn',params,'file');
	},
	getTextbookPrice(params) { // 查询订单教材金额
		// type 类型：0-订单，1-产品
		// objectId
		// isTextbook 是否要教材：0-是，1-否
		return axios.get(api.crm+'/mobile/order/getTextbookPrice',params);
	},
	addInvoice(params) { // 添加发票
		// orderId 订单ID
		// invoiceHeader 发票抬头
		// invoicePhone 手机号
		// invoiceIdentifyNumber 识别号
		// invoiceExplain 开票说明
		// invoiceEmail 邮箱
		return axios.post(api.crm+'/mobile/order/add/invoice',params,1)
	},
	invoiceInfo(invoiceId) { // 查询发票信息
		// invoiceId 发票ID
		return axios.get(api.crm+'/mobile/order/queryInvoiceInfo',{invoiceId})
	},
	editInvoice(params) { // 修改发票信息
		// orderInvoiceId 发票ID
		// invoiceHeader 发票抬头
		// invoicePhone 手机号
		// invoiceIdentifyNumber 识别号
		// invoiceExplain 开票说明
		// invoiceEmail 接受邮箱
		return axios.post(api.crm+'/mobile/order/edit/invoice',params,1)
	},
	orderShare(orderId,ownerId) { // 订单-海报分享
		// orderId 订单ID
		// ownerId
		return axios.get(api.crm+'/mobile/order/getSharePoster',{orderId,ownerId});
	},

	resourceShare(params) { // 资料-海报分享
		// resourceType 类型：1-销售，2-教学，3-招生
		// resourceId 资料ID
		// ownerId 拥有者
		return axios.get(api.crm+'/mobile/resource/getSharePoster',params);
	},
	resourceInfo(params) { // 资料-详情
		// resourceType 类型：1-销售，2-教学，3-招生
		// resourceId 资料ID
		// ownerId 拥有者
		return axios.get(api.crm+'/mobile/resource/queryInfo',params);
	},


	orderContract(orderId) { // 合同信息
		// orderId 订单ID
		return axios.get(api.crm+'/mobile/order/queryContract',{orderId});
	},

	intentionSend({name,mobileNumber,telephone,companyName,intentionContent}) { // 引流
		// name 姓名
		// mobileNumber 手机号
		// telephone 电话
		// companyName 公司
		// intentionContent 意向
		// source 来源
		let params = {
			name,
			mobileNumber,
			telephone,
			companyName,
			intentionContent,
			source: '公众号'
		}
		return axios.post(api.crm+'/api/clue/add',params,1);
	},
}
export default api;