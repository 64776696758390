<template>
  <div class="image">
    <titles isBack titleName="签到" />
    <div class="Project_wx_sign" :style="{
      backgroundImage: `url('${ bgImg }')`
    }">
      <login :loginShow="loginShow" @login="login" />
      <name :nameMessage="nameMessage" @nameConfirm="nameConfirm" />
      <tips :tips="tips" @btn="signName" @close="close" />
      <location-sign-in ref="myLocationSignIn" :locationData="locationData" @signConfirm="signConfirm" @locationConfirm="locationConfirm" />
      <sign-success :successMessage="successMessage" @close="close" />
      <sign-err :errShow="errShow" :errMessage="errMessage" @close="close" />


      <all-sign-in 
        :signInData="wxSignData" 
        :disabled="disabledIn" 
        @signIn="signIn" 
        @onChange="dateChange" />

    </div>
  </div>
</template>

<script>


import titles from '@/components/titles';
import AllSignIn from '../components/AllSignIn'
import Login from '@/components/dislog/Login'
import Name from '@/components/dislog/Name'
import Tips from '@/components/dislog/Tips'
import LocationSignIn from '@/components/dislog/LocationSignIn'
import SignSuccess from '@/components/dislog/SignSuccess'
import SignErr from '@/components/dislog/SignErr'

export default {
  components: {
    titles,
    AllSignIn,
    Login,
    Name,
    Tips,
    LocationSignIn,
    SignSuccess,
    SignErr
  },
  data() {
    return {
      bgImg: require('../../../assets/image/bg_sign_in@2x.png'), // 背景图片
      projectSessionId: this.$route.query.projectSessionId,
      userInfo: {}, // 用户信息
      projectData: {}, // 项目信息
      disabledIn: true, // 是否已签到: true 是,false 否
      // 弹窗信息
      loginShow: false,
      nameMessage: {
        nameShow: false,
        nameTitle:'请填写真实姓名',
        placeholder: '请输入真实姓名'
      },
      tips: {
        tipsShow: false,
        title: '提示',
        message: '您还有未完善的签名信息,请先完善签名,再进行课程签到。',
        confirmBtn: '去完善'
      },
      locationData: {
        locationShow: false,
        locationSignTitle: ''
      },
      successMessage: {
        successShow: false,
        successTitle: '签到成功'
      },
      errShow: false,
      errMessage: {
        errTitle: '签到失败',
        errDetailMessage: '',
      },

      // 签到组件信息
      wxSignData: {
        top: {
          topTitle: '场次信息',
          topMessage: [
            {
              topLeft: '场次时间',
              topRight: '未设置'
            },
            {
              topLeft: '地点',
              topRight: '未设置'
            },
            {
              topLeft: '场地',
              topRight: '未设置'
            },
          ]
        },
        center: {},
        bottom: {
          bottomTitle: '签到人信息',
          signNameShow: true,
          signPhoneShow: true,
          signIdShow: true,
          signCompanyShow: true,
          signForm: {
            username: '',
            phoneNum: '',
            idNum: '',
            idDis: true, // 身份证是否可编辑: true 是,false 否
            company: '',
            companyDis: false, // 公司是否可编辑
            timeValue: ''
          },
        },
        timeSlot: {
          isShow: true,
          timeSlotTitle: '学习时间段',
          option: [
            // { text: '上午', value: 1 },
            // { text: '下午', value: 2 },
            // { text: '晚上', value: 3 },
          ],
        },
        button: {
          signInBtn: '签到',
          signUpShow: false
        }
      },
      dateList: [
        { text: '上午', value: '1' },
        { text: '下午', value: '2' },
        { text: '晚上', value: '3' }
      ], // 学习时间段
      isSign: false, // true 表示要去签名
      rCode: null, // 只要不是 0 都是不能签到的
    }
  },
  mounted() {
    
  },
  methods: {
    implementInfo() { // 项目实施签到信息
      let params = {
        projectSessionId: this.projectSessionId,
        studentPhone: this.userInfo.mobileNumber
      }
      this.api.implementInfo(params).then(res=> {
        this.rCode = res.code;
        if(res.code === 0) {
          this.getDetail(res.data);
          
          this.dateChange();
        }else {
          this.$dialog({
            message: res.msg
          });

          if(typeof res.data == 'object') {
            this.getDetail(res.data);
          }
        }
      })
    },
    getDetail(data) {
      this.projectData = data;
        let {
          dateTypeList,
          sessionDate,
          sessionAddress,
          place
        } = data;

        let arr = [];
        this.dateList.forEach(item=> {
          dateTypeList.forEach(type=> {
            if(item.value == type) {
              arr.push(item);
            }
          });
        });
        this.wxSignData.timeSlot.option = arr;
        this.wxSignData.bottom.signForm.timeValue = dateTypeList.length?dateTypeList[0]:''

        this.wxSignData.top.topMessage[0].topRight = sessionDate;
        this.wxSignData.top.topMessage[1].topRight = sessionAddress;
        this.wxSignData.top.topMessage[2].topRight = place;
    },
    checkStudentSignIn() { // 是否已签到
      let params = {
        dateType: this.wxSignData.bottom.signForm.timeValue,
        objectId: this.projectSessionId,
        signInType: 3,
        // studentId: this.userInfo.studentId,
        userPhone: this.userInfo.mobileNumber
      }
      this.api.checkStudentSignIn(params).then(res=> {
        if(res.code === 0) {
          let {
            signIn
          } = res.data;
          /*let {
            disabledIn,inBtn
          } = this.api.into(this.wxSignData.bottom.signForm.timeValue,signIn);
          // console.log(disabledIn,inBtn);
          this.disabledIn = disabledIn;
          this.wxSignData.button.signInBtn = inBtn;*/
          this.disabledIn = signIn;
          this.wxSignData.button.signInBtn = signIn?'已签到':'签到';

          // this.disabledIn = false;
        }
      });
    },
    // 签到按钮
    signIn() {
      let signUrl = this.$store.state.sign;
      let {
        studentId,
        studentIdCardNo,
        workUnit,
        isSign,
        personSign
      } = this.userInfo;
      console.log('状态：'+isSign,'我的签名：'+personSign,'新签名：'+signUrl);
      if(/^[0]+$/.test(isSign) && !signUrl) { // 未签名-跳转签名页
        this.tips.tipsShow = true;
        return
      }else if(/^[01]+$/.test(isSign) && !personSign && !signUrl) { // 没有签名
        this.tips.tipsShow = true;
        return
      }else if(/^[1]+$/.test(isSign) && personSign) { // 已有签名
        signUrl = this.userInfo.personSign;
      }/*else if(/^[0]+$/.test(isSign) && signUrl) { // 签名之后把签名图片带过来
        // 
      }*/
      // let { username, phoneNum, idNum } = this.wxSignData.bottom.signForm
      let { idNum,company } = this.wxSignData.bottom.signForm;
      // if(!username) {
      //   this.$toast('请输入姓名');
      //   return
      // }
      // if(!/^[0-9]{11}$/.test(phoneNum)) {
      //   this.$toast('请输入正确的手机号');
      //   return
      // }
      // 新用户需要输入身份证号
      if(!this.api.isCardNo(idNum)) {
        this.$toast('请输入正确的身份证号');
        return
      }
      // 判断是否有签名, 没有则跳转签名页面
      // if(0) {
      //   this.tips.tipsShow = true
      // } else {  //否则弹出定位签到
      // console.log(this.$refs.myLocationSignIn)
      // this.$refs.myLocationSignIn.baiduMap();
      if(!studentIdCardNo || !workUnit || /^[0]+$/.test(isSign)) { // 已经有 身份证号/公司/签名 等，不需要调这个
        let params = {
          studentId: studentId,
          studentIdCardNo: idNum || undefined,
          workUnit: company || undefined,
          personalSign: /^[0]+$/.test(isSign)?signUrl:undefined // isSign 签名状态: 0 未签名,1 已签名
        }
        this.api.changeIdCard(params).then(res=> {
          if(res.code === 0) {
            this.locationData.locationShow = true;
            setTimeout(this.$refs.myLocationSignIn.baiduMap,500);
            
            if(!studentIdCardNo) {
              this.userInfo.studentIdCardNo = params.studentIdCardNo;
            }
            if(!workUnit) {
              this.userInfo.workUnit = params.workUnit;
            }
            if(/^[0]+$/.test(isSign) && signUrl) {
              this.userInfo.isSign = 1;
              this.userInfo.personSign = signUrl;
            }
            this.getInfo(this.userInfo);
            sessionStorage.setItem('userInfo',JSON.stringify(this.userInfo));
          }else {
            this.$dialog({
              message: res.msg
            });
          }
        });
      }else { // 显示签到弹框
        this.locationData.locationShow = true;
        setTimeout(this.$refs.myLocationSignIn.baiduMap,500);
      }
      // }
    },
    signConfirm() {
      console.log(11111)
    },
    //点击去完善,跳转签名页面
    signName() {
      this.tips.tipsShow = false;
      setTimeout(()=> {
        this.$router.push('/productSign');
      },500);
    },
    getSign(data) {
			console.log('获取签名', data);
    },

    // 判断定位
    locationConfirm({lng,lat}) {
      // console.log(lng,lat);
      /*let map = new window.BMap.Map('allmap');
      let pointClass = new window.BMap.Point( this.projectData.longitude , this.projectData.latitude ); // 课程
      let pointMy = new window.BMap.Point( lng , lat ); // 我现在的位置
      let distance = map.getDistance(pointClass,pointMy); // 计算两个距离相差多少
      // console.log(pointClass,pointMy)
      console.log('距离学校',distance,'米')*/
      this.locationData.locationShow = false;
      // if(distance > this.projectData.signInDistance) { // 我的距离 > 课程距离-不可以签到哦
      //   // this.signType
      //   this.errMessage.errDetailMessage = "不在课程签到地点"+this.projectData.signInDistance+"米范围内"
      //   this.errShow = true
      // } else { // 指定范围内-可以签到
        // let params = {
        //   objectId: this.projectSessionId,
        //   studentId: this.userInfo.studentId, 
        //   dateType: this.wxSignData.bottom.signForm.timeValue,
        //   type: 1,
        //   studentIdCard: this.wxSignData.bottom.signForm.idNum,
        //   // : this.wxSignData.bottom.signForm.company,
        //   longitude: lng, 
        //   latitude: lat,
        //   signInType: 3,
        //   company: this.wxSignData.bottom.signForm.company
        // }
        // // console.log(params)
        // this.api.schedulingSignIn(params).then(res=> {
        //   if(res.code === 0) {
            let formData = {
              dateType: this.wxSignData.bottom.signForm.timeValue,
              signInType: 3, // 1=代表公开课,3=代表项目实施
              userName: this.userInfo.studentName, // 姓名
              // userSex: this.projectData., // 性别
              userPhone: this.userInfo.mobileNumber, // 手机号
              userIdCard: this.wxSignData.bottom.signForm.idNum, // 身份证号
              userCompany: this.wxSignData.bottom.signForm.company, // 公司
              objectId: this.projectSessionId,
              longitude: lng,
              latitude: lat,
              signatureUrl: this.userInfo.personSign
            }
            this.api.userSigin(formData).then(data=> {
              if(data.code === 0) {
                this.userInfo.studentIdCardNo = this.wxSignData.bottom.signForm.idNum;
                this.userInfo.workUnit = this.wxSignData.bottom.signForm.company;
                this.getInfo(this.userInfo);
                sessionStorage.setItem('userInfo',JSON.stringify(this.userInfo));
                this.successMessage.successShow = true;
                this.checkStudentSignIn();
              }else {
                if(data['3964']) { // 不在课程签到地点范围内
                  this.errMessage.errDetailMessage = "不在课程签到地点"+this.projectData.signInDistance+"米范围内"
                  this.errShow = true
                }else if(data['3956']) { // 不在学习时间
                  this.errMessage.errDetailMessage = "不在学习时间内进行签到";
                  this.errMessage.errTimeMessage = data['3956']; // [ '0-12点', '11-19点', '17-22点' ]
                  this.errShow = true;
                }else {
                  this.$dialog({
                    message: data.msg
                  });
                }
              }
            });
        //   }else {
        //     this.$toast.fail(res.msg);
        //   }
        // });
      // }
    },

    // 登录
    login(loginMessage) {
      this.loginShow = false;
      this.wxSignData.bottom.signForm.phoneNum = loginMessage.mobileNumber;
      this.userInfo = loginMessage;
      this.implementInfo();
      this.getInfo(this.userInfo);
      if(loginMessage.isNewUser == 1 || !loginMessage.studentName || loginMessage.studentName == 'KZT'){
        this.nameMessage.nameShow = true;
      }else {
        this.addClue();
      }
    },

    // 填写真真实姓名
    nameConfirm(userInfo) {
      console.log(userInfo);
      this.nameMessage.nameShow = false;
      this.userInfo = userInfo;
      this.getInfo(this.userInfo);
      this.addClue();

      // if(nameForm.name){
      //   let params = {
      //     studentName: nameForm.name,
      //     studentId: this.userInfo.studentId
      //   }
      //   this.api.changeName_kzt(params).then(res=> {
      //     if(res.code === 0) {
      //       this.nameMessage.nameShow = false;
      //       this.userInfo = res.data;
      //       // this.wxSignData.bottom.signForm.username = nameForm.name;
      //       this.$toast(res.msg);
      //       this.getInfo(this.userInfo);
      //       sessionStorage.setItem('userInfo',JSON.stringify(res.data));
      //       this.addClue();
      //     }else {
      //       this.$toast(res.msg)
      //     }
      //   });
      // }else {
      //   this.$toast('请输入真实姓名')
      // }
    },
    close() {
      this.errShow = false;
      this.errMessage.errDetailMessage = '';
      this.errMessage.errTimeMessage = null;
      this.successMessage.successShow = false;
      this.tips.tipsShow = false;
    },
    dateChange() { // 切换时段
      // console.log(this.wxSignData.bottom.signForm.timeValue)
      this.disabledIn = true;
      if(this.rCode === 0) {
        this.checkStudentSignIn();
      }
    },
    getInfo(userInfo) {
      this.wxSignData.bottom.signForm.username = userInfo.studentName;
      this.wxSignData.bottom.signForm.phoneNum = userInfo.mobileNumber;
      this.wxSignData.bottom.signForm.idNum = userInfo.studentIdCardNo;
      // 没有身份证号的需要填写
      if(!this.api.isCardNo(userInfo.studentIdCardNo)) {
        this.wxSignData.bottom.signForm.idDis = false;
      }
      this.wxSignData.bottom.signForm.company = userInfo.workUnit;
      this.wxSignData.bottom.signForm.companyDis = userInfo.workUnit?true:false;
    },
    addClue() { // 添加线索人
      let params = {
        name: this.userInfo.studentName || '',
        mobileNumber: this.userInfo.mobileNumber || '',
        companyName: this.userInfo.workUnit || '',
        source: '项目签到',
        studentIdCardNo: this.userInfo.studentIdCardNo || '',
      }
      this.api.addClue(params).then(res=> {
        console.log(res)
        });
    },
  },
  created() {
    // 判断用户是否登录-若已登录则获取用户信息
    if(sessionStorage.getItem('userInfo')) {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      this.userInfo = userInfo;
      this.getInfo(this.userInfo);
      this.implementInfo();
      // 判断是否新用户，是新用户则设置姓名
      // isNewUser 判断是否新用户: 0 否,1 是
      if(userInfo.isNewUser == 1 || !userInfo.studentName || userInfo.studentName == 'KZT') {
        this.nameMessage.nameShow = true
      }
    }else { // 显示登录弹框
      this.loginShow = true
    }
		// this.$bus.on('getSign', this.getSign);
    // console.log('111');
  }
};
</script>

<style scoped>
.Project_wx_sign {
  overflow: scroll;
  text-align: left;
  height: 100%;
  /*background: url('../../../assets/image/bg_sign_in@2x.png') no-repeat top center #f0f6ff;*/
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
}
.Project_wx_sign::-webkit-scrollbar {
  display:none
}
</style>