<template>
<div v-if="show" class="fixed-btn">
	<div class="fixed-btn-content align-center border-box">
		<a 
			:class="[
				'confirm',
				'block',
				'text-center',
				{'disabled': disabled}
			]" 
			href="javascript:;" 
			:style="{
				backgroundColor: bg,
				color: textColor
			}" 
			@click="confirm">
			{{ btnText }}
		</a>
	</div>
</div>
</template>

<script>
export default {
	props: {
		bg: {
			type: String,
			default: '#1E85F4'
		}, // 按钮背景色
		textColor: {
			type: String,
			default: '#fff'
		}, // 字体颜色
		btnText: {
			type: String,
			default: '确定'
		}, // 按钮文字
		show: {
			type: Boolean,
			default: true
		}, // 显示/隐藏: 默认 true
		disabled: {
			type: Boolean,
			default: false
		}, // 按钮状态:true 禁用
	},
	methods: {
		confirm() {
			this.$emit('confirm');
		},
	}
};
</script>

<style scoped>
.fixed-btn,.fixed-btn-content {
	height: 3.2rem;
}
.fixed-btn-content {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;
	padding: .5rem 1rem;
	z-index: 99;
}
.confirm {
	width: 100%;
	height: 2.2rem;
	line-height: 2.2rem;
	border-radius: 1.1rem;
	font-size: .8rem;
}
</style>