<template>
<div>
	<van-popup 
		class="share" 
		:closeable="false" 
		:close-on-click-overlay="false" 
		:style="{
			backgroundImage: 'url('+ shareBg +')'
		}" 
		v-model="isShow" 
		@click-close-icon="shareClose">
		<div v-if="type == 2" class="share-check align-center">
			<span class="share-check-label bold">是否可开发票</span>
			<a 
				class="share-check-btn align-center bold" 
				href="javascript:;" 
				v-for="(item,index) of invoiceData" 
				:key="index" 
				@click="invoiceClick(item.type)">
				<div class="share-check-btn-icon">
					<img class="image" :src="invoiceType == item.type?iconCheck:iconUncheck">
				</div>
				&nbsp;
				{{ item.name }}
			</a>
		</div>
		<p v-else-if="type == 3" class="share-title text-center bold">资料分享</p>
		<p v-else class="share-title text-center bold">产品分享</p>

		<div class="share-content border-box" ref="content">
			<!-- <div class="share-content-pic">
				<img class="image" :src="productDetail.image">
				<p>{{ productDetail.name }}</p>
			</div>
			<div class="share-user flex between">
				<div class="share-user-head align-center">
					<div ref="headImg" class="share-user-head-img">
						<img class="image" :src="productDetail.userImage" :style="{backgroundImage: `url('${headNull}')`}">
					</div>
					<div>
						<p>{{ productDetail.username }}</p>
						邀请您报名
					</div>
				</div>
				<div id="qrcode" class="share-qrcode">
					<img class="image" :src="productDetail.qrcode">
				</div>
			</div> -->
			<vue-canvas-poster :painting="painting" @success="canvasSuccess" @fail="canvasFail"></vue-canvas-poster>
		</div>

		<div class="share-bottom align-center between">
			<a class="share-btn share-btn-save border-box align-center" href="javascript:;" @click="downLoad">
				保存图片
			</a>
			<a class="share-btn share-btn-copy border-box align-center" href="javascript:;" @click="copyLink">复制链接</a>
		</div>
		<div style="height: 0;opacity: 0;">
			<input id="copy-content" type="text" readonly v-model="productDetail.url" />
		</div>
		<!-- <div class="hidden">
			<canvas id="poster-canvas"></canvas>
		</div> -->
	</van-popup>
</div>
</template>

<script>
export default {
	data() {
		return {
			productDetail: {}, // 产品详情
			type: this.$route.query.type || null, // 1 产品,2 订单,3 资料
			orderId: this.$route.query.orderId, // 对象id
			ownerId: this.$route.query.ownerId,
			isShow: true,
			headNull: require('../../assets/image/head_null.png'),
			shareBg: require('../../assets/image/bg_share_2.png'),
			invoiceData: [
				{name: '是',type: 0},
				{name: '否',type: 1},
			],
			invoiceType: 0,
			iconUncheck: require('../../assets/image/icon_unselected.png'), // 未选中
			iconCheck: require('../../assets/image/icon_selected.png'), // 选中
			poster: require('../../assets/image/pic_share.png'), // 固定的海报

			posterImg: '',//生成的海报图片路径
			painting: {
				width: '544px',
				height: '564px',
				// background: 'https://ddcz.oss-cn-beijing.aliyuncs.com/images/file-1587004539522R5oa.png',
				background: '#1E85F4',
				borderRadius: '12px',
				views: [{
						type: 'rect', // 海报白色背景
						css: {
							top: '20px',
							left: '20px',
							width: '504px',
							height: '376px',
							borderRadius: '8px',
							color: '#fff'
						},
					},{
						type: 'image', // 海报
						url: require('../../assets/image/pic_share.png'),
						css: {
							top: '20px',
							left: '20px',
							width: '504px',
							height: '278px',
						},
					},{
						type: 'text', // 产品名称
						text: '',
						css: {
							top: '314px',
							left: '40px',
							// width: '140px',
							maxLines: 1,
							fontSize: '32px',
							color: '#242424'
						},
					},{
						type: 'image', // 头像
						url: require('../../assets/image/head_null.png'),
						css: {
							top: '432px',
							left: '20px',
							borderRadius: '40px',
							borderWidth: '4px',
							borderColor: '#fff',
							width: '80px',
							height: '80px',
						},
					}, {
						type: 'text', // 名字
						text: '',
						css: {
							top: '432px',
							left: '118px',
							// width: '140px',
							maxLines: 1,
							fontSize: '28px',
							color: '#fff'
						},
					}, {
						type: 'text',
						text: '',
						css: {
							top: '472px',
							left: '118px',
							// width: '140px',
							maxLines: 1,
							fontSize: '28px',
							color: '#fff'
						},
					}, {
					type: 'qrcode', // 二维码
					content: '',
					css: {
						bottom: '20px',
						right: '32px',
						color: '#000',
						background: '#fff',
						width: '128px',
						height: '128px',
						// borderWidth: '10px',
						// borderColor: '#fff'
					},
				},],
			},
		}
	},
	beforeRouteLeave(to, form, next) {
		sessionStorage.removeItem('productDetail');
		next();
	},
	mounted() {
		// this.$nextTick(()=> {
			// console.log('type',this.type)
			if(/^[2]+$/.test(this.type)) { // 订单分享
				this.orderShare();
			}else if(/^[3]+$/.test(this.type)) { // 资料分享
				let {
					resourceType,
					resourceId,
					ownerId
				} = this.$route.query;
				let params = {
					resourceType: resourceType,
					resourceId: resourceId,
					ownerId: ownerId
				}
				this.api.resourceShare(params).then(res=> {
					if(res.code === 0) {
						this.productDetail = {
							image: this.poster,
							name: res.data.name,
							userImage: res.data.userImage,
							// userImage: '',
							username: res.data.username,
							url: res.data.url, // 查看资料前面的填写 姓名/手机号 页面
						}
						// this.api.getCode('qrcode',64,64,this.productDetail.url);
						let {
							name,
							url,
							userImage,
							username
						} = res.data;
						setTimeout(()=> {
							// this.painting.views[1].url = this.poster;
							this.painting.views[2].text = name;
							this.painting.views[3].url = this.api.crossDomain(userImage); //.indexOf(this.api.files) !== -1?this.headNull:userImage;
							// console.log(this.api.crossDomain(userImage))
							this.painting.views[4].text = username;
							this.painting.views[5].text = '邀请您查看';
							this.painting.views[6].content = url;
						},1000);
						
						// this.api.getBlob(res.data.userImage).then(img=> {
						// 	this.$set(this.productDetail,'userImage',img);
						// })
					}else {
						this.$toast(res.msg);
					}
				});
			}else { // 产品分享
				if(sessionStorage.getItem('productDetail')) {
					// this.productDetail = JSON.parse(sessionStorage.getItem('productDetail'));
					let {
						image,
						name,
						url,
						userImage,
						username
					} = JSON.parse(sessionStorage.getItem('productDetail'));
					setTimeout(()=> {
						this.$set(this.productDetail,'url',url);
						this.painting.views[1].url = this.api.crossDomain(image); //.indexOf(this.api.files) !== -1?this.poster:image;
						this.painting.views[2].text = name;
						this.painting.views[3].url = this.api.crossDomain(userImage); //.indexOf(this.api.files) !== -1?this.headNull:userImage;
						this.painting.views[4].text = username;
						this.painting.views[5].text = '邀请您报名';
						this.painting.views[6].content = url;
					},1000);

					// let canvas = document.getElementsByClassName('canvas')[0];
					// console.log(canvas.width,canvas.clientWidth)
					// canvas.width = canvas.clientWidth;

					// console.log(this.productDetail.url)
					// let qrCode = "data:image/jpeg;base64,"+this.productDetail.qrCode;
					// this.$set(this.productDetail,'qrcode',qrCode);
					// this.api.getCode('qrcode',64,64,this.productDetail.url); // 产品详情页
					// this.api.getBlob(this.productDetail.userImage).then(img=> {
					// 	this.$set(this.productDetail,'userImage',img);
					// });
					// this.api.getBlob(this.productDetail.image).then(img=> {
					// 	this.$set(this.productDetail,'image',img);
					// })
				}
			}
		// });
	},
	methods: {
		shareClose() {
			window.close()
		},
		orderShare() { // 订单分享
			this.api.orderShare(this.orderId,this.ownerId).then(res=> {
				if(res.code === 0) {
					this.invoiceType = res.data.isInvoice || 0;
					this.productDetail = {
						image: this.poster,
						name: '',
						userImage: res.data.image,
						// userImage: '',
						username: res.data.name,
						url: res.data.url, // 查看订单前面的登录页面
					}
					let {
						image,
						name,
						url
					} = res.data;
					setTimeout(()=> {
						// this.painting.views[1].url = this.poster;
						// this.painting.views[2].text = 
						this.painting.views[3].url = this.api.crossDomain(image); //.indexOf(this.api.files) !== -1?this.headNull:image;
						this.painting.views[4].text = name;
						this.painting.views[5].text = '邀请您查看订单';
						this.painting.views[6].content = url;
						// this.api.getCode('qrcode',64,64,this.productDetail.url);
					},1000);

					// this.api.getBlob(res.data.image).then(img=> {
					// 	console.log(img)
					// 	// this.$set(this.productDetail,'userImage',img);
					// })

					/*// 创建画布
					let pCanvas = document.getElementById('poster-canvas');
					let content = this.$refs.content;
					let fold = 5; // 缩放倍数
					let cw = content.clientWidth*fold,
						ch = content.clientHeight*fold;
					pCanvas.width = cw;
					pCanvas.height = ch;
					let ctx = pCanvas.getContext("2d");
					let isRight = false;
					// 蓝色背景
					this.roundRect(ctx,0,0,cw,ch,6*fold,'#1E85F4');

					setTimeout(()=> {
						this.drawBg(ctx,fold,()=> {
							console.log("timeout doing")
							// 海报
							let pic = new Image();
							pic.setAttribute('crossOrigin', 'anonymous');
							pic.src = this.poster;
							pic.onload = function() {
								console.log("timeout 画图")
								ctx.drawImage(pic,10*fold,10*fold,252*fold,139*fold);
								isRight = true;
							}
						});
						// 产品名称
						ctx.font = 16*fold+"px";
						ctx.fillStyle = "#242424";
						ctx.fillText('这是产品名称', 20*fold, 157*fold);
						ctx.font = 14*fold+"px";
						ctx.fillStyle = "#fff";
						ctx.fillText(res.data.name, 59*fold, 220*fold);
						ctx.fillText('邀请您报名', 59*fold, 236*fold);
						// 头像
						// this.drawHead(ctx,this.headNull,fold,10,216,40,40);
						this.drawHead(ctx,res.data.image,fold,10,216,40,40);

						// 二维码
						let qCanvas = document.getElementById('qrcode').getElementsByTagName('img')[0];
						// console.log(qCanvas)
						let qr = new Image();
						qr.src = qCanvas.src;
						qr.onload = function() {
							// console.log(qr)
							ctx.drawImage(qCanvas,192*fold,208*fold,64*fold,64*fold);
						}

						let url = null;
						if(isRight) {
							url = pCanvas.toDataURL("image/png");
							console.log(url)
						}else {
							setTimeout(()=> {
								url = pCanvas.toDataURL("image/png");
								console.log(url)
							},1000);
						}
					},1000);*/
				}else {
					this.$toast(res.msg)
				}
			});
		},
		canvasSuccess(src) {
			this.posterImg = src;
			// console.log(src)
		},
		canvasFail(err) {
			console.log(err)
		},
		// drawBg(ctx,fold,callback) {
		// 	// 海报的白色背景
		// 	this.roundRect(ctx,10*fold,10*fold,252*fold,188*fold,6*fold,'#fff');
		// 	setTimeout(function () {
		// 		(callback && typeof (callback) === "function") && callback();
		// 	}, 1000)
		// 	console.log("timeout after")
		// },
		// roundRect(ctx, x, y, w, h, r, c = '#fff') { // canvas绘制圆角矩形
		// 	if (w < 2 * r) { r = w / 2; }
		// 	if (h < 2 * r) { r = h / 2; }

		// 	ctx.beginPath();
		// 	ctx.fillStyle = c;

		// 	ctx.arc(x + r, y + r, r, Math.PI, Math.PI * 1.5);
		// 	ctx.moveTo(x + r, y);
		// 	ctx.lineTo(x + w - r, y);
		// 	ctx.lineTo(x + w, y + r);

		// 	ctx.arc(x + w - r, y + r, r, Math.PI * 1.5, Math.PI * 2);
		// 	ctx.lineTo(x + w, y + h - r);
		// 	ctx.lineTo(x + w - r, y + h);

		// 	ctx.arc(x + w - r, y + h - r, r, 0, Math.PI * 0.5);
		// 	ctx.lineTo(x + r, y + h);
		// 	ctx.lineTo(x, y + h - r);

		// 	ctx.arc(x + r, y + h - r, r, Math.PI * 0.5, Math.PI);
		// 	ctx.lineTo(x, y + r);
		// 	ctx.lineTo(x + r, y);

		// 	ctx.fill();
		// 	ctx.closePath();
		// },
		// drawHead(ctx,url,fold,x,y,w,h) { // 绘制头像
		// 	let img = new Image();
		// 	// img.setAttribute('crossOrigin', 'anonymous');
		// 	img.src = url;
		// 	img.style = `border: ${ 4*fold }px solid #fff;border-radius: 50%;`;
		// 	img.onload = function() {
		// 		// ctx.drawImage(img,10*fold,216*fold,40*fold,40*fold);
		// 		ctx.save()
		// 		let width = w*fold;
		// 		let height = h*fold;
		// 		let min = Math.min(width, height)

		// 		let circle = {
		// 			x: Math.floor(min / 2),
		// 			y: Math.floor(min / 2),
		// 			r: Math.floor(min / 2)
		// 		}

		// 		ctx.fillStyle = ctx.createPattern(img, 'no-repeat')
		// 		ctx.beginPath()
		// 		//开始路径画圆,剪切处理
		// 		ctx.arc(circle.x + (x*fold), circle.y + (y*fold), circle.r, 0, Math.PI * 2, false);

		// 		ctx.clip()
		// 		ctx.drawImage(img, x*fold, y*fold, 2 * circle.r, 2 * circle.r)//改成这种方式可以画圆形头像，且不被切割
		// 	}
		// },
		invoiceClick(type) { // 是否可开发票
			this.invoiceType = type;
			let params = {
				orderId: this.orderId,
				isInvoice: type
			}
			this.api.orderUpdate(params).then(res=> {
				if(res.code === 0) {
					this.orderShare();
				}else {
					this.$toast(res.msg);
				}
			})
		}, // 是否可开发票
		downLoad() { // html标签转成图片保存
			// this.api.downLoad(this.$refs.content);
			let a = document.createElement('a');
			a.setAttribute('href',this.posterImg);
			a.setAttribute('download','');
			let event = new MouseEvent('click');
			a.dispatchEvent(event);
		},
		copyLink() { // 复制链接
			this.api.copyLink('copy-content');
			this.$toast('复制成功');
		},
	}
};
</script>

<style scoped>
.share {
	width: 15.7rem;
	height: 22.6rem;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center center;
	padding: 2rem 1.05rem 1.2rem;
	box-sizing: border-box;
	/*right: 1.5rem;
	left: 1.5rem;*/
	left: 50%;
	transform: translate3d(-50%,-50%,0);
	/*width: 314px;*/
	border-radius: .5rem;
	font-size: .7rem;
	color: #242424;
}
.share-title {
	font-size: 1rem;
}

.share-check {
	margin-bottom: .85rem;
	font-size: .8rem;
}
.share-check-label {
	color: #1E85F4;
}
.share-check-btn {
	line-height: 1;
	margin-left: 1.5rem;
	color: #242424;
}
.share-check-btn-icon {
	width: 1rem;
	height: 1rem;
	margin-right: .5rem;
}

.share-content {
	/*width: 13.6rem;*/
	width: 100%;
	height: 14.1rem;
	/*background-color: #1E85F4;*/
	/*border-radius: .3rem;*/
	margin: .51rem auto 0;
	/*padding: .5rem;*/
	/*overflow: hidden;*/
}
.share-content >>> div {
	width: 100%;
	height: 100%;
}
.share-content >>> .canvas {
	width: 100% !important;
	height: 100% !important;
	position: static;
}
.share-content-pic {
	height: 9.4rem;
	background-color: #F8FBFF;
	border-radius: .2rem;
}
.share-content-pic img {
	height: 6.95rem;
}
.share-content-pic p {
	margin-top: .4rem;
	padding: 0 .5rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: bold;
	font-size: .8rem;
	color: #242424;
}
.share-user {
	padding-top: .5rem;
}
.share-user-head {
	margin-top: .4rem;
}
.share-user-head-img {
	width: 2rem;
	height: 2rem;
	border: .15rem solid #fff;
	border-radius: 50%;
	overflow: hidden;
}
.share-user-head-img img {
	background-size: 105% 105%;
	background-repeat: no-repeat;
	background-position: center center;
}
.share-user-head div {
	line-height: 1rem;
	margin-left: .45rem;
	font-size: .7rem;
	color: #fff;
}

.share-qrcode {
	width: 3.2rem;
	height: 3.2rem;
	margin-right: .3rem;
}
.share-bottom {
	margin-top: .9rem;
}
.share-btn {
	width: 5.7rem;
	height: 1.8rem;
	/*line-height: 1.8rem;*/
	border-radius: 1.25rem;
	box-sizing: border-box;
	justify-content: center;
	text-align: center;
	font-size: .6rem;
}
.share-btn-save {
	border: 1px solid #1E85F4;
	background: linear-gradient(180deg, #FFFFFF 0%, #E1EFFF 100%);
	color: #1E85F4;
}
.share-btn-copy {
	background: linear-gradient(180deg, #55A5FB 0%, #1E85F4 100%);
	color: #fff;
}
</style>