import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/style/reset.css';

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
import store from './store'

// import VueBus from 'vue-bus';
// Vue.use(VueBus);

import api from '@/api/api';
Vue.prototype.api = api;

import VueCanvasPoster from 'vue-canvas-poster';
Vue.use(VueCanvasPoster);

// import BaiduMap from 'vue-baidu-map'
 
// Vue.use(BaiduMap, {
//     ak: 'rHcKgBozU6jRoq8p5uUKdckQ0YGXellG'
// });

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
