<template>
  <div class="login">
    <van-dialog v-model="loginShow" :showConfirmButton="false" ref="dialog">
      <div class="login_head">
        <div class="head_logo">
          <img :src="imgURL" class="logo_img" />
        </div>
        <div class="head_title">
          {{ loginTitle }}
        </div>
      </div>

      <div class="login_form">
        <van-form :model="loginForm">

          <van-field
            v-model="loginForm.phone"
            type="number"
            clearable
            :disabled="numPass"
            placeholder="手机号"
            maxlength="11"
            inputmode="tel"
            class="new_input"
          />
          <van-field
            v-model="loginForm.sms"
            clearable
            center
            placeholder="验证码"
            class="new_input1"
          >
            <template #button>
              <van-button 
                v-if='loginForm.phone.length === 11 && showCountdown == true' 
                ref="smsCode"  
                slot="button" 
                size="small"
              >
                <van-count-down 
                  :time="time" 
                  style="color:#777" 
                  @finish='countDownFinish' 
                  format=" ss 秒后重试" 
                />
              </van-button>
              <van-button 
                v-else-if="loginForm.phone.length === 11"  
                @click="getSmsCode"  
                slot="button" 
                size="small"
                type="info"
                native-type="button"
              >
                获取验证码
              </van-button>
              <van-button 
                v-else     
                slot="button" 
                size="small"
                type="info"
                @click="errNum"
              >
                获取验证码
              </van-button>
            </template>
          </van-field>
          <div style="margin: 16px auto; width: 80%;">
            <van-button 
              type="info" 
              block 
              round 
              :disabled="loginLoading" 
              :loading="loginLoading" 
              loading-text="登录中..." 
              @click="onConfirm">
              登录
            </van-button>
          </div>
        </van-form>
      </div>
    </van-dialog>
  </div>

</template>

<script>
export default {
  props: {
    loginShow: {
      type: Boolean,
      default: false
    },
    isPhone: {
      type: Boolean,
      default: false
    }, // 验证是不是该订单手机号: true 验证,false 不验证
  },
  data() {
    return {
      loginTitle: "考证通",
      imgURL: require('../../assets/image/APPlogo@2x.png'),
      time: 60 * 1000,
      showCountdown: false,
      numPass: false,
      loginForm: {
        phone: "",
        sms: ""
      },
      loginLoading: false
    }
  },
  mounted() {
    
  },
  methods: {
    // 手机号不正确点击获取验证码按钮
    errNum() {
      this.$toast('请输入正确手机号');
    },
    getSmsCode(){ // 获取验证码
      this.api.sendCode_kzt(this.loginForm.phone).then(res=> {
        if(res.code === 0) {
          this.showCountdown = true
          this.numPass = true
        }else {
          this.$toast(res.msg);
        }
      });
    },
    countDownFinish(){ // 倒计时结束
      this.showCountdown = false 
      this.numPass = false
    },
    verifyPhone() { // 验证是不是该订单手机号
      this.api.checkPhone(this.$route.query.orderId,this.loginForm.phone).then(res=> {
        if(res.code === 0) {
          if(res.data && res.data === true) {
            this.login();
          }else {
            this.$toast('不是该订单手机号，请修改！')
          }
        }else {
          this.$toast(res.msg);
        }
      })
    },
    login() { // 调用登录接口
      let params = {
        mobileNumber: this.loginForm.phone,
        code: this.loginForm.sms,
      }
      this.loginLoading = true;
      setTimeout(()=> {
        if(this.loginLoading) {
          this.loginLoading = false;
          this.$toast('网络错误，请稍后再试...')
        }
      },1000*10);
      this.loginLoading = false;
      this.api.codeLogin_kzt(params).then(res=> {
        if(res.code === 0) {
          this.loginLoading = false;
          this.$toast({ message:'登录成功', color: '#fff',background: '#1E85F4',});
          sessionStorage.setItem('userInfo',JSON.stringify(res.data));
          this.$emit('login',res.data);
        }else {
          this.loginLoading = false;
          this.$toast(res.msg);
        }
      });
    },
    onConfirm() { // 点击登录按钮
      const loginMesage = this.loginForm
      if(!/^[0-9]{11}$/.test(loginMesage.phone)) {
        this.$toast('请输入正确的手机号');
        return
      }
      if(!/^[0-9]{6}$/.test(loginMesage.sms)) {
        this.$toast('请输入正确的验证码');
        return
      }
      if(this.isPhone) {
        this.verifyPhone();
      }else {
        this.login();
      }
    },
  }
}
</script>

<style scoped>
.login_head {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto;
}
.login_head .head_logo .logo_img {
  width: 70px;
  text-align: center;
  margin: 10px;
}
.head_title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: #242424;
}
.new_input {
  margin: 15px auto 10px auto;
  width: 80%;
  background: #F8FBFF;
  border: 1px solid #E2E2E2;
  border-radius: 6px;
  padding: 8px !important
}
.new_input1 {
  margin: 15px auto 10px auto;
  width: 80%;
  background: #F8FBFF;
  border: 1px solid #E2E2E2;
  border-radius: 6px;
  padding: 4px !important
}
</style>
