<template>
  <div class="image">
    <titles isBack titleName="签到" />
    <div class="educational_wx_sign" :style="{
      backgroundImage: `url('${ bgImg }')`
    }" @touchmove="more($event)">
      <login :loginShow="loginShow" @login="login" />
      <name :nameMessage="nameMessage" @nameConfirm="nameConfirm" />
      <tips :tips="tips" @btn="signName" />
      <location-sign-in ref="myLocationSignIn" :locationData="locationData" @locationConfirm="locationConfirm" />
      <sign-success :successMessage="successMessage" @close="successClose" />
      <sign-err :errShow="errShow" :errMessage="errMessage" @close="errClose" />

      <!-- 签到人信息 -->
      <all-sign-in 
        :disabled="disabledIn" 
        :disabledUp="disabledUp" 
        :signInData="educationalWxData" 
        :classData="classData" 
        @signIn="signIn" 
        @signUp="signUp" 
        @onChange="dateChange" />

      <!-- 签到-排名 -->
      <!-- <div 
        class="group-container" 
        v-infinite-scroll="siginInRanking" 
        infinite-scroll-disabled="ranBusy" 
        infinite-scroll-distance="10"> -->
        <ranking :data="ranList" :myRan="myRan" />
      <!-- </div> -->
    </div>
  </div>
</template>

<script>

import titles from '@/components/titles';
import AllSignIn from '../components/AllSignIn'
import Ranking from '../components/Ranking'
import Login from '@/components/dislog/Login'
import Name from '@/components/dislog/Name'
import Tips from '@/components/dislog/Tips'
import LocationSignIn from '@/components/dislog/LocationSignIn'
import SignSuccess from '@/components/dislog/SignSuccess'
import SignErr from '@/components/dislog/SignErr'

// import infiniteScroll from 'vue-infinite-scroll';

export default {
  directives: {
    // infiniteScroll
  },
  components: {
    titles,
    AllSignIn,
    Ranking,
    Login,
    Name,
    Tips,
    LocationSignIn,
    SignSuccess,
    SignErr
  },
  data() {
    return {
      bgImg: require('../../../assets/image/bg_sign_in@2x.png'), // 背景图片
      schedulingId: this.$route.query.schedulingId, // 公开课id（扫码传进来的）9
      userInfo: {}, // 用户信息
      schedulingData: {}, // 课程信息
      disabledIn: true, // 签到 按钮是否可以点击: true 是
      disabledUp: true, // 签退 按钮是否可以点击: true 是
      loginShow: false,
      nameMessage: {
        nameShow: false,
        nameTitle:'请填写真实姓名',
        placeholder: '请输入真实姓名'
      },
      tips: {
        tipsShow: false,
        title: '提示',
        message: '您还有未完善的签名信息,请先完善签名,再进行课程签到。',
        confirmBtn: '去完善'
      },
      locationData: {
        locationShow: false,
        locationSignTitle: '定位签到'
      },
      successMessage: {
        successShow: false,
        successTitle: '签到成功'
      },
      errShow: false,
      errMessage: {
        errTitle: '签到失败',
        errDetailMessage: '',
      },
      classData: [], // 上课时间
      educationalWxData: {
        top: {
          topTitle: '课程信息',
          topMessage: [
            {
              topLeft: '课程名称',
              topRight: '未设置'
            },
            {
              topLeft: '科目',
              topRight: '未设置'
            },
            {
              topLeft: '班级',
              topRight: '未设置'
            },
            {
              topLeft: '科室',
              topRight: '未设置'
            }
          ]
        },
        center: {
          centerTitle: '上课时间',
          centerMessage: []
        },
        bottom: {
          bottomTitle: '签到人信息',
          signNameShow: true,
          signPhoneShow: true,
          signIdShow: true,
          signForm: {
            username: '',
            phoneNum: '',
            idNum: '',
            idDis: true,
            timeValue: 1
          }
        },
        timeSlot: {
          isShow: true,
          timeSlotTitle: '学习时间段',
          option: [
            { text: '上午', value: 1 },
            { text: '下午', value: 2 },
            { text: '晚上', value: 3 },
          ],
        },
        button: {
          signInBtn: '签到',
          signUpBtn: '签退',
          signUpShow: true
        }
      },
      isLocation: false,
      signType: null, // 类型: 1 签到,2 签退
      ranParams: {
        schedulingId: undefined,
        dateType: undefined,
        page: 1,
        limit: 10
      }, // 排名参数
      ranTotal: 0,
      isLoading: false, // true 表示正在加载中
      ranBusy: false,
      ranList: [], // 排名数据
      myRan: {}, // 我的排名
    }
  },
  mounted() {
    // this.$refs.myLocationSignIn.baiduMap();
  },
  created() {
    /*this.$toast.loading({
      message: '加载中...',
      duration: 1000 * 10,
      forbidClick: true
    });*/
    // 判断用户是否登录-若已登录则获取用户信息
    if(sessionStorage.getItem('userInfo')) {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      this.userInfo = userInfo;
      // this.getInfo(this.userInfo);
      this.schedulingInfo();
      // 判断是否新用户，是新用户则设置姓名
      // isNewUser 判断是否新用户: 0 否,1 是
      // if(userInfo.isNewUser == 1) {
      //   this.nameMessage.nameShow = true
      // }
    }else { // 显示登录弹框
      this.loginShow = true
    }
  },
  methods: {
    // 签到按钮
    signIn() {
      this.signType = 1;
      if(!/^[0-9]+$/.test(this.schedulingData.studentId)) {
        this.$toast('您不是本课程学员！');
        return;
      }
      let { idNum } = this.educationalWxData.bottom.signForm
      // this.errMessage.errDetailMessage = "不在签到地点200米范围内"
      /*if(!username) {
        this.$toast('请输入姓名');
        return
      }
      if(!/^[0-9]{11}$/.test(phoneNum)) {
        this.$toast('请输入正确的手机号');
        return
      }*/
      if(!this.api.isCardNo(idNum)) {
        this.$toast('请输入正确的身份证号');
        return
      }
      // if(0) { // 如果没有签名, 则跳转签名页面
      //   this.tips.tipsShow = true
      // } else if(!this.isLocation) {  //如果定位不成功,弹出定位签到
        this.locationData.locationShow = true;
        setTimeout(this.$refs.myLocationSignIn.baiduMap,500);
      // } else if(0) { //如果不在规定时间内
      //   this.errMessage.errTitle = '签到失败'
      //   this.errMessage.errDetailMessage = "不在学习时间内进行签到"
      //   this.errMessage.errTimeMessage = [ '0-12点', '11-19点', '17-22点' ]
      //   this.errShow = true
      // } else { // 显示地图
      //     this.successMessage.successShow = true
      // }
    },

    // 签退按钮
    signUp() {
      this.signType = 2;
      if(!/^[0-9]+$/.test(this.schedulingData.studentId)) {
        this.$toast('您不是本课程学员！');
        return;
      }
      // this.successMessage.successTitle = '签退成功'
      // this.errMessage.errTitle = '签退失败'
      // this.errMessage.errDetailMessage = "不在签退地点200米范围内"
      let { idNum } = this.educationalWxData.bottom.signForm
      /*if(!username) {
        this.$toast('请输入姓名');
        return
      }
      if(!/^[0-9]{11}$/.test(phoneNum)) {
        this.$toast('请输入正确的手机号');
        return
      }*/
      if(!this.api.isCardNo(idNum)) {
        this.$toast('请输入正确的身份证号');
        return
      }
      // if(!this.isLocation) {  //如果定位不成功,弹出定位签到
        this.locationData.locationShow = true;
        setTimeout(this.$refs.myLocationSignIn.baiduMap,500);
      // } else if(1) { //如果不在规定时间内
      //     this.errMessage.errDetailMessage = "不在学习时间内进行签退"
      //     this.errMessage.errTimeMessage = [ '签到后至当日14点', '11-签到后至当日20点', '17-签到后至当日23点' ]
      //     this.errShow = true
      // } else {
      //     this.successMessage.successShow = true
      // }
    },
    
    //点击去完善,跳转签名页面
    signName() {
      // if(1) {
      //   this.$router.push('productSign')
      //   this.tips.tipsShow = false
      // }
    },
    getSign(data) {
			console.log('获取签名', data);
    },

    // 判断定位
    locationConfirm({lng,lat}) {
      console.log(lng,lat);
      /*let map = new window.BMap.Map('allmap');
      let pointClass = new window.BMap.Point( this.schedulingData.longitude , this.schedulingData.latitude ); // 课程
      let pointMy = new window.BMap.Point( lng , lat ); // 我现在的位置
      let distance = map.getDistance(pointClass,pointMy);
      console.log('距离学校',distance,'米')*/
      this.locationData.locationShow = false;
      // if(distance > this.schedulingData.signInDistance) { // 我的距离 > 课程距离-不可以签到哦
      //   // this.signType
      //   this.errMessage.errTitle = `${ this.signType == 1?'签到':'签退' }失败`;
      //   this.errMessage.errDetailMessage = "不在课程签到地点"+this.schedulingData.signInDistance+"米范围内"
      //   this.errShow = true
      // } else { // 指定范围内-可以签到
        let params = {
          objectId: this.schedulingId,
          studentId: this.schedulingData.studentId, 
          dateType: this.educationalWxData.bottom.signForm.timeValue,
          type: this.signType,
          studentIdCard: this.educationalWxData.bottom.signForm.idNum,
          longitude: lng, 
          latitude: lat,
          signInType: 2
        }
        // console.log(params)
        this.api.schedulingSignIn(params).then(res=> {
          if(res.code === 0) {
            this.successMessage.successTitle = `${ this.signType == 1?'签到':'签退' }成功`;
            this.successMessage.successShow = true
            this.dateChange();
          }else {
            if(res['3964']) {
              this.errMessage.errTitle = `${ this.signType == 1?'签到':'签退' }失败`;
              this.errMessage.errDetailMessage = "不在课程签到地点"+this.schedulingData.signInDistance+"米范围内"
              this.errShow = true
            }else {
              this.$toast.fail(res.msg);
            }
          }
        });
      // }
    },
    
    // 登录
    login(loginMessage) {
      this.loginShow = false;
      this.userInfo = loginMessage;
      // this.getInfo(this.userInfo);
      this.schedulingInfo();
      // 判断是否新用户，是新用户则设置姓名
      // isNewUser 判断是否新用户: 0 否,1 是
      // if(loginMessage.isNewUser == 1) {
      //   this.nameMessage.nameShow = true
      // }
    },

    // 填写真真实姓名
    nameConfirm(userInfo) {
      this.nameMessage.nameShow = false;
      this.userInfo = userInfo;
      this.schedulingData.studentName = userInfo.studentName;
      this.educationalWxData.bottom.signForm.username = userInfo.studentName;

      // if(nameForm.name){
      //   let params = {
      //     studentName: nameForm.name,
      //     studentId: this.userInfo.studentId
      //   }
      //   this.api.changeName_kzt(params).then(res=> {
      //     if(res.code === 0) {
      //       this.nameMessage.nameShow = false;
      //       this.userInfo = res.data;
      //       this.schedulingData.studentName = nameForm.name;
      //       this.educationalWxData.bottom.signForm.username = nameForm.name;
      //       this.$toast(res.msg);
      //       // this.getInfo(this.userInfo);
      //       sessionStorage.setItem('userInfo',JSON.stringify(res.data));
      //     }else {
      //       this.$toast(res.msg)
      //     }
      //   });
      // }else {
      //   this.$toast('请输入真实姓名')
      // }
    },
    errClose() {
      this.errShow = false
      this.errMessage.errDetailMessage = ''
      this.errMessage.errTimeMessage = ''
    },
    successClose() {
      this.successMessage.successShow = false
      this.isLocation = false
      this.successMessage.successTitle = ''
    },
    schedulingInfo() { // 公开课签到信息
      let params = {
        // schedulingId: 9,
        schedulingId: this.schedulingId,
        studentPhone: this.userInfo.mobileNumber
      }
      this.api.schedulingInfo(params).then(res=> {
        if(res.code === 0) {
          let data = res.data;
          this.schedulingData = data;
          this.educationalWxData.top.topMessage[0].topRight = data.className;
          this.educationalWxData.top.topMessage[1].topRight = data.courseName;
          this.educationalWxData.top.topMessage[2].topRight = data.courseOutlineName;
          this.educationalWxData.top.topMessage[3].topRight = data.departments;

          this.classData = data.appSchedulingDateList;

          this.dateChange();

          // 判断是否本课程学员
          if(/^[0-9]+$/.test(data.studentId)) { // 是课程学员
            this.getInfo(data);
          }else { // 不是课程学员
            this.$dialog({
              message: '您不是本课程学员！'
            });
          }
        }else {
          // this.$toast(res.msg);
          this.$dialog({
            message: res.msg
          });
        }
      });
    },
    checkStudentSignIn() { // 是否已签到
      let params = {
          dateType: this.educationalWxData.bottom.signForm.timeValue,
          objectId: this.schedulingId,
          signInType: 2,
          studentId: this.schedulingData.studentId,
          // userPhone: this.schedulingData.studentPhone
        }
      this.api.checkStudentSignIn(params).then(res=> {
        if(res.code === 0) {
          // let {
          //   signIn,
          //   signOut
          // } = res.data;
          // this.educationalWxData.button.signInBtn = signIn?'已签到':'签到';
          // this.educationalWxData.button.signUpBtn = signOut?'已签退':'签退';
          // this.disabledIn = signIn;
          // this.disabledUp = signOut;

          let {
            disabledIn,inBtn,
            up,upBtn
          } = this.api.into(this.educationalWxData.bottom.signForm.timeValue,res.data.signIn,res.data.signOut);
          // console.log(disabledIn,inBtn,up,upBtn);
          this.educationalWxData.button.signInBtn = inBtn;
          this.educationalWxData.button.signUpBtn = upBtn;
          this.disabledIn = disabledIn;
          this.disabledUp = up;
        }
      });
    },
    siginInRanking() { // 排班排名
      /*if((this.ranParams.page - 1) * this.ranParams.limit > this.ranTotal && this.ranBusy){
        this.ranBusy = false;
        // this.showLoading = false;
        return;
      }*/
      // this.ranBusy = true;
      // setTimeout(()=> {
      //   this.ranBusy = false;
      // },1000*10);
      let params = this.ranParams;
      params.schedulingId = this.schedulingId;
      params.dateType = this.educationalWxData.bottom.signForm.timeValue;
      this.api.siginInRanking(params).then(res=> {
        if(res.code === 0) {
          let list = res.rows;
          list.forEach(item=> {
            if(item.studentId == this.schedulingData.studentId) {
              // console.log(item)
              this.myRan = item;
            }
          });
          this.ranList = this.ranList.concat(list);
          this.ranTotal = res.total;
          this.ranParams.page += 1;
          
          setTimeout(()=> {
            this.isLoading = false;
          },500);
          // this.ranBusy = false;
        }else {
          setTimeout(()=> {
            this.isLoading = false;
          },500);
          this.$toast(res.msg);
        }
      });
    },
    signReset() {
      this.ranParams.page = 1;
      this.ranList = [];
      this.myRan = {};
      this.ranTotal = 0;
      this.siginInRanking();
    },
    more(e) { // 排名 加载更多
      let domn = e.target;
      if(domn.scrollHeight <= Math.ceil(domn.scrollTop+domn.clientHeight)) {
        if(1 <= this.ranTotal && this.ranList.length < this.ranTotal) {
          if(!this.isLoading) {
            console.log('加载更多');
            this.isLoading = true;
            this.siginInRanking();
          }
        }else {
          // console.log('到底了')
        }
      }
    },
    dateChange() { // 切换时段
      this.disabledIn = true;
      this.disabledUp = true;
      // 判断是否本课程学员
      if(/^[0-9]+$/.test(this.schedulingData.studentId)) {
        this.checkStudentSignIn();
      }
      this.signReset();
    },
    getInfo(data) {
      this.educationalWxData.bottom.signForm.username = data.studentName;
      this.educationalWxData.bottom.signForm.phoneNum = data.studentPhone;
      this.educationalWxData.bottom.signForm.idNum = data.studentIdCard;
      // 没有身份证号的需要填写
      if(!this.api.isCardNo(data.studentIdCard)) {
        this.educationalWxData.bottom.signForm.idDis = false;
      }
      // 判断是新用户时，设置姓名，或者是否有返回姓名
      if(this.userInfo.isNewUser == 1 || !data.studentName || data.studentName == '' || data.studentName == 'KZT') {
        this.nameMessage.nameShow = true
      }
    },
  },
};
</script>

<style scoped>
.educational_wx_sign {
  overflow: scroll;
  text-align: left;
  height: 100%;
  /*background: url('../../../assets/image/bg_sign_in@2x.png') no-repeat top center #f0f6ff;*/
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
}
.educational_wx_sign::-webkit-scrollbar {
  display:none
}
</style>