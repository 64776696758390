<template>
<div class="share">
	<popup-blue ref="popupBlue" :height="387" :show="true" title="分享合同">
		<!-- <p class="share-title share-text">{{ detail.contractName }}</p>
		<div id="qrcode" ref="qrcode" class="share-qrcode"></div>
		<p class="share-hint share-text mt-10 text-center">请扫描二维码签署合同</p>
		<p class="share-name share-text mt-10 text-center">待签署人：{{ detail.studentName }}</p>
		<div class="share-bottom align-center between mt-30">
			<a class="confirm-btn confirm-save" href="javascript:;" @click="downLoad()">保存图片</a>
			<a class="confirm-btn confirm-copy" href="javascript:;" @click="copyLink()">复制链接</a>
		</div>
		<div style="height: 0;opacity: 0;">
			<input id="copy-content" type="text" readonly v-model="detail.link">
		</div>
		<div class="bottom-icon flex between">
			<img class="bottom-icon-left" src="../../assets/image/left.png">
			<img class="bottom-icon-right" src="../../assets/image/right.png">
		</div> -->
		<div style="height: 0;opacity: 0;">
			<input id="copy-content" type="text" readonly v-model="detail.link">
		</div>
		<div class="share-bottom align-center between">
			<a class="confirm-btn confirm-save" href="javascript:;" @click="downLoad()">保存图片</a>
			<a class="confirm-btn confirm-copy" href="javascript:;" @click="copyLink()">复制链接</a>
		</div>
		<vue-canvas-poster class="bg-null" :painting="painting" @success="canvasSuccess" @fail="canvasFail"></vue-canvas-poster>
	</popup-blue>
</div>
</template>

<script>
import popupBlue from '@/components/popup-blue';
export default {
	components: {
		popupBlue
	},
	data() {
		return {
			orderId: this.$route.query.orderId,
			detail: {},

			posterImg: '',//生成的海报图片路径
			painting: {
				width: '640px',
				height: '972px',
				// background: 'https://ddcz.oss-cn-beijing.aliyuncs.com/images/file-1587004539522R5oa.png',
				background: require('../../assets/image/bg_blue@2x.png'),
				borderRadius: '40px',
				views: [
					{
						type: 'rect', // 海报白色背景
						css: {
							top: '156px',
							left: '40px',
							width: '560px',
							height: '773px',
							borderRadius: '28px',
							color: '#fff'
						},
					},{
						type: 'text', // 标题
						text: '分享合同',
						css: {
							top: '68px',
							left: '0',
							width: '100%',
							maxLines: 1,
							fontWeight: 'bold',
							fontSize: '44px',
							color: '#fff',
							textAlign: 'center'
						},
					},
					{
						type: 'image', // 底部左边的叶子
						url: require('../../assets/image/left.png'),
						css: {
							bottom: '0',
							left: '0',
							width: '116px',
							height: '116px',
						},
					},
					{
						type: 'image', // 底部右边的叶子
						url: require('../../assets/image/right.png'),
						css: {
							bottom: '0',
							right: '0',
							width: '124px',
							height: '160px',
						},
					},
					{
						type: 'text', // 合同名称
						text: '',
						css: {
							top: '230px',
							left: '94px',
							width: '451px',
							maxLines: 2,
							lineHeight: '44px',
							fontWeight: 'bold',
							fontSize: '32px',
							color: '#242424'
						},
					},
					{
						type: 'qrcode', // 二维码
						content: '',
						css: {
							top: '340px',
							left: '174px',
							color: '#000',
							background: '#fff',
							width: '271px',
							height: '271px',
							// borderWidth: '10px',
							// borderColor: '#fff'
						},
					},
					{
						type: 'text', // 提示
						text: '请扫描二维码签署合同',
						css: {
							top: '632px',
							left: '0',
							width: '100%',
							maxLines: 1,
							textAlign: 'center',
							fontWeight: 'bold',
							fontSize: '32px',
							color: '#242424'
						},
					},
					{
						type: 'text', // 签署人
						text: '待签署人：',
						css: {
							top: '696px',
							left: '0',
							width: '100%',
							maxLines: 1,
							textAlign: 'center',
							fontWeight: 'bold',
							fontSize: '32px',
							color: '#242424'
						},
					},
				],
			},
		}
	},
	mounted() {
		if(/^[0-9]+$/.test(this.orderId)) {
			this.orderContract();
		}else {
			this.$toast('缺少订单id');
		}
	},
	methods: {
		orderContract() {
			this.api.orderContract(this.orderId).then(res=> {
				if(res.code === 0) {
					this.detail = res.data;
					let data = res.data;
					let url = data.url+'&share=0'
					this.$set(this.detail,'link',url);
					// this.api.getCode('qrcode',136,136,url);
					setTimeout(()=> {
						this.painting.views[4].text = data.contractName;
						this.painting.views[5].content = url;
						this.painting.views[7].text += data.studentName;
					},1000);
				}else {
					this.$toast(res.msg);
				}
			})
		},
		canvasSuccess(src) {
			this.posterImg = src;
			// console.log(src)
		},
		canvasFail(err) {
			console.log(err)
		},
		downLoad() { // 保存图片
			// this.$refs.popupBlue.downLoad();
			// this.api.downLoad(this.$refs.qrcode);
			let a = document.createElement('a');
			a.setAttribute('href',this.posterImg);
			a.setAttribute('download','');
			let event = new MouseEvent('click');
			a.dispatchEvent(event);
		},
		copyLink() { // 复制链接
			this.api.copyLink('copy-content');
			this.$toast('复制成功');
		},
	}
};
</script>

<style scoped>
.share >>> .popup-box {
	/*background: none !important;*/
}
.bg-null {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	/*background: red;*/
}
/*.bg-null >>> div {
	width: 100%;
	height: 100%;
}*/
.bg-null >>> .canvas {
	width: 100% !important;
	height: 100% !important;
	position: static;
}

.share-text {
	font-weight: bold;
	font-size: .8rem;
	color: #242424;
}
.share-title {
	padding: 1.85rem 1.35rem 0;
}
.share-qrcode {
	width: 6.8rem;
	height: 6.8rem;
	margin: .5rem auto 0;
}
.share-bottom {
	/*padding: 0 0.7rem 0 .55rem;*/
	padding: 0 1.55rem;
	position: absolute;
	top: 400px;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	z-index: 999;
}
.confirm-btn {
	width: 5.8rem;
	height: 1.8rem;
	line-height: 1.8rem;
	border-radius: 1rem;
	background: linear-gradient(180deg, #A2CFFF 0%, #5EA9F9 52%, #1E85F4 100%);
	font-weight: bold;
	font-size: .75rem;
}

.bottom-icon {
	height: 0;
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	align-items: flex-end;
}
.bottom-icon-left {
	width: 2.9rem;
	height: 2.9rem;
}
.bottom-icon-right {
	width: 3.1rem;
	height: 4rem;
}
</style>