<template>
<div class="upload image">
	<input id="upload-file" class="image" :type="iptType" accept="image/*" @change="upload">
	<a 
		v-if="!src" 
		class="idcard-file border-box image" 
		href="javascript:;">
		<img class="block" src="../assets/image/icon_camera@2x.png">
		<p class="text-center">{{ label }}</p>
	</a>
	<img 
		v-else 
		class="idcard-img image" 
		:src="src">
</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: ''
		}, // 
		src: {
			type: String,
			default: ''
		}, // 图片路径
	},
	data() {
		return {
			iptType: 'file',
			// voucher: null,
		}
	},
	methods: {
		upload(e) { // 上传缴费凭证
			let files = e.target.files[0];
			this.iptType = 'text';
			setTimeout(()=> {
				this.iptType = 'file';
			},500);
			if(files) {
				if(!/image\/\D/.test(files.type)) {
					this.$toast('请上传图片')
					return
				}
				// let img = window.URL.createObjectURL(files);
				// console.log(img)
				let formData = new FormData();
				formData.append('file',files);

				this.api.upload(formData).then(res=> {
					if(res.code === 0) {
						// this.voucher = res.url;
						this.$emit('upload',res.url);
					}else {
						this.$toast(res.msg);
					}
				})
			}
		},
	}
};
</script>

<style scoped>
.upload {
	position: relative;
}
.upload input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: 99;
}
.idcard-file,.idcard-img {
	border-radius: .4rem;
}
.idcard-file {
	padding-top: .9rem;
	background-color: #F5FAFF;
	border: 1px solid #D9D9D9;
}
.idcard-file img {
	width: 2.4rem;
	height: 2.4rem;
	margin: 0 auto;
}
.idcard-file p {
	line-height: .85rem;
	margin-top: .25rem;
	font-size: .6rem;
	color: #949494;
}
</style>