<template>
<div class="contract">
	<titles isBack titleName="签署合同" />

	<div ref="contract" class="contract-content">
		<pdf 
			id="pdf" 
			ref="pdf" 
			class="pdf image" 
			:rotate="0" 
			v-for="i in numPages" 
			:key="i" 
			:page="i" 
			:src="contractUrl" 
			@progress="pdfProgress" 
			@page-loaded="pageLoaded" 
			@error="pdfError" />
	</div>

	<fixedBtn :btnText="btnText" @confirm="onConfirm" />
</div>
</template>

<script>
import titles from '@/components/titles';
import fixedBtn from '@/components/fixed-button';
import pdf from 'vue-pdf';
export default {
	components: {
		titles,
		fixedBtn,
		pdf,
	},
	data() {
		return {
			isSign: false, // 是否已签名: true 是,false 否
			contractUrl: undefined, // 合同
			orderId: this.$route.query.orderId, // 订单id
			type: this.$route.query.type, // 1 是表示订单详情进入的
			btnText: '', // 按钮文字
			loadedRatio: 0, // 加载进度
			numPages: null, // pdf总页数
		}
	},
	destroyed() {
		// console.log('进入/退出 页面')
	},
	mounted() {
		
	},
	created() {
		this.$nextTick(()=> {
			if(/^[0-9]+$/.test(this.orderId)) {
				// 获取合同文件
				this.api.getOrderContract(this.orderId).then(res=> {
					if(res.code === 0) {
						// console.log(res)
						if(typeof res.data.isSign != 'undefined' && /^[0]+$/.test(res.data.isSign)) { // 已签名
							this.getNumPages(res.data.url);
							// this.isSign = true;
							this.isSign = false;
							// if(/^[1]+$/.test(this.type)) {
								this.btnText = '重新签名';
							// }else { // 分享合同
							// 	this.btnText = '提交';
							// }
						}else { // 未签名
							this.getNumPages(res.data);
							this.isSign = false;
							this.btnText = '我已查看，进行签字';
						}
					}else {
						this.btnText = '缺少合同文件';
						this.$dialog({
							message: res.msg
						})
					}
				});
			}
		});
	},
	methods: {
		getNumPages(url) { // 获取pdf页码
			let loadingTask = pdf.createLoadingTask(url)
			loadingTask.promise.then(pdf => {
				// console.log(pdf)
				this.numPages = pdf.numPages;
				this.contractUrl = loadingTask;
			}).catch(err => {
				console.error('pdf 加载失败', err);
			})
		},
		pageLoaded(page) { // pdf 加载成功
			console.log('加载成功，共' + page + '页');
		},
		pdfError(error) { // 加载错误的回调
			console.error(error)
		},
		pdfProgress(e) { // 加载进度，从0开始，等于1的时候代表第1页加载完成
			console.log(e)
		},
		onConfirm() {
			if(this.contractUrl) {
				if(this.isSign) { // 已签名，返回上一页
					if(/^[1]+$/.test(this.type)) {
						this.$router.back();
					}else { // 分享合同进来的，需要提交签名合同
						// this.uploadImg();
					}
				}else { // 去签名
					this.$router.push({
						path: '/productSign',
						query: {
							orderId: this.orderId
						}
					});
				}
			}else { // 没有合同文件不能签名
				if(/^[1]+$/.test(this.type)) {
					this.$router.back();
					console.log('请回吧')
				}
			}
		},
	}
};
</script>

<style scoped>
.contract {
	/*background-color: #F1F5F8;*/
}
.contract-content {
	/*height: 26.8rem;*/
	position: relative;
	/*width: 793px;
	height: 1122px;
	position: relative;
	transform: scale(-0.48);*/
}
.contract-content .pdf {
	margin-bottom: .25rem;
}
.pdf >>> canvas {
	width: 100% !important;
	height: 100% !important;
}
</style>