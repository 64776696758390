<template>
<div>
	<van-popup 
		class="share" 
		:close-on-click-overlay="false" 
		:style="{
			backgroundImage: 'url('+ shareBg +')'
		}" 
		v-model="isShow" 
		@click-close-icon="shareClose">
		<p class="share-title text-center bold">分享设置</p>
		<dl class="share-list">
			<!-- 学历 才显示这个 -->
			<dd v-if="/^[1]{1}$/.test(productType)">
				<p class="share-item-title">专业/班级</p>
				<div class="share-item-select align-center">
					<div>
						<select class="border-box" v-model="specialtyId" @change="specialtyChange()">
							<option value="">请选择专业</option>
							<option v-for="(item,index) in specialtyList" :value="item.businessTypeId" :key="index">{{ item.name }}</option>
						</select>
					</div>
					<!-- <div v-if="/^[0-9]+$/.test(specialtyId)">
						<select class="border-box" v-model="classId">
							<option value="">请选择班级</option>
							<option 
								v-for="(item,index) of classList" 
								:key="index" 
								:value="item.classId">
								{{ item.className }}
							</option>
						</select>
					</div> -->
				</div>
			</dd>
			<dd>
				<p class="share-item-title">被分享人是否为合作方学员</p>
				<div class="share-item-check align-center">
					<label class="align-center">
						<input type="radio" value="0" v-model="cooperation" @change="cooperationChange()">
						&nbsp;
						否
					</label>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<label class="align-center">
						<input type="radio" value="1" v-model="cooperation" @change="cooperationChange()">
						&nbsp;
						是
					</label>
				</div>
				<div v-if="cooperation == 1" class="share-item-select align-center">
					<div>
						<select class="border-box" v-model="cooperationType" @change="cooperationClick()">
							<option value="1">合作企业</option>
							<option value="2">合作渠道</option>
							<option value="3">合作项目</option>
						</select>
					</div>
					<div>
						<select class="border-box" v-model="cooperationId">
							<option value="">请选择合作方</option>
							<option 
								v-for="(item,index) of cooperationList" 
								:key="index" 
								:value="cooperationType == 3?item.projectId:item.enterpriseChannelCustomerId">
								<span>
									{{ cooperationType == 3?item.projectName:item.enterpriseFullName }}
								</span>
							</option>
						</select>
					</div>
				</div>
			</dd>
			<dd>
				<p class="share-item-title">是否可开发票</p>
				<div class="share-item-check align-center">
					<label class="align-center">
						<input type="radio" value="1" v-model="invoice">
						&nbsp;
						否
					</label>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<label class="align-center">
						<input type="radio" value="0" v-model="invoice">
						&nbsp;
						是
					</label>
				</div>
			</dd>
			<dd>
				<p class="share-item-title">是否有提报人</p>
				<div class="share-item-check align-center">
					<label class="align-center">
						<input type="radio" value="1" v-model="report" @change="reportChange()">
						&nbsp;
						否
					</label>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<label class="align-center">
						<input type="radio" value="0" v-model="report" @change="reportChange()">
						&nbsp;
						是
					</label>
				</div>
				<div v-if="report == 0" class="share-item-select align-center">
					<div class="align-center">
						<span v-if="cooperationType == 2 && cooperation == 1">合伙人</span>
						<span v-else>提报人</span>
					</div>
					<div>
						<select class="border-box" v-model="partnerId">
							<option value="">请选择</option>
							<option :value="item.partnerId" v-for="(item,index) of reportList" :key="index">
								{{ item.partnerName }}
							</option>
						</select>
					</div>
				</div>
				<div v-if="report == 0" class="share-item-select align-center">
					<div class="align-center">
						<span v-if="cooperationType == 2 && cooperation == 1">合伙人政策</span>
						<span v-else>提报人政策</span>
					</div>
					<div>
						<select class="border-box" v-model="salesPolicyId">
							<option value="">请选择</option>
							<option 
								v-for="(item,index) of reportZCList" 
								:key="index" 
								:value="cooperationType == 2 && cooperation == 1?item.partnershipPolicyId:item.reportingPolicyId">
								{{ cooperationType == 2 && cooperation == 1?item.partnershipPolicyName:item.reportingPolicyName }}
							</option>
						</select>
					</div>
				</div>
			</dd>
			<dt v-if="!isRedact" class="share-total align-center">
				<span class="share-total-label color-red">学费：￥{{ tuitionFee }}</span>
				<img class="share-total-icon" src="../../assets/image/ic_compile.png" @click="redactClick()">
			</dt>
			<dt v-else class="share-total align-center">
				<div class="color-red align-center">
					<span class="share-total-label">学费：</span>
					<input class="tuitionFee-ipt" type="number" v-model="tuitionFeeIpt" @keydown="removeNum($event)">
				</div>
				<a class="share-total-btn align-center share-total-save" href="javascript:;" @click="redactConfirm(true)">保存</a>
				<a class="share-total-btn align-center share-total-cancel" href="javascript:;"  @click="redactConfirm()">取消</a>
			</dt>
			<dd class="share-code share-item-select align-center">
				<span>分享二维码有效期</span>
				<input class="border-box" type="number" v-model="validDay" @keydown="removeNum($event)">
				<span>天</span>
			</dd>
		</dl>
		<div class="share-send">
			<a class="confirm-btn block" href="javascript:;" @click="getSharePoster()">下一步</a>
		</div>
	</van-popup>
</div>
</template>

<script>
export default {
	data() {
		return {
			productId: this.$route.query.objectId, // 产品id
			ownerId: this.$route.query.ownerId, // 线索所有者编号
			productType: this.$route.query.productType, // 1-学历，2-技能
			isShow: true,
			shareBg: require('../../assets/image/bg_share_2.png'),
			specialtyList: [], // 专业 数据
			specialtyId: '', // 选中的 专业id
			classList: [], // 班级 数据
			classId: '', // 选中的 班级id
			cooperation: 0, // 被分享人是否为合作方学员
			cooperationType: 1, // 合作类型
			cooperationList: [], // 合作方
			cooperationId: '', // 合作方 id
			invoice: 1, // 是否可开发票
			report: 1, // 是否为提报人:0 是,1 否
			reportList: [], // 合伙人/提报人 数据
			partnerId: '', // 合伙人/提报人 id
			reportZCList: [], // 合伙人/提报人 政策数据
			salesPolicyId: '', // 合伙人政策/提报人政策 id
			tuitionFee: this.$route.query.tuitionFee || 0, // 学费
			tuitionFeeIpt: '', // 学费
			isRedact: false, // 显示学费输入框
			validDay: 3, // 有效天数，默认 3 天
		}
	},
	mounted() {
		if(/^[1]{1}$/.test(this.productType)) { // 学历
			this.specialtyListSystem();
		}
	},
	methods: {
		removeNum(e) { // 去掉数字输入框 e + - 按钮的操作
			if(e.keyCode == 38 || e.keyCode == 40 || e.keyCode == 69 || e.keyCode == 107 || e.keyCode == 109) {
				e.returnValue = false;
			}else {
				e.returnValue = true;
			}
		},
		specialtyListSystem() { // 获取 专业 数据
			this.api.specialtyListSystem(this.productId).then(res=> {
				if(res.code === 0) {
					this.specialtyList = res.data;
				}
			});
		},
		specialtyChange() { // 选择 专业
			this.classList = [];
			// this.classId = '';
			if(/^[0-9]+$/.test(this.specialtyId)) {
				let name;
				this.specialtyList.forEach(item=> {
					if(item.businessTypeId == this.specialtyId) {
						name = item.name;
					}
				});
				this.classListSystem(name);
			}
		},
		classListSystem(name) { // 获取 班级 数据
			let params = {
				productId: this.productId,
				specialtyId: name
			}
			this.api.classListSystem(params).then(res=> {
				if(res.code === 0) {
					this.classList = res.data;
				}
			});
		},
		shareClose() {
			this.$router.back();
		},
		cooperationClick() { // 切换 合作类型
			this.cooperationList = [];
			this.cooperationId = '';
			this.cooperationChange();
		},
		cooperationChange() { // 获取合作方数据
			if(this.cooperation == 1) {
				this.api.customerList(this.cooperationType).then(res=> {
					if(res.code === 0) {
						this.cooperationList = res.data;
					}else {
						this.$toast(res.msg);
					}
				})
			}
			if(this.report == 0) {
				this.getPartner();
			}
		},
		reportChange() { // 切换 是否有提报人
			if(this.report == 0) {
				this.getPartner();
			}
		},
		getPartner() { // 合伙人/提报人 数据
			this.partnerId = '';
			let type;
			if(this.cooperationType == 2 && this.cooperation == 1) { // 合伙人
				console.log('合伙人');
				type = 1;
			}else { // 提报人
				console.log('提报人');
				type = 2;
			}
			// 合伙人/提报人
			this.api.partnerList(type).then(res=> {
				if(res.code === 0) {
					this.reportList = res.data;
				}else {
					this.$toast(res.msg);
				}
			});
			this.salesPolicyId = '';
			// 合伙人/提报人 政策
			this.api.policyList(type,this.productId,type == 2 && /^[1]{1}$/.test(this.productType)? this.specialtyId:'').then(res=> {
				if(res.code === 0) {
					this.reportZCList = res.data;
				}else {
					this.$toast(res.msg);
				}
			});
		},
		redactClick() { // 编辑学费
			// this.tuitionFeeIpt = value;
			this.isRedact = true;
		},
		redactConfirm(right) { // 学费 保存/取消
			if(right && this.tuitionFeeIpt > 0) {
				this.tuitionFee = this.tuitionFeeIpt;
				this.isRedact = false;
			}else {
				this.isRedact = false;
			}
		},
		getSharePoster() { // 下一步
			// console.log(this.cooperation,this.cooperationId)
			if(/^[1]{1}$/.test(this.productType)) { // 学历
				if(!/^[0-9]+$/.test(this.specialtyId)) {
					this.$toast('请选择专业');
					return
				}
				// if(!/^[0-9]+$/.test(this.classId)) {
				// 	this.$toast('请选择班级');
				// 	return
				// }
			}
			if(this.cooperation == 1 && !/^[0-9]+$/.test(this.cooperationId)) {
				this.$toast('请选择合作方')
				return
			}
			if(this.report == 0) {
				let title = '';
				if(this.cooperationType == 2 && this.cooperation == 1) {
					title = '合伙人';
				}else {
					title = '提报人';
				}
				if(!/^[0-9]+$/.test(this.partnerId)) {
					this.$toast('请选择'+title);
					return
				}
				if(!/^[0-9]+$/.test(this.salesPolicyId)) {
					this.$toast('请选择'+title+'政策');
					return
				}
			}
			if(this.validDay < 1) {
				this.$toast('请输入有效天数');
				return
			}
			if(this.tuitionFee <= 0) {
				this.$toast('请输入学费')
				return
			}
			let params = {
				isPartner: this.report,
				partnerId: this.report == 0?this.partnerId:undefined,
				policyId: this.report == 0?this.salesPolicyId:undefined,
				tuitionFee: this.tuitionFee,
				cooperationType: this.cooperation == 1?this.cooperationType:undefined,
				cooperationId: this.cooperation == 1?this.cooperationId:undefined,
				productId: this.productId,
				isInvoice: this.invoice,
				ownerId: this.ownerId,
				specialtyId: this.specialtyId || undefined,
				// classId: this.classId || undefined,
				validDay: this.validDay
			}
			// console.log(params)
			this.api.getSharePoster(params).then(res=> {
				if(res.code === 0) {
					sessionStorage.setItem('productDetail',JSON.stringify(res.data));
					this.$router.push('/productPoster');
				}else {
					this.$toast(res.msg);
				}
			})
		},
	}
};
</script>

<style scoped>
.share {
	/*min-width: 80%;*/
	width: 16.75rem;
	border-radius: .5rem;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center center;
	padding-bottom: 1.75rem;
	font-size: .7rem;
	color: #242424;
}
.share-title {
	margin-top: 1.1rem;
	font-size: 1rem;
}

.share-list {
	padding: 0 1.25rem 0 1.8rem;
	margin-top: .7rem;
}
.share-list > dd + dd {
	margin-top: .9rem;
}
.share-item-title {
	color: #999;
}
.share-item-check {
	margin-top: .5rem;
}
.share-item-check input {
	width: .9rem;
	height: .9rem;
}

.share-total {
	margin-top: 1.5rem;
}
.share-total-label {
	margin-right: .4rem;
	font-size: .6rem;
}
.share-total-icon {
	width: .8rem;
	height: .8rem;
}

.share-send {
	padding: 0 1.4rem 0 1.8rem;
	margin-top: 1rem;
}
.share-send .confirm-btn {
	width: 100%;
	height: 2rem;
	line-height: 2rem;
	border-radius: 1.1rem;
}

.share-item-select {
	height: 1.6rem;
	margin-top: .5rem;
}
.share-item-select > div {
	width: 50%;
	height: 100%;
	box-sizing: border-box;
	flex-shrink: 0;
}
.share-item-select > div:nth-child(1) {
	padding-right: .4rem;
}
.share-item-select > div:nth-child(2) {
	padding-left: .4rem;
}
.share-item-select select,.share-item-select input {
	width: 100%;
	height: 100%;
	padding: 0 .45rem 0 .55rem;
	border: 1px solid #D9D9D9;
	border-radius: .2rem;
	background-color: #fff;
}

.tuitionFee-ipt {
	width: 2.5rem;
	height: 1.6rem;
	border: 1px solid #D9D9D9;
	border-radius: .2rem;
	padding: 0 .45rem 0 .55rem;
	color: #242424;
}
.share-total-btn {
	display: block;
	border-radius: .15rem;
	padding: .25rem .5rem;
	box-sizing: border-box;
	margin-left: .5rem;
	text-align: center;
	font-size: .6rem;
}
.share-total-save {
	background: linear-gradient(180deg, #55A5FB 0%, #1E85F4 100%);
	color: #fff;
}
.share-total-cancel {
	border: 1px solid #1E85F4;
	background: linear-gradient(180deg, #FFFFFF 0%, #E1EFFF 100%);
	color: #1E85F4;
}

.share-code {
	margin-top: 1.5rem;
}
.share-code input {
	width: 3rem;
	height: 1.6rem;
	margin: 0 .4rem;
	text-align: center;
}
</style>