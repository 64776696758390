<template>
  <div class="sign_success">
    <van-dialog v-model="successMessage.successShow" :showConfirmButton="false" ref="dialog">
      <div class="sign_logo">
        <img :src="imgURL" alt="" class="logo_img">
      </div>
      <div class="sign_message">
        {{successMessage.successTitle}}
      </div>
      <div style="margin: 20px">
        <van-button round block type="info" @click="close">我知道了</van-button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  props: {
    successMessage: {
      type: Object,
      default() {
        return {
          successShow: false,
          successTitle: '',
        }
      }
    }
  },
  data () {
    return {
      imgURL: require('../../assets/image/img_call_the_roll@2x.png'),
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
  
}
</script>

<style scoped>
  .sign_success {
    text-align: center;
  }
  div /deep/.van-dialog {
    overflow: visible;
  }
  .sign_logo {
    width: 100%;
    height: 80px;
  }
  .sign_logo .logo_img{
    position: absolute;
    width: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
