<template>
<div>
	<titles isBack />
	<login :isPhone="!/^[1]$/.test(type)" loginShow @login="onConfirm" />
</div>
</template>

<script>
import titles from '@/components/titles';
import login from '@/components/dislog/Login';
export default {
	components: {
		titles,
		login
	},
	data() {
		return {
			type: this.$route.query.type, // 是否验证手机号: 1 否
			orderId: this.$route.query.orderId,
			ownerId: this.$route.query.ownerId,
			share: this.$route.query.share, // 0 表示合同分享
		}
	},
	methods: {
		onConfirm(msg) { // 登录
			console.log(msg)
			if(/^[0]{1}$/.test(this.share)) { // 签合同
				// let params = {
				// 	orderId: this.orderId,
				// 	ownerId: this.ownerId,
				// 	name: msg.studentName,
				// 	mobileNumber: msg.mobileNumber,
				// }
				// this.api.clueAdd(params).then(res=> {
				// 	if(res.code === 0 || res.code === 500) {
						this.$router.push({path: '/productContract',query: {orderId: this.orderId}});
				// 	}else {
				// 		this.$toast(res.msg);
				// 	}
				// });
			}else { // 订单详情
				this.$router.push({path: '/productConfirmSign',query: {orderId: this.orderId,clueId: this.ownerId}});
			}
		},
	}
};
</script>

<style scoped>

</style>