<template>
<div>
	<titles titleName="产品详情" />
	<div class="top-bg">
		<img class="image" :src="detail.productImage">
	</div>
	<div class="product-info">
		<p class="product-info-name clamp-2">{{ detail.productName }}</p>
		<div class="product-info-price">
			<div class="align-center between">
				<div>
					<label>线上价格：</label>
					<span class="product-info-price-count">￥{{ detail.onLinePrice }}</span>
				</div>
				<span>课时：{{ detail.onLineHour }}</span>
			</div>
			<div class="align-center between">
				<div>
					<label>线下价格：</label>
					<span class="product-info-price-count">￥{{ detail.offlinePrice }}</span>
				</div>
				<span>课时：{{ detail.offlineHour }}</span>
			</div>
		</div>
	</div>
	<div class="product-detail mt-10">
		<div class="product-detail-title bold">详情</div>
		<div class="product-detail-content" v-html="content"></div>
	</div>

	<fixedBtn btnText="报名" :disabled="isLoading" @confirm="onConfirm" />

	<!-- 二维码过期 -->
	<van-popup v-model="codeShow" :close-on-click-overlay="false" class="code-pop">
		<img class="code-pop-warn" src="../../assets/image/icon_warn.png" alt="">
		<p class="code-pop-title text-center">二维码已过期</p>
		<a class="code-pop-btn confirm-btn" href="javascript:;" @click="codeClose()">关闭</a>
		<p class="code-pop-phone text-center">客服电话：0735-2446546</p>
	</van-popup>
	<!-- 登录 -->
	<login :loginShow="loginShow" @login="onLogin" />
	<!-- 填写姓名 -->
    <name :nameMessage="nameMessage" @nameConfirm="nameConfirm" />
</div>
</template>

<script>
import titles from '@/components/titles';
import login from '@/components/dislog/Login';
import Name from '@/components/dislog/Name';
import fixedBtn from '@/components/fixed-button';
export default {
	components: {
		titles,
		login,
		Name,
		fixedBtn
	},
	data() {
		return {
			// 测试的时候url后面加上这段参数: ?isPartner=0&partnerId=12&policyId=11&tuitionFee=5000&cooperationType=1&cooperationId=14&productId=5&isInvoice=0&username=系统管理员
			productId: this.$route.query.productId, // 产品id
			content: '',
			detail: {}, // 产品详情
			userInfo: {}, // 学员信息
			loginShow: false, // 是否显示登录弹框: true 是
			nameMessage: {
				nameShow: false,
				nameTitle:'请填写真实姓名',
				placeholder: '请输入真实姓名'
			},
			clueId: undefined,
			codeShow: false, // 显示二维码过期提示
			isLoading: true,
			hint: '', // 添加线索人失败的提示
		}
	},
	mounted() {
		// console.log(this.$route.query)
		if(/^[0-9]+$/.test(this.productId)) {
			this.productInfo();
		}else {
			this.$toast('缺少必要参数');
		}
	},
	methods: {
		suitable() {
			// 获取有效时间
			let endDate = this.$route.query.endDate;
			let now = new Date().getTime(),
				eD = new Date(endDate).getTime();
			// console.log(endDate)
			if(now >= eD) {
				return true;
			}else {
				return false;
			}
		},
		productInfo() {
			this.api.productInfo(this.productId).then(res=> {
				if(res.code === 0) {
					this.detail = res.data;
					this.content = res.data.productDetails.replace(/<img/g,'<img style="display: block;max-width: 100%;" ');
					
					if(this.suitable()) { // 过了有效时间，链接已失效
						this.codeShow = true;
					}else {
						if(sessionStorage.getItem('userInfo')) {
							this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
							if(this.userInfo.isNewUser == 1 || !this.userInfo.studentName || this.userInfo.studentName == 'KZT') { // 新用户或者没有名字
								this.nameMessage.nameShow = true
							}else {
								this.productClue();
							}
						}else { // 显示登录弹框
							this.loginShow = true;
						}
					}
				}else {
					this.$toast(res.msg);
				}
			})
		},
		onLogin(userInfo) { // 登录
			// console.log(userInfo);
			this.userInfo = userInfo;
			this.loginShow = false;
			// this.productInfo();
			if(userInfo.isNewUser == 1 || !userInfo.studentName || userInfo.studentName == 'KZT') {
				this.nameMessage.nameShow = true
			}else {
				this.productClue();
			}
		},
		nameConfirm(userInfo) { // 填写真真实姓名
			// console.log(userInfo);
			this.nameMessage.nameShow = false;
			this.userInfo = userInfo;
			// this.productInfo();
			this.productClue();
			
			// if(nameForm.name){
			// 	let params = {
			// 		studentName: nameForm.name,
			// 		studentId: this.userInfo.studentId
			// 	}
			// 	this.api.changeName_kzt(params).then(res=> {
			// 		if(res.code === 0) {
			// 			this.nameMessage.nameShow = false;
			// 			this.userInfo = res.data;
			// 			// this.openClassWxData.bottom.signForm.username = nameForm.name;
			// 			// this.getInfo(this.userInfo);
			// 			this.$toast(res.msg);
			// 			sessionStorage.setItem('userInfo',JSON.stringify(res.data));
			// 			// this.productClue();
			// 		}else {
			// 			this.$toast(res.msg)
			// 		}
			// 	});
			// }else {
			// 	this.$toast('请输入真实姓名')
			// }
		},
		codeClose() {
			// window.close();
			this.codeShow = false;
		},
		productClue() { // 添加线索人
			let params = {
				name: this.userInfo.studentName || '',
				mobileNumber: this.userInfo.mobileNumber || '',
				companyName: this.userInfo.workUnit || '',
				source: '产品报名',
				studentIdCardNo: this.userInfo.studentIdCardNo || '',
				shareUserId: this.$route.query.ownerId
			}
			this.api.productClue(params).then(res=> {
				this.isLoading = false;
				if(res.code === 0) {
					this.clueId = res.data.clueId;
					// this.isLoading = false;
					this.hint = '';
				}else {
					// this.$toast(res.msg);
					this.hint = res.msg;
				}
			}).catch(err=> {
				console.log(err)
				this.isLoading = false;
			});
		},
		onConfirm() { // 报名
			if(this.suitable()) { // 过了有效时间，链接已失效
				this.codeShow = true;
				return
			}
			if(this.hint != '') {
				this.$toast(this.hint);
				return
			}
			let params = this.$route.query;
			this.$set(params,'studentName',this.userInfo.studentName);
			this.$set(params,'studentPhone',this.userInfo.mobileNumber);
			this.$set(params,'clueId',this.clueId);
			this.api.createOrder(params).then(res=> {
				if(res.code === 0) {
					// this.$router.push({path: '/productLogin',query: {type: 1,orderId: res.data,ownerId: this.$route.query.ownerId}});
					// sessionStorage.setItem('productParams',JSON.stringify(params));
					this.$router.push({
						path: '/productConfirmSign',
						query: {
							orderId: res.data,
							clueId: this.clueId
							// productId: params.productId
						}
					});
				}else {
					this.$toast(res.msg);
				}
			})
		},
	}
};
</script>

<style scoped>
.top-bg {
	height: 10.6rem;
}
.product-info {
	background-color: #fff;
	padding: .8rem 1rem .7rem;
}
.product-info-name {
	line-height: 1.25rem;
	font-weight: bold;
	font-size: .9rem;
	color: #242424;
}
.product-info-price {
	margin-top: .5rem;
	font-size: .7rem;
	color: #949494;
}
.product-info-price > div {
	height: 1rem;
}
.product-info-price > div + div {
	margin-top: .2rem;
}
.product-info-price-count {
	color: #FF5858;
}

.product-detail {
	background-color: #fff;
}
.product-detail-title {
	line-height: 2.2rem;
	padding: 0 .8rem;
	font-size: .8rem;
	color: #242424;
}

.code-pop {
	width: 13.75rem;
	height: 12rem;
	background: #F8FBFF;
	border-radius: .6rem;
}
.code-pop-warn {
	display: block;
	width: 3.2rem;
	height: 3.2rem;
	margin: 1.55rem auto 0;
}
.code-pop-title {
	margin-top: .75rem;
	font-weight: bold;
	font-size: .8rem;
	color: #242424;
}
.code-pop-btn {
	display: block;
	width: 10rem;
	height: 2rem;
	line-height: 2rem;
	margin: 1rem auto 0;
	border-radius: 1.1rem;
	font-size: .7rem;
}
.code-pop-phone {
	margin-top: .5rem;
	font-size: .6rem;
	color: #1E85F4;
}
</style>