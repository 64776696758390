<template>
  <div class="sign_err">
    <van-dialog v-model="errShow" :showConfirmButton="false" ref="dialog">
      <div class="icon">
        <van-icon name="clear" color="rgb(255, 72, 74)" size="80"/>
      </div>

      <div class="err_title" style="padding-bottom: 8px">
        {{errMessage.errTitle}}
      </div>
      <div class="err_message">
        <div class="err_title">
          {{errMessage.errDetailMessage}}
        </div>
        <div class="errDetailMessage" v-if="errMessage.errTimeMessage">
          <div v-for="(item,index) of errMessage.errTimeMessage" :key="index">{{ item }}</div>
        </div>
      </div>

      <div class="button" style="margin: 20px">
        <van-button round block type="info" @click="close">确认</van-button>
      </div>

    </van-dialog>
  </div>
</template>

<script>
export default {
  props: {
    errShow: {
      type: Boolean,
      default() {
        return false
      },
    },
    errMessage: {
      type: Object,
      default() {
        return {
          errTitle: '签到失败',
          errDetailMessage: '',
          errTimeMessage: []
        }
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.sign_err {
  text-align: center;
}
.icon {
  margin: 20px 0;
}
.err_title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: #242424;
  opacity: 1;
}
.errDetailMessage {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: #666666;
  opacity: 1;
}
</style>
