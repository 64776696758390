<template>
<div>
	<titles isBack titleName="提交报读意向" />

	<div class="intention">
		<div class="content">
			<img class="logo" src="../../assets/image/icon_app.png" />
			<p class="c-label">姓名</p>
			<input type="text" placeholder="请输入姓名（必填）" v-model="params.name" />

			<p class="c-label">手机号</p>
			<input type="text" placeholder="请输入手机号（必填）" maxlength="11" v-model="params.mobileNumber"/>

			<p class="c-label">电话</p>
			<input type="text" placeholder="请输入电话" v-model="params.telephone"/>

			<p class="c-label">公司</p>
			<input type="text" placeholder="请输入公司（必填）" v-model="params.companyName"/>

			<p class="c-label">报读意向</p>
			<textarea class="border-box" cols="30" rows="10" placeholder="请输入您想要报读了解的信息...（必填）" v-model="params.intentionContent"></textarea>

			<a class="button block" href="javascript:;" @click="send()">提交</a>
		</div>

		<!-- 成功提示 -->
		<div v-show="isShow" class="model">
			<div class="model-zbz"></div>
			<div class="model-content">
				<img class="model-success block" src="../../assets/image/ic_success.png" />
				<p class="model-tips-title">提交成功</p>
				<p class="model-tips">会有专人联系您，请保持手机畅通</p>
				<a class="model-button block" href="javascript:;" @click="isShow = false">我知道了</a>
			</div>
		</div>
	</div>
</div>
</template>

<script>
	
import titles from '@/components/titles';
export default {
	components: {
		titles,
	},
	data() {
		return {
			isShow: false,
      params: {
        name: '',
        mobileNumber: '',
        telephone: '',
        companyName: '',
        intentionContent: ''
      },
		}
	},
	mounted() {
		
	},
	methods: {
		send() {
      let {
        name,
        mobileNumber,
        companyName,
        intentionContent
      } = this.params;
      if(!name) {
        this.$toast('请输入姓名！');
        return
      }
      if(!/^[0-9]{11}$/.test(mobileNumber)) {
        this.$toast('请输入正确的手机号！');
        return
      }
      if(!companyName) {
        this.$toast('请输入公司！');
        return
      }
      if(!intentionContent) {
        this.$toast('请输入报读意向！');
        return
      }
      this.api.intentionSend(this.params).then(res=> {
        if(res.code === 0) {
          this.isShow = true;
          this.params = {
            name: '',
            mobileNumber: '',
            telephone: '',
            companyName: '',
            intentionContent: ''
          }
        }else {
          this.$toast.fail(res.msg);
        }
      });
		},
	}
};
</script>

<style scoped>

.intention{
  height: 100%;
  background: #1E85F4;
  padding: 3rem 1rem 1rem;
}
.content{
  padding: 3.8rem 1rem 1rem;
  background: #FFFFFF;
  box-shadow: 0 .15rem .5rem rgba(8, 58, 112, 0.24);
  border-radius: .4rem;
  position: relative;
}
.content .logo{
	display: block;
  width: 4.9rem;
  height: 4.9rem;
  position: absolute;
  top: -1.9rem;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 1.1rem;
  z-index: 9;
}

 .content .c-label{
   padding-left: .8rem;
  height: .85rem;
  line-height: .85rem;
  margin-bottom: .4rem;
  font-family: PingFang SC;
  font-weight: bold;
  font-size: .6rem;
  color: #242424;
}
.content input,.content textarea {
	width: 100%;
	background: #E9F4FF;
	box-sizing: border-box;
	border: none;
	font-size: .7rem;
}
.content input{
  padding: 0 1rem;
  height: 2.2rem;
  border-radius: 1.1rem;
  margin-bottom: .8rem;
}

.content textarea{
  height: 6rem;
  border-radius: .2rem;
  padding: .5rem;
}
input::-webkit-input-placeholder,textarea::-webkit-input-placeholder {
	color: #BBC3CC;
}

.content .button{
  height: 2.2rem;
  line-height: 2.2rem;
  background: #1E85F4;
  box-shadow: 0 .2rem .6rem rgba(12, 107, 210, 0.39);
  border-radius: 1.25rem;
  margin-top: 1.15rem;
  text-align: center;
  font-size: .8rem;
  color: #fff;
}

/* 灰幕开始 */
.model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}
.model-zbz {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.4);
  z-index: 3;
  
}
.model-content{
  width: 14.5rem;
  background: #FFFFFF;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  border-radius: .4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
}

 .model .model-success{
  width: 3.2rem;
  height: 3.2rem;
  margin: 0 auto;
}

.model .model-tips-title{
  height: 1.1rem;
  line-height: 1.1rem;
  margin-top: .5rem;
  text-align: center;
  font-weight: bold;
  font-size: .8rem;
  color: #242424;
}

.model-tips{
  height: 1rem;
  line-height: 1rem;
  margin-top: .2rem;
  text-align: center;
  font-weight: 400;
  font-size: .7rem;
  color: #999999;
}

.model-button {
  width: 8rem;
  height: 2.2rem;
  line-height: 2.2rem;
  background: #1E85F4;
  box-shadow: 0 .2rem .6rem rgba(12, 107, 210, 0.39);
  border-radius: 1.25rem;
  margin: 1rem auto 0;
  text-align: center;
  font-size: .8rem;
  color: #fff;
}
</style>