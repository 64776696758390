<template>
  <div class="message">
    <titles :isShow="isShow" isBack titleName="填写基本信息" />

    <div class="main">
      <div v-if="isShow" class="mesage_top">
        <img :src="topImgURL" class="top_bg image" />
          <div class="img_box flex">
            <img :src="successImgURL" class="success_img block" />
          </div>
          <div class="success_message">
            缴费成功，请填补基本信息
          </div>
      </div>

      <!-- <confirm-sign ref="confirmSign" @orderInfo="orderInfo" /> -->

      <van-form :model="messageForm" class="message_form">
        <van-field
          v-model="messageForm.username"
          label="姓名"
          :placeholder="!isShow?'未设置':'请输入'"
          required
        />
        <van-field name="sex" label="性别" required>
          <template #input>
            <van-radio-group v-model="messageForm.sex" direction="horizontal">
              <van-radio name="0" style="margin-right: 30px">男</van-radio>
              <van-radio name="1">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="messageForm.wxID"
          label="微信号"
          :placeholder="!isShow?'未设置':'请输入'"
        />
        <van-field
          v-model="messageForm.phoneNum"
          label="手机号"
          :placeholder="!isShow?'未设置':'请输入'"
          required
          maxlength="11"
        />
        <van-field
          v-model="messageForm.email"
          label="邮箱"
          :placeholder="!isShow?'未设置':'请输入'"
        />
        <van-field
          v-model="messageForm.address"
          label="联系地址"
          :placeholder="!isShow?'未设置':'请输入'"
        />
        <van-field
          v-model="messageForm.studentIdCard"
          label="身份证号"
          :placeholder="!isShow?'未设置':'请输入'"
          required
          maxlength="18"
        />
        <van-field name="uploaderID" :label="!isShow?'身份证照':'上传身份证'" required class="wrap">
          <template #input>
            <div class="uploader_box align-center">
              <div>
                <idcardFile label="人像面" :src="messageForm.frontalImage" @upload="uploadA" />
              </div>
              <div>
                <idcardFile label="国徽面" :src="messageForm.reversedImage" @upload="uploadB" />
              </div>
            </div>
          </template>
        </van-field>
        <van-field name="uploader_photo" label="蓝底照片" class="wrap">
          <template #input>
            <div class="mt-10">
              <!-- <van-uploader
                v-model="messageForm.uploaderPhoto"
                preview-size="100px"
                multiple
                :max-count="1"
                type="primary"
              /> -->
              <uploadFile :src="messageForm.blueImage" :width="72" :height="80" @upload="uploadC" />
            </div>
          </template>
        </van-field>
        <van-field name="uploader_diploma" label="毕业证照" class="wrap">
          <template #input>
            <!-- <van-uploader
              v-model="messageForm.uploaderDiploma"
              preview-size="100px"
              multiple
              :max-count="1"
              type="primary"
            /> -->
            <div class="mt-10">
              <uploadFile :src="messageForm.diplomaImage" @upload="uploadD" />
            </div>
          </template>
        </van-field>
      </van-form>
    </div>
    <div style="height: 1rem;"></div>
    <!-- <div class="button">
      <van-button round block type="info" style="width: 90%" @click="onConfirm">完成</van-button>
    </div> -->
    <fixedBtn :show="isShow" btnText="完成" @confirm="onConfirm" />
  </div>
</template>

<script>
import titles from '@/components/titles';
import fixedBtn from '@/components/fixed-button';
import idcardFile from '@/components/idcard-file';
import uploadFile from '@/components/upload-file';
// import confirmSign from './confirm_sign';
export default {
  components: {
    titles,
    fixedBtn,
    idcardFile,
    uploadFile,
    // confirmSign
  },
  data() {
    return {
      ownerId: this.$route.query.clueId,
      messageForm: {
        username: "",
        sex: "0",
        wxID: undefined,
        phoneNum: "",
        email: undefined,
        address: undefined,
        studentIdCard: "",
        frontalImage: undefined,
        reversedImage: undefined,
        blueImage: undefined,
        diplomaImage: undefined,
      },
      count: 0,
      topImgURL: require("../../assets/image/course_bg@2x.png"),
      successImgURL: require("../../assets/image/img_succed@2x.png"),
      isShow: true, // 
    };
  },
  mounted() {
    this.count ++;
    // 自动填充学员信息（如果有）
    let userMsg = JSON.parse(sessionStorage.getItem('userMsg'));
    if(userMsg) {
      this.messageForm = {
        username: userMsg.studentName,
        sex: userMsg.studentSex,
        wxID: userMsg.studentWechatNo,
        phoneNum: userMsg.studentPhone,
        email: userMsg.studentEmail,
        address: userMsg.studentAddress,
        studentIdCard: userMsg.studentIdCard,
        frontalImage: userMsg.frontalImage,
        reversedImage: userMsg.reversedImage,
        blueImage: userMsg.blueImage,
        diplomaImage: userMsg.diplomaImage,
      }
    }
    /*this.$refs.confirmSign.titleShow = false;
    this.$refs.confirmSign.bottomShow = false;
    this.$refs.confirmSign.isBd = false;
    this.$refs.confirmSign.isJf = false;
    this.$refs.confirmSign.isXw = false;
    this.$refs.confirmSign.isHt = false;
    this.$refs.confirmSign.isWay = false;
    this.$refs.confirmSign.isShadow = true;
    if(sessionStorage.getItem('formData')) {
      let params = JSON.parse(sessionStorage.getItem('formData'));
      this.$refs.confirmSign.voucherImage = params.voucherImage;
      setTimeout(()=> {
        // console.log(this.$route.query.way)
        let way = this.$route.query.way;
        this.$refs.confirmSign.wayClick(way);
      },1000);
    }*/
  },
  methods: {
    /*orderInfo(data) { // 学员信息
      // console.log(data);
      this.$set(this.messageForm,'username',data.studentName);
      this.$set(this.messageForm,'sex',data.studentSex);
      this.$set(this.messageForm,'wxID',data.studentWechatNo);
      this.$set(this.messageForm,'phoneNum',data.studentPhone);
      this.$set(this.messageForm,'email',data.studentEmail);
      this.$set(this.messageForm,'address',data.studentAddress);
      this.$set(this.messageForm,'studentIdCard',data.studentIdCard);
      this.$set(this.messageForm,'frontalImage',data.frontalImage);
      this.$set(this.messageForm,'reversedImage',data.reversedImage);
      this.$set(this.messageForm,'blueImage',data.blueImage);
      this.$set(this.messageForm,'diplomaImage',data.diplomaImage);
    },*/
    uploadA(data) { // 人像面
      // console.log(data)
      this.messageForm.frontalImage = data;
    },
    uploadB(data) { // 国徽面
      // console.log(data)
      this.messageForm.reversedImage = data;
    },
    uploadC(data) { // 蓝底照片
      // console.log(data)
      this.messageForm.blueImage = data;
    },
    uploadD(data) { // 毕业证照
      // console.log(data)
      this.messageForm.diplomaImage = data;
    },
    onConfirm() {
      let {
        username,
        sex,
        wxID,
        phoneNum,
        email,
        address,
        studentIdCard,
        frontalImage,
        reversedImage,
        blueImage,
        diplomaImage,
      } = this.messageForm;
      if(!username) {
        this.$toast('请输入姓名');
        return
      }
      if(!/^[0-9]{11}$/.test(phoneNum)) {
        this.$toast('请输入正确的手机号');
        return
      }
      if(!this.api.isCardNo(studentIdCard)) {
        this.$toast('请输入正确的身份证号码');
        return
      }
      if(!frontalImage) {
        this.$toast('请上传身份证人像面');
        return
      }
      if(!reversedImage) {
        this.$toast('请上传身份证国徽面');
        return
      }
      let params = JSON.parse(sessionStorage.getItem('formData'));
      this.$set(params,'studentName',username);
      this.$set(params,'studentSex',sex);
      this.$set(params,'studentIdCard',studentIdCard);
      this.$set(params,'studentWechatNo',wxID);
      this.$set(params,'studentPhone',phoneNum);
      this.$set(params,'studentEmail',email);
      this.$set(params,'studentAddress',address);
      this.$set(params,'frontalImage',frontalImage);
      this.$set(params,'reversedImage',reversedImage);
      this.$set(params,'blueImage',blueImage);
      this.$set(params,'diplomaImage',diplomaImage);
			this.$set(params,'isPay',0);
      // console.log(params);
      if(!params.ownerId) {
        if(/^[0-9]+$/.test(params.clueId)) { // 已有线索人
          this.orderUpdate(params);
        }else { // 没有线索人
          let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
          let data = {
            orderId: params.orderId,
            ownerId: this.ownerId,
            name: userInfo.studentName,
            mobileNumber: userInfo.mobileNumber,
          }
          this.api.clueAdd(data).then(res=> {
            if(res.code === 0) { // 没有线索人
              this.$set(params,'clueId',res.data);
              this.orderUpdate(params);
            }else if(res.code === 500) { // 已有线索人
              this.orderUpdate(params);
            }else {
              this.$toast(res.msg);
            }
          });
        }
      }else {
        let formData = params;
        delete formData.ownerId;
        this.orderUpdate(formData);
      }
    },
    orderUpdate(params) {
      this.api.orderUpdate(params).then(res=> {
        if(res.code === 0) {
          this.$toast('已提交');
          sessionStorage.removeItem('userMsg');
          sessionStorage.removeItem('formData');
          // sessionStorage.removeItem('signUrl');
          setTimeout(()=> {
            this.$router.push('/productDownload');
          },1000);
        }else {
          this.$toast(res.msg);
        }
      })
    },
  },
};
</script>

<style scoped>
.mesage_top {
  height: 9rem;
  position: relative;
}
.img_box {
  position: absolute;
  left: 50%;
  top: 1.4rem;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.4rem;
  height: 4.4rem;
  background-color: #fff;
  border-radius: 100%;
}
.success_img {
  width: 3.65rem;
  height: 3.5rem;
  margin: auto;
}
.success_message {
  position: absolute;
  top: 6.7rem;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 1rem;
  color: #fff;
}
.message {
  height: 100%;
}
.main::-webkit-scrollbar {
  display:none
}
/* 证件样式 */
.wrap {
  display: flex;
  flex-direction: column;
}
.uploader_box {
  width: 100%;
  /*display: flex;
  justify-content: space-around;*/
  margin-top: .85rem;
}
.uploader_box > div {
  width: 50%;
  height: 5.3rem;
}
.uploader_box > div:nth-child(1) {
  padding-right: .375rem;
}
.uploader_box > div:nth-child(2) {
  padding-left: .375rem;
}

.button {
  position: fixed;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 10%;
  background-color: #fff;
}
</style>
