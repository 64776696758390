<template>
<div v-if="show" class="item-select align-center between">
	<label class="item-select-title">{{ label }}</label>
	<a class="item-select-btn" href="javascript:;" @click="onSelect">
		<img v-if="isSelected" class="image" src="../../../assets/image/icon_up.png">
		<img v-else class="image" src="../../../assets/image/icon_down.png">
	</a>
</div>
</template>

<script>
export default {
	props: {
		show: {
			type: Boolean,
			default: true
		}, //
		label: {
			type: String,
			default: ''
		}, // 标题文字
		isSelected: {
			type: Boolean,
			default: true
		}, // 显示/隐藏 下拉框
	},
	methods: {
		onSelect() {
			this.$emit('onSelect');
		}, // 点击了按钮
	}
};
</script>

<style scoped>
.item-select {
	height: 2.5rem;
	padding: 0 1rem;
}
.item-select-title {
	font-weight: bold;
	font-size: .8rem;
	color: #242424;
}
.item-select-btn {
	width: 1rem;
	height: 1rem;
}
</style>