<template>
<div>
	<titles isBack titleName="定位" />
	<button @click="getLocation()">获取定位</button>

	<location-sign-in ref="myLocationSignIn" :isShow="isShow" :locationData="locationData" @locationConfirm="locationConfirm" />
</div>
</template>

<script>
	
import titles from '@/components/titles';
import LocationSignIn from '@/components/dislog/LocationSignIn';
export default {
	components: {
		titles,
		LocationSignIn
	},
	data() {
		return {
			locationData: {
				locationShow: true,
				locationSignTitle: '定位'
			},
			isShow: false,
		}
	},
	mounted() {
		this.getLocation();
	},
	methods: {
		getLocation() {
			this.$refs.myLocationSignIn.baiduMap();
			this.locationData.locationShow = true;
			this.isShow = true;
		},
		locationConfirm({lng,lat}) {
			console.log(lng,lat)
		}
	}
};
</script>