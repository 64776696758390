<template>
<div class="upload" 
	:style="{
		width: width + 'px',
		height: height + 'px'
	}">
	<input id="upload-file" class="image" :disabled="disabled" :type="iptType" accept="image/*" @change="upload">
	<a 
		v-if="!src"
		:class="['way-upload-img image border-box',{'flex': !isExample}]" 
		href="javascript:;">
		<img :class="['block',{'way-upload-img-center': !isExample,'way-upload-img-auto': isExample}]" src="../assets/image/icon_add.png">
		<p v-if="isExample" class="way-upload-hint">
			<span>*</span>
            上传缴费凭证
		</p>
	</a>
	<img 
		v-else 
		class="way-upload-img image" 
		:src="src">
</div>
</template>

<script>
export default {
	props: {
		width: {
			type: Number,
			default: 132
		},
		height: {
			type: Number,
			default: 75
		},
		disabled: {
			type: Boolean,
			default: false
		}, // 是否禁止上传图片: true 是,false 否
		src: {
			type: String,
			default: null
		}, // 图片路径
		isExample: {
			type: Boolean,
			default: false
		}, // 上传缴费凭证
	},
	data() {
		return {
			iptType: 'file',
			// voucher: null,
		}
	},
	mounted() {
		// console.log(this.src)
	},
	methods: {
		upload(e) { // 上传缴费凭证
			let files = e.target.files[0];
			this.iptType = 'text';
			setTimeout(()=> {
				this.iptType = 'file';
			},500);
			if(files) {
				if(!/image\/\D/.test(files.type)) {
					this.$toast('请上传图片')
					return
				}
				// let img = window.URL.createObjectURL(files);
				// console.log(img)
				let formData = new FormData();
				formData.append('file',files);

				this.api.upload(formData).then(res=> {
					if(res.code === 0) {
						// this.voucher = res.url;
						this.$emit('upload',res.url);
					}else {
						this.$toast(res.msg);
					}
				})
			}
		},
	}
};
</script>

<style scoped>
.upload {
	position: relative;
}
.upload input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	z-index: 99;
}
.way-upload-img {
	border-radius: .3rem;
}
a.way-upload-img {
	border: 1px solid #B1B1B1;
}
.way-upload-img img {
	width: 1.9rem;
	height: 1.9rem;
}
.way-upload-img-center {
	margin: auto;
}
.way-upload-img-auto {
	display: block;
	margin: 1.75rem auto 0;
}

.way-upload-hint {
	display: block;
	margin-top: .7rem;
	text-align: center;
	font-size: .7rem;
	color: #242424;
}
.way-upload-hint span {
	color: #F11B2A;
}
</style>