<template>
<div>
	<titles isBack titleName="开发票" />
	<dl class="invoice-type bgfff mt-10">
		<dt class="bold">发票类型</dt>
		<dd class="invoice-item align-center">
			<label class="invoice-item-label border-box">电子发票<!-- <span>(普票)</span> --></label>
			<div></div>
		</dd>
	</dl>

	<dl class="invoice-type bgfff mt-10">
		<dt class="bold">发票信息</dt>
		<dd class="invoice-item align-center">
			<label class="invoice-item-label shrink border-box">发票抬头</label>
			<input class="invoice-item-input" type="text" placeholder="请输入发票抬头" :disabled="disableds" v-model="params.invoiceHeader">
		</dd>
		<dd class="invoice-item align-center">
			<label class="invoice-item-label shrink border-box">手机号</label>
			<input class="invoice-item-input" type="text" placeholder="请输入手机号" :disabled="disableds" maxlength="11" v-model="params.invoicePhone">
		</dd>
		<dd class="invoice-item align-center">
			<label class="invoice-item-label shrink border-box">识别号</label>
			<input class="invoice-item-input" type="text" placeholder="请输入纳税人识别号或统一社会信用代码" :disabled="disableds" v-model="params.invoiceIdentifyNumber">
		</dd>
		<dd class="invoice-item align-center">
			<label class="invoice-item-label shrink border-box">开票说明</label>
			<input class="invoice-item-input" type="text" placeholder="请输入开票说明" :disabled="disableds" v-model="params.invoiceExplain">
		</dd>
	</dl>

	<dl class="invoice-type bgfff mt-10">
		<dt class="bold">接收邮箱</dt>
		<dd class="invoice-item align-center">
			<label class="invoice-item-label shrink border-box">邮箱帐号</label>
			<input class="invoice-item-input" type="text" placeholder="请输入邮箱帐号" :disabled="disableds" v-model="params.invoiceEmail">
		</dd>
		<dd class="invoice-hint color-red">注：五个工作日内完成开票，请留意邮箱</dd>
	</dl>

	<fixedBtn v-if="!disableds" @confirm="onConfirm" />
</div>
</template>

<script>
import titles from '@/components/titles';
import fixedBtn from '@/components/fixed-button';
export default {
	components: {
		titles,
		fixedBtn
	},
	data() {
		return {
			invoiceId: this.$route.query.invoiceId,
			params: {
				orderId: this.$route.query.orderId,
				invoiceHeader: '',
				invoicePhone: '',
				invoiceIdentifyNumber: '',
				invoiceExplain: '',
				invoiceEmail: '',
			},
			disableds: /^[0]+$/.test(this.$route.query.isPay), // true 已支付订单不能编辑
		}
	},
	mounted() {
		if(/^[0-9]+$/.test(this.invoiceId)) { // 发票信息
			this.api.invoiceInfo(this.invoiceId).then(res=> {
				if(res.code === 0) {
					this.params = {
						orderId: res.data.orderId,
						invoiceHeader: res.data.invoiceHeader,
						invoicePhone: res.data.invoicePhone,
						invoiceIdentifyNumber: res.data.invoiceIdentifyNumber,
						invoiceExplain: res.data.invoiceExplain,
						invoiceEmail: res.data.invoiceEmail
					}
				}else {
					this.$toast(res.msg);
				}
			})
		}
	},
	methods: {
		onConfirm() {
			// console.log('确定')
			let params = this.params;
			if(!params.invoiceHeader) {
				this.$toast('请输入发票抬头');
				return
			}
			if(!/^[0-9]{11}$/.test(params.invoicePhone)) {
				this.$toast('请输入正确的手机号');
				return
			}
			if(!params.invoiceIdentifyNumber) {
				this.$toast('请输入识别号');
				return
			}
			if(!params.invoiceExplain) {
				this.$toast('请输入开票说明');
				return
			}
			if(!params.invoiceEmail) {
				this.$toast('请输入邮箱');
				return
			}
			if(/^[0-9]+$/.test(this.invoiceId)) {
				this.$set(params,'orderInvoiceId',this.invoiceId);
				this.api.editInvoice(params).then(res=> {
					if(res.code === 0) {
						this.$toast('已保存');
						setTimeout(()=> {
							this.$router.back();
						},1500);
					}else {
						this.$toast(res.msg);
					}
				});
			}else {
				this.api.addInvoice(params).then(res=> {
					if(res.code === 0) {
						this.$toast('已保存');
						setTimeout(()=> {
							this.$router.back();
						},1500);
					}else {
						this.$toast(res.msg);
					}
				});
			}
		},
	}
};
</script>

<style scoped>
.invoice-type {
	padding-bottom: .5rem;
	font-size: .7rem;
	color: #101010;
}
.invoice-type dt {
	padding: .85rem .9rem .35rem;
}
.invoice-item {
	height: 1.7rem;
	padding: 0 .9rem;
}
.invoice-item-label {
	width: 5rem;
}
.invoice-item-input {
	width: 100%;
	height: 100%;
	font-size: .7rem;
	color: #101010;
}

.invoice-hint {
	padding: 0 .9rem;
	margin-top: .15rem;
	font-size: .6rem;
}
</style>