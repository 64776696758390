<template>
  <div class="tips">
    <van-dialog v-model="tips.tipsShow" :showConfirmButton="false" ref="dialog" closeOnClickOverlay @close="close">
      <div class="tips_title">{{tips.title}}</div>
      <div class="tips_message">{{tips.message}}</div>
      <div class="tips_btn">
        <van-button round block type="info" @click="btn">{{tips.confirmBtn}}</van-button>
      </div>
      <div class="close_icon" @click="close"><van-icon name="cross" color="#000" /></div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  props: {
    tips: {
      type: Object,
      // default: {
      //   title: '提示'
      // }
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    btn() {
      this.$emit('btn')
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style>
.tips {
  text-align: center;
}
.tips_title {
  margin: 20px 0 26px 0;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: #242424;
  opacity: 1;
}
.tips_message {
  margin: 0 auto;
  width: 80%;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 22px;
  color: #242424;
  opacity: 1;
}
.tips_btn {
  width: 80%;
  margin: 29px auto;
}
.close_icon {
  position: absolute;
  right: 7%;
  top: 7%;
}
</style>