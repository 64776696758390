var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"fixed-btn"},[_c('div',{staticClass:"fixed-btn-content align-center border-box"},[_c('a',{class:[
				'confirm',
				'block',
				'text-center',
				{'disabled': _vm.disabled}
			],style:({
				backgroundColor: _vm.bg,
				color: _vm.textColor
			}),attrs:{"href":"javascript:;"},on:{"click":_vm.confirm}},[_vm._v(" "+_vm._s(_vm.btnText)+" ")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }