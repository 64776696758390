<template>
<div v-show="isShow" class="title">
	<div class="title-content align-center between">
		<!-- 返回 -->
		<a v-if="isBack" class="title-close" href="javascript:;" @click="back">
			<img class="image" src="../assets/image/icon_return.png">
			<!-- &lt; -->
		</a>
		<!-- 关闭 -->
		<a v-else-if="isClose" class="title-close" href="javascript:;" @click="close">
			<img class="image" src="../assets/image/ic_close.png">
		</a>
		<span v-else class="title-close"></span>
		<span class="title-name">{{ titleName }}</span>
		<span class="title-close"></span>
	</div>
</div>
</template>

<script>
export default {
	props: {
		titleName: {
			type: String,
			default: ''
		}, // 标题
		isShow: {
			type: Boolean,
			default: true
		}, // 显示标题
		isBack: {
			type: Boolean,
			default: false
		}, // 显示返回按钮
		isClose: {
			type: Boolean,
			default: false
		}, // 显示关闭按钮
		page: {
			type: Number,
			default: -1
		}, // 返回页数
	},
	methods: {
		back() {
			this.$router.go(this.page);
		},
		close() {
			window.close();
			/*if (navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") !=-1) {
		        window.location.href="about:blank";
		        window.close();
		    } else {
		        window.opener = null;
		        window.open("", "_self");
		        window.close();
		    }*/
		},
	}
};
</script>

<style scoped>
.title,.title-content {
	height: 2.2rem;
}
.title-content {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	padding: 0 1rem;
	background-color: #fff;
	z-index: 99;
}
.title-close {
	width: 1.6rem;
	height: 1.6rem;
}
.title-name {
	font-family: 'PingFang SC';
	font-weight: bold;
	font-size: 18px;
	color: #242424;
}
</style>