<template>
<div>
	<titles isBack titleName="缴费凭证示例" />

	<div class="ex-title text-center bold">必须带有交易人、金额、单号、时间</div>
	<div class="ex-pay flex">
		<dl class="ex-pay-item">
			<dt>支付宝</dt>
			<dd>
				<img class="image" src="../../assets/image/alipay_ex.png">
			</dd>
		</dl>
		<dl class="ex-pay-item">
			<dt>微信</dt>
			<dd>
				<img class="image" src="../../assets/image/wechat_ex.png">
			</dd>
		</dl>
	</div>
	<div class="ex-pay flex">
		<dl class="ex-pay-item">
			<dt>银行卡</dt>
			<dd>
				<p>手机银行</p>
				<img class="image" src="../../assets/image/card_ex.png">
			</dd>
		</dl>
		<dl class="ex-pay-item">
			<dt></dt>
			<dd>
				<p>ATM</p>
				<img class="image" src="../../assets/image/ATM_ex.png">
			</dd>
			<dd>
				<p>银行回单</p>
				<img class="image" src="../../assets/image/card_return_ex.png">
			</dd>
		</dl>
	</div>
</div>
</template>

<script>
import titles from '@/components/titles';
export default {
	components: {
		titles
	},
	data() {
		return {}
	}
};
</script>

<style scoped>
.ex-title {
	margin-top: 1rem;
	font-size: .8rem;
	color: #FF5858;
}

.ex-pay {
	padding: 1rem .25rem .8rem;
}
.ex-pay-item {
	width: 50%;
	padding: 0 .25rem;
	box-sizing: border-box;
	flex-shrink: 0;
}
.ex-pay-item dt {
	height: 1.1rem;
	line-height: 1.1rem;
	font-weight: bold;
	font-size: .8rem;
	color: #242424;
}
.ex-pay-item dd {
	margin-top: .5rem;
}
.ex-pay-item dd p {
	margin-bottom: .25rem;
	text-align: center;
	font-weight: bold;
	font-size: .6rem;
	color: #FF5858;
}
</style>