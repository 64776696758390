<template>
  <div class="image">
    <p><router-link to="/productSet">产品分享设置</router-link></p>
    <p><router-link to="/productPoster">海报分享</router-link></p>
    <p><router-link to="/productDetails">产品详情</router-link></p>
    <p><router-link to="/productLogin">订单详情前面的登录页面</router-link></p>
    <p><router-link to="/productConfirmSign">订单详情</router-link></p>
    <!-- <p><router-link to="/productConfirmSignSkill">信息确认和合同签字-技能</router-link></p> -->
    <p><router-link to="/productShareContract">分享合同</router-link></p>
    <p><router-link to="/productContract">合同</router-link></p>


    <p><router-link to="/watchData">资料分享</router-link></p>
    <p><router-link to="/projectWxSign">项目实施 微信/App 签到</router-link></p>
    <!-- <p><router-link to="/projectAppSign">项目实App签到</router-link></p> -->
    <p><router-link to="/educationalWxSign">教务管理 微信/App 签到</router-link></p>
    <!-- <p><router-link to="/educationalAppSign">教务管理App签到</router-link></p> -->
    <p><router-link to="/openClassWx">公开课 微信/App 签到</router-link></p>
    <!-- <p><router-link to="/openClassApp">公开课App签到</router-link></p> -->
    <!-- <iframe class="image" :src="`https://view.xdocin.com/xdoc?_xdoc=${url}`" frameborder="0"></iframe> -->
    <!-- <div class="image" v-html="detail"></div> -->
    <p><router-link to="/map">定位</router-link></p>
    <p><router-link to="/intention">提交报读意向—引流</router-link></p>
  </div>
</template>
<script>
// import axios from 'axios'
// import mammoth from 'mammoth'
export default {
  data() {
    return {
      url: 'https://e-kzt.oss-cn-shenzhen.aliyuncs.com/file/2021/05/26/dl8kd5whm.doc',
      detail: '',
    }
  },
  mounted() {
    /*let url = 'https://e-kzt.oss-cn-shenzhen.aliyuncs.com/file/2021/05/26/dl8kd5whm.doc';
    axios({
      url: this.api.crossDomain(url),
      withCredentials:false,
      responseType:'arraybuffer'
    }).then(res=> {
      console.log(res)
      mammoth.convertToHtml({arrayBuffer: res.data})
        .then(result=>{
          console.log(result.value)
          // this.detail = result.value;
        })
        .done();
    })*/
  },
}
</script>
<style scoped>
</style>
