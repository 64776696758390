import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  userData: {}, // 用户基本信息
  sign: null, // 签名
  payWay: {
    status: 7, // 选中的支付方式: 7 支付宝,8 微信,9 银行卡
    img: '', // 缴费凭证
  }, // 缴费方式数据
}

if (sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== undefined) {
  state.userData = JSON.parse(sessionStorage.getItem('token'))
}

const getters = {}
const mutations = {
  // 用户信息
  modifyuserData (state, param) {
    state.userData = param
    console.log(param)
    sessionStorage.setItem('token', JSON.stringify(param))
  },
  getSign(state,data) { // 签名
    state.sign = data;
  },
  getWay(state,data) { // 缴费方式数据
    state.payWay = data;
  },
}
const actions = {}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})
