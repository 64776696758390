<template>
<div ref="sign" class="sign"><!--  :style="{
										width: bWidth+'px',
										height: bHeight+'px',
									}"
	> -->
	<div class="sign-title bgfff">
		<a class="icon-back trans90" href="javascript:;" @click="onBack">
			<img class="image" src="../../assets/image/icon_back_black.png">
		</a>
		<a class="sign-reset trans90" href="javascript:;" @click="onReset">重写</a>
	</div>

	<!-- 签名板 -->
	<div class="sign-content">
		<vue-signature ref="signature" :sigOption="sigOption" />
	</div>

	<a class="sign-send confirm-btn trans90" href="javascript:;" @click="onConfirm">保存</a>
</div>
</template>

<script>
import VueSignature from 'vue-signature';
export default {
	components: {
		VueSignature
	},
	data() {
		return {
			orderId: this.$route.query.orderId,
			sigOption: {
				penColor: "#101010", // 字体颜色
				// backgroundColor: "#F6F7F8", // 画布背景颜色
				backgroundColor: "#fff", // 画布背景颜色
			},
			// bWidth: document.body.clientHeight || 0,
			// bHeight: document.body.clientWidth || 0,
		}
	},
	mounted() {
		// console.log(this.$refs.signature)
	},
	destroyed() {
		// this.$bus.off('getSign');
	},
	methods: {
		onBack() {
			this.$router.back();
		},
		onReset() { // 重置签名板
			this.$refs.signature.clear();
		},
		onConfirm() { // 把签名传给上个页面 /productContract
			let signature = this.$refs.signature;
			let isEmpty = signature.isEmpty();
			if(isEmpty) {
				// this.$toast('请输入签名');
				return
			}
			var png = signature.save();
			// console.log(png)
			// return
			this.rotateBase64Img(png, 270, base64data=> {
				//生成的base64内容
				// console.log(base64data)
				// return
				let file = this.api.base64toFile(base64data);
				let formData=new FormData();
				formData.append('file',file);
				if(/^[0-9]+$/.test(this.orderId)) { // 签合同
					formData.append('orderId',this.orderId);
					this.api.contractSignIn(formData).then(res=> {
						if(res.code === 0) {
							this.$toast('已保存');
							// this.$store.commit('getSign',res.msg);
							this.$router.back();
						}else {
							this.$dialog({
								message: res.msg
							})
						}
					});
				}else { // 签到签名
					this.api.upload(formData).then(res=> {
						if(res.code === 0) {
							this.$store.commit('getSign',res.url);
							this.$router.back();
						}else {
							this.$dialog({
								message: res.msg
							})
						}
					});
				}

				// 调用上传图片接口上传到服务器

				// 把服务器返回的图片路径传到上一个页面
				// this.$bus.emit('getSign',png);
			});
		},
		rotateBase64Img(src, edg, callback) {
			var canvas = document.createElement("canvas");
			var ctx = canvas.getContext("2d");
			var imgW;//图片宽度
			var imgH;//图片高度
			var size;//canvas初始大小
			if (edg % 90 != 0) {
				console.error("旋转角度必须是90的倍数!");
				throw '旋转角度必须是90的倍数!';
			}
			(edg < 0) && (edg = (edg % 360) + 360)
			const quadrant = (edg / 90) % 4; //旋转象限
			const cutCoor = {sx: 0, sy: 0, ex: 0, ey: 0}; //裁剪坐标
			var image = new Image();
			image.crossOrigin = "anonymous"
			image.src = src;
			image.onload = function () {
				imgW = image.width;
				imgH = image.height;
				size = imgW > imgH ? imgW : imgH;
				canvas.width = size * 2;
				canvas.height = size * 2;
				switch (quadrant) {
					case 0:
						cutCoor.sx = size;
						cutCoor.sy = size;
						cutCoor.ex = size + imgW;
						cutCoor.ey = size + imgH;
						break;
					case 1:
						cutCoor.sx = size - imgH;
						cutCoor.sy = size;
						cutCoor.ex = size;
						cutCoor.ey = size + imgW;
						break;
					case 2:
						cutCoor.sx = size - imgW;
						cutCoor.sy = size - imgH;
						cutCoor.ex = size;
						cutCoor.ey = size;
						break;
					case 3:
						cutCoor.sx = size;
						cutCoor.sy = size - imgW;
						cutCoor.ex = size + imgH;
						cutCoor.ey = size + imgW;
					break;
				}
				ctx.translate(size, size);
				ctx.rotate(edg * Math.PI / 180);
				//drawImage向画布上绘制图片
				ctx.drawImage(image, 0, 0);
				//getImageData() 复制画布上指定矩形的像素数据
				var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
				if (quadrant % 2 == 0) {
					canvas.width = imgW;
					canvas.height = imgH;
				} else {
					canvas.width = imgH;
					canvas.height = imgW;
				}
				//putImageData() 将图像数据放回画布
				ctx.putImageData(imgData, 0, 0);
				callback(canvas.toDataURL())
			};
		},
	}
};
</script>

<style scoped>
body >>> .van-toast--text {
	transform: rotate(90deg) !important;
}

.sign {
	width: 100%;
	height: 100%;
	/*position: absolute;
	top: 0;
	left: 0;
	transform: rotate(90deg);*/
}
.sign-title {
	width: 0;
	height: 100%;
	padding: .6rem 0 1rem 0;
	box-sizing: border-box;
	display: flex;
	align-content: space-between;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
}
.icon-back {
	width: 1rem;
	height: 1rem;
	flex-shrink: 0;
	position: absolute;
	top: .6rem;
	right: .7rem;
}
.sign-reset {
	line-height: 1rem;
	white-space: nowrap;
	text-align: right;
	font-size: .8rem;
	color: #1E85F4;
	position: absolute;
	bottom: 1.5rem;
	right: .55rem;
}
.trans90 {
	transform: rotate(90deg);
}

.sign-content {
	width: 100%;
	height: 100%;
}

.sign-send {
	position: fixed;
	left: -2rem;
	bottom: 4.5rem;
	width: 8.8rem;
	height: 2.5rem;
	line-height: 2.5rem;
	border-radius: 1.25rem;
	text-align: center;
	z-index: 99;
}
</style>