const files = 'https://e-kzt.oss-cn-shenzhen.aliyuncs.com';

// const kzt = 'https://kzt.admin.kaozhengtong.net/common'; //接口地址-线上（（发送验证码、验证码登录、密码登录、设置姓名 用这个地址））
// const crm = 'https://crm.admin.kaozhengtong.net'; //接口地址-线上
// const crm2 = 'http://crm.admin.kaozhengtong.net'; //接口地址-线上

const kzt = 'https://test.kzt.admin.kaozhengtong.net/common'; //接口地址-测试（发送验证码、验证码登录、密码登录、设置姓名 用这个地址）
const crm = 'https://test.crm.admin.kaozhengtong.net'; //接口地址-测试
const crm2 = 'http://test.crm.admin.kaozhengtong.net'; //接口地址-测试

// const kzt = 'http://192.168.1.140:10811'; //接口地址-后台本地
// const crm = 'http://192.168.1.140:10811'; //接口地址-后台本地

// export default
module.exports = {
    files,
    kzt,
    crm,
    crm2
}