import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import home from '@/views/home';
import productDetails from '@/views/product/details'; // 产品详情
import productLogin from '@/views/product/login'; // 登录
import productConfirmSign from '@/views/product/confirm_sign'; // 信息确认和合同签字-学历
import productExample from '@/views/product/example'; // 缴费凭证示例
import productInvoice from '@/views/product/invoice'; // 开发票
import productContract from '@/views/product/contract'; // 签署合同
import productSign from '@/views/product/sign'; // 签名
import productUserInfo from '@/views/product/user-info'; // 填写基本信息
import productDownload from '@/views/product/download'; // 报读成功-下载app
import watchData from '@/views/shareDataBase/WatchData' //资料分享

import projectWxSign from '@/views/allSignIn/projectWechat/ProjectWxSign' //项目实施微信签到
// import projectAppSign from '@/views/allSignIn/projectApp/ProjectAppSign' //项目实施app签到
import educationalWxSign from '@/views/allSignIn/educationalWeChat/EducationalWxSign' //教务管理微信签到
// import educationalAppSign from '@/views/allSignIn/educationalApp/EducationalAppSign' //教务管理App签到
import openClassWx from '@/views/allSignIn/openClassWeChat/OpenClassWx' //公开课微信签到
// import openClassApp from '@/views/allSignIn/OpenClassApp/OpenClassApp' //公开课App签到


// import productConfirmSignSkill from '@/views/product/confirm_sign-skill'; // 信息确认和合同签字-学历
import productSet from '@/views/product/setting'; // 产品分享设置
import productPoster from '@/views/product/poster'; // 生成海报/产品分享
import productShareContract from '@/views/product/share-contract'; // 分享合同


import map from '@/views/map/map'; // 分享合同

import intention from '@/views/intention/intention'; // 提交报读意向-引流

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: home
    },

    {
      path: '/productDetails',
      name: 'productDetails',
      component: productDetails
    },
    {
      path: '/productLogin',
      name: 'productLogin',
      component: productLogin
    },
    {
      path: '/productConfirmSign',
      name: 'productConfirmSign',
      component: productConfirmSign,
      meta: {
        // keepAlive: true
      }
    },
    {
      path: '/productExample',
      name: 'productExample',
      component: productExample
    },
    {
      path: '/productInvoice',
      name: 'productInvoice',
      component: productInvoice
    },
    {
      path: '/productContract',
      name: 'productContract',
      component: productContract,
      meta: {
        // keepAlive: true
      }
    },
    {
      path: '/productSign',
      name: 'productSign',
      component: productSign
    },
    {
      path: '/projectWxSign',
      name: 'projectWxSign',
      component: projectWxSign,
      meta: {
        keepAlive: true
      }
    },
    // {
    //   path: '/projectAppSign',
    //   name: 'projectAppSign',
    //   component: projectAppSign
    // },
    {
      path: '/watchData',
      name: 'watchData',
      component: watchData
    },
    {
      path: '/productUserInfo',
      name: 'productUserInfo',
      component: productUserInfo
    },
    {
      path: '/productDownload',
      name: 'productDownload',
      component: productDownload
    },

    // {
    //   path: '/productConfirmSignSkill',
    //   name: 'productConfirmSignSkill',
    //   component: productConfirmSignSkill
    // },

    {
      path: '/productSet',
      name: 'productSet',
      component: productSet
    },
    {
      path: '/productPoster',
      name: 'productPoster',
      component: productPoster
    },
    {
      path: '/productShareContract',
      name: 'productShareContract',
      component: productShareContract
    },
    {
      path: '/educationalWxSign',
      name: 'educationalWxSign',
      component: educationalWxSign
    },
    // {
    //   path: '/educationalAppSign',
    //   name: 'educationalAppSign',
    //   component: educationalAppSign
    // },
    {
      path: '/openClassWx',
      name: 'openClassWx',
      component: openClassWx
    },
    // {
    //   path: '/openClassApp',
    //   name: 'openClassApp',
    //   component: openClassApp
    // },
    {
      path: '/map',
      name: 'map',
      component: map
    },
    {
      path: '/intention',
      name: 'intention',
      component: intention
    },
  ]
});