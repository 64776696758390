<template>
  <div class="location_sign_in">
    <van-popup v-model="locationData.locationShow" closeable :showConfirmButton="false" ref="dialog">
      <div class="sign_title">定位签到</div>
      <div class="location">
        <!-- <location ref="myLocation" @locationMessage="getLocationMessage"></location> -->
        <div id="myMap" class="map image"></div>
        <a class="my-location" href="javascript:;" @click="myLocation(myMap,myPoint)">
          <img class="image" src="../../assets/image/icon_location.png" />
        </a>
      </div>
      <!-- <div class="map_message" v-html="myAddress"></div> -->
      <!-- <div id="map_message" class="map_message"></div> -->
      <textarea class="map_message" readonly v-model="myAddress"></textarea>
      <div style="margin: 20px 20px;">
        <van-button round block type="info" @click="confirmBtn">确认</van-button>
      </div>
    </van-popup>
    <!-- <van-popup v-model="isShow" closeable :showConfirmButton="false" ref="dialog">
      {{ coordinateMessage }}
    </van-popup> -->
  </div>
</template>

<script>
// import Location from '../Map'
export default {
  props: {
    locationData: {
      type: Object,
      default() {
        return {
          locationShow: false,
          locationSignTitle: '定位签到'
        }
      }
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  components: {
    // Location
  },
  data () {
    return {
      coordinateMessage: '',
      myMap: null, // 地图对象
      myAddress: '', // 我的地址
      myPoint: {
        lat: null,
        lng: null
      }, // 我的经纬度
    }
  },
  mounted() {
    // this.baiduMap();
  },
  methods: {
    confirmBtn() {
      // this.isShow = false;
      this.$emit('locationConfirm', this.myPoint)
    },
    baiduMap() {
      var geolocation = new window.BMap.Geolocation();
      // geolocation.enableSDKLocation();
      let that = this;
      let map = new window.BMap.Map("myMap");
      // console.log(map)
      var gc = new window.BMap.Geocoder();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == window.BMAP_STATUS_SUCCESS) {
          gc.getLocation(r.point, function (rs) {
            that.myAddress = rs.address;
            that.myPoint = rs.point;
            that.myMap = map;
            // console.log(rs);

            that.myLocation(map,rs.point);
          });
        }
        else {
          alert('failed' + this.getStatus());
        }
      }, { enableHighAccuracy: true });

      /*map.addEventListener( "dragend" , function showInfo(){
        var cp = map.getCenter();
        gc.getLocation(cp, function (rs) {
          console.log(rs)
          that.myAddress = rs.address;
          that.myPoint = rs.point;
          that.myMap = map;
          that.myLocation(map,rs.point);
        });
      });*/
    },
    myLocation(map,point) { // 中心点
      map.centerAndZoom(point, 16);
      var marker = new window.BMap.Marker(point);// 创建标注
      map.addOverlay(marker);
      map.panTo(point);
    },
  }
}
</script>

<style scoped>
.location_sign_in {
  text-align: center;
}
.location_sign_in >>> .van-popup {
  width: 268px;
  height: 378px;
  background: #F8FBFF;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 0 20px;
}
div /deep/ .van-dialog__content {
  padding: 20px 20px;
}
.location {
  height: 164px;
  position: relative;
}
.map {
  margin: 0 auto;
  border-radius: 6px;
}

.my-location {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 6px;
  bottom: 6px;
  z-index: 999;
}
.sign_title {
  height: 62px;
  line-height: 62px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #242424;
  opacity: 1;
}
.map_message {
  width: 100%;
  height: 44px;
  line-height: 22px;
  margin: 19px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 16px;
  font-family: PingFang SC;
  color: #242424;
  opacity: 1;
  border: none;
  padding: 0;
  resize: none;
  background: none;
}
</style>
