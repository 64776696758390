<template>
  <div class="watch_data">
    <login-data :loginShow="loginShow" @loginData="loginData"></login-data>
    <div class="head_message">
      <div class="head_left">
        <div class="face_img_box">
          <img :src="detail.image" class="face_img">
        </div>
        <div class="face_message">
          <div class="face_name">
            {{ detail.name }} 
          </div>
          <div class="face_date">
            {{ detail.phone }}
          </div>
        </div>
      </div>
      <div class="head_right">
        <div class="qr_code">
          <img :src="detail.codeImage" class="qr_img">
        </div>
      </div>
    </div>

    <div v-if="fileType==='pdf'" class="image">
      <pdf 
        id="pdf" 
        ref="pdf" 
        class="pdf image" 
        :src="detail.file" />
    </div>
    <div v-if="/docx/.test(fileType)" class="image" v-html="detail.file"></div>
  </div>
</template>

<script>
import LoginData from './components/LoginData';
import pdf from 'vue-pdf';
import mammoth from 'mammoth'
import axios from 'axios'

export default {
  components: {
    LoginData,
    pdf
  },
  data() {
    return {
      loginShow: false,
      detail: {},
      fileType: null,
    }
  },
  computed: {
    getDate() {
      const myDate = new Date();
      const date = myDate.toLocaleDateString()
      const time = myDate.toLocaleTimeString('chinese', { hour12: false })
      return date + '  ' + time
    }
  },
  mounted() {
    let isLogin = sessionStorage.getItem('isLogin');
    if(isLogin) { // 已填过资料
      this.resourceInfo();
    }else { // 未填资料
      this.loginShow = true
    }
  },
  methods: {
    loginData() {
      this.loginShow = false;
      this.resourceInfo();
    },
    resourceInfo() {
      let {
        resourceId,
        type,
        ownerId
      } = this.$route.query;
      let params = {
        resourceType: type,
        resourceId: resourceId,
        ownerId: ownerId
      }
      this.api.resourceInfo(params).then(res=> {
        if(res.code === 0) {
          this.detail = res.data;
          let url = this.api.crossDomain(res.data.fileUrl);
          let fileName=url.split('/')[url.split('/').length-1]
          this.fileType=fileName.split('.')[1]
          console.log(this.fileType)
          if(/pdf/.test(this.fileType)){
            this.$set(this.detail,'file',pdf.createLoadingTask(url));
          }else if(/docx/.test(this.fileType)){
            axios({
              url,
              withCredentials:false,
              responseType:'arraybuffer'
            }).then(res=> {
              // console.log(res)
              mammoth.convertToHtml({arrayBuffer: res.data})
                .then(result=>{
                  // console.log(result.value)
                  this.$set(this.detail,'file',result.value);
                })
                .done();
            })
          }else {
            this.$toast('文件格式不支持，仅支持pdf,docx文件预览')
          }
        }else {
          this.$toast(res.msg);
        }
      });
    },
  }
}
</script>

<style scoped>
.watch_data {
  height: 100%;
}
.head_message {
  display: flex;
  justify-content: space-between;
  background-color: #E9F4FF;
  padding: 13px 20px;
}
.head_left {
  display: flex;
  align-items: center;
  width: 100%;
} 
.face_img_box {
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  opacity: 1;
}
.face_img {
  width: 100%;
  border-radius: 50%;
}
.face_message {
  text-align: left;
  width: 65%;
  height: 40px;
  line-height: 25px;
  /* margin-left: 23px; */
  margin: 0 0 8px 23px;
  font-size: 18px;
  font-weight: 400;
  font-family: PingFang SC;
  color: #242424;
}
.face_name {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 25px;
  color: #242424;
}
.face_date {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: #999999;
  white-space: nowrap;/*不换行*/
  overflow:hidden;
  text-overflow: ellipsis;/*超出部分文字以...显示*/
}
.qr_code {
  width: 64px;
  height: 64px;
}
.qr_img {
  width: 100%;
}
</style>