<template>
  <div class="ranking">
    <div class="ranking_box">
      <div class="ranking_title">
        <div class="title_left">
          <img src="../../../assets/image/ic_left@2x.png" class="title_img">
        </div>
        <div class="title_main">
          本次签到排名
        </div>
        <div class="title_right">
          <img src="../../../assets/image/ic_right@2x.png" class="title_img">
        </div>
      </div>
      <div class="ranking_data">
        <div class="time_data">
          {{ myRan.signInDate }}
        </div>
        <div class="rank_data">
          当前排名: {{ myRan.studentRanking || '--' }}
        </div>
      </div>
      <div class="ranking_detail">
        <p v-if="!data.length" class="ranking-title">暂无排名</p>
        <div class="ranking_item" 
          v-for="(item, index) in data" 
          :key="index"
        >

          <div class="ranking_index">
            <div class="ranking_num_topthree" v-if="item.signInDate && index == 0">
              <img src="../../../assets/image/no1@2x.png" class="num_img">
            </div>
            <div class="ranking_num_topthree" v-else-if="item.signInDate && index == 1">
              <img src="../../../assets/image/no2@2x.png" class="num_img">
            </div>
            <div class="ranking_num_topthree" v-else-if="item.signInDate && index == 2">
              <img src="../../../assets/image/no3@2x.png" class="num_img">
            </div>
            <div v-else class="ranking_num">
              {{ item.signInDate ?index + 1:'--' }}
            </div>
          </div>

            <div class="ranking_message_face" :style="{
                  backgroundImage: `url('${ headNull }')`
              }">
              <!-- <img :src="item.image" class="face_img image" :style="{
                  backgroundImage: `url('${ headNull }')`
              }"> -->
            </div>

            <div class="ranking_message_detail">
              <div class="name">
                {{item.studentName}}
              </div>
              <div class="date">
                {{ item.signInDate || '--' }}
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data','myRan'],
  data() {
    return {
      nowRanking: 1000,
      headNull: require('../../../assets/image/head_null.png'),
      /*data: [
        {
          studentName: '王大锤',
          signInDate: '2021-5-20'
        }
      ],*/
    }
  },
  computed: {
    getDate() {
      const myDate = new Date();
      const date = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
      const time = myDate.toLocaleTimeString('chinese', { hour12: false, hour: '2-digit', minute:'2-digit' })
      return date + '  ' + time
    },
  }
};
</script>

<style scoped>
  .ranking_box {
    text-align: center;
    margin: 0 auto 20px;
    padding:18px 18px 10px;
    width: 80%;
    border: 1px solid #1E85F4;;
    background-color: #fff;
    border-radius: 13px;
  }
  .ranking_title {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  .title_main {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 30px;
    margin: 0 15px;
    color: #1E85F4;
  }
  .title_img {
    height: 12px;
    border: 1px solid rgba(0, 0, 0, 0);
  }
  .ranking_data {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #65ADFB;
  }

  .ranking-title {
    height: 100px;
    line-height: 100px;
    font-size: 14px;
    color: #999;
  }

  .ranking_item {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 10px 0;
  }
  .ranking_index {
    text-align: center;
    width: 30px;
    height: 30px;
  }
  .ranking_num_topthree {
    width: 100%;
    height: 100%;
  }
  .ranking_num {
    font-size: 19px;
    font-family: PingFang SC;
    line-height: 30px;
    color: #242424;
    opacity: 1;
  }
  .num_img {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
  }
  .ranking_message_face {
    width: 45px;
    height: 45px;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    border-radius: 50%;
    margin: 0 20px;
    overflow: hidden;
  }
  /*.face_img {
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
  }*/
  .ranking_message_detail .name {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #242424;
  }
  .ranking_message_detail .date {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 17px;
    color: #999999;
  }
</style>
