<template>
  <div class="image">
      <titles isBack titleName="签到" />
      <div class="open_class_wx" :style="{
        backgroundImage: `url('${ bgImg }')`
      }">

        <login :loginShow="loginShow" @login="login" />
        <name :nameMessage="nameMessage" @nameConfirm="nameConfirm" />
        <location-sign-in ref="myLocationSignIn" :locationData="locationData" @locationConfirm="locationConfirm" />
        <sign-success :successMessage="successMessage" @close="close" />
        <sign-err :errShow="errShow" :errMessage="errMessage" @close="close" />


        <all-sign-in :disabled="isSignIn" :signInData="openClassWxData" @signIn="signIn" />

      </div>
  </div>
</template>

<script>

import titles from '@/components/titles';
import AllSignIn from '../components/AllSignIn'
import Login from '@/components/dislog/Login'
import Name from '@/components/dislog/Name'
import LocationSignIn from '@/components/dislog/LocationSignIn'
import SignSuccess from '@/components/dislog/SignSuccess'
import SignErr from '@/components/dislog/SignErr'

// import BMap from 'BMap';

export default {
 components: {
    titles,
    AllSignIn,
    Login,
    Name,
    LocationSignIn,
    SignSuccess,
    SignErr
 },
 data() {
    return {
      bgImg: require('../../../assets/image/bg_sign_in@2x.png'), // 背景图片
      openClassId: this.$route.query.openClassId, // 公开课id（扫码传进来的）4
      userInfo: {}, // 用户信息
      classData: {}, // 课程信息
      isSignIn: true, // 签到状态: true 已签,false 未签
      // 弹窗信息
      loginShow: false,
      nameMessage: {
        nameShow: false,
        nameTitle:'请填写真实姓名',
        placeholder: '请输入真实姓名'
      },
      locationData: {
        locationShow: false,
        locationSignTitle: ''
      },
      successMessage: {
        successShow: false,
        successTitle: '签到成功'
      },
      errShow: false,
      errMessage: {
        errTitle: '签到失败',
        errDetailMessage: '',
      },
      openClassWxData: {
        top: {
          topTitle: '公开课信息',
          topMessage: [
            {
              topLeft: '公开课名称',
              topRight: '未设置'
            },
            {
              topLeft: '地点',
              topRight: '未设置'
            }
          ]
        },
        center: {},
        bottom : {
          bottomTitle: '签到人信息',
          signNameShow: true,
          signPhoneShow: true,
          signSexShow: true,
          signForm: {
            username: '',
            phoneNum: '',
            sex: '',
          },
        },
        timeSlot: { 
          isShow: false
        },
        button: {
          signInBtn: '签到',
          signUpShow: false
        }
      },
      rCode: null, // 只要不是 0 都是不能签到的
    }
  },
  mounted() {
    // this.$refs.myLocationSignIn.baiduMap();
  },
  methods: {
    // 登录
    login(loginMessage) {
      this.loginShow = false;
      this.userInfo = loginMessage;
      // this.classInfo();
      this.getInfo(this.userInfo);
      // 判断是否新用户，是新用户则设置姓名
      // isNewUser 判断是否新用户: 0 否,1 是
      if(loginMessage.isNewUser == 1 || !loginMessage.studentName || loginMessage.studentName == 'KZT') {
        this.nameMessage.nameShow = true
      }else {
        this.addClue();
      }
    },
    nameConfirm(userInfo) { // 填写真真实姓名
      this.nameMessage.nameShow = false;
      this.userInfo = userInfo;
      this.getInfo(this.userInfo);
      this.addClue();

      // if(nameForm.name){
      //   let params = {
      //     studentName: nameForm.name,
      //     studentId: this.userInfo.studentId
      //   }
      //   this.api.changeName_kzt(params).then(res=> {
      //     if(res.code === 0) {
      //       this.nameMessage.nameShow = false;
      //       this.userInfo = res.data;
      //       // this.openClassWxData.bottom.signForm.username = nameForm.name;
      //       this.getInfo(this.userInfo);
      //       this.$toast(res.msg);
      //       sessionStorage.setItem('userInfo',JSON.stringify(res.data));
      //       this.addClue();
      //     }else {
      //       this.$toast(res.msg)
      //     }
      //   });
      // }else {
      //   this.$toast('请输入真实姓名')
      // }
    },

    signIn() { // 签到
      // console.log(this.openClassWxData.bottom.signForm);
      /*let { username, phoneNum,sex } = this.openClassWxData.bottom.signForm
      if(!username) {
        this.$toast('请输入姓名信息');
        return
      }
      if(!/^[0-9]{11}$/.test(phoneNum)) {
        this.$toast('请输入正确的手机号');
        return
      }*/
      // if(!/^[01]+$/.test(sex)) {
      //   this.$toast('请输入正确的手机号');
      //   return
      // }
      // this.$refs.myLocationSignIn.baiduMap();
      this.locationData.locationShow = true;
      setTimeout(this.$refs.myLocationSignIn.baiduMap,500);
    },
    // 判断定位
    locationConfirm({lng,lat}) {
      // console.log(lng,lat);
      /*let location = {
        address: '广东省深圳市福田区泰然四路26号',
        lat: 22.540542,
        lng: 114.036553
        // address: '龙城广场地铁站',
        // lat: 22.72323,
        // lng: 114.260917
      }
      let map = new window.BMap.Map('allmap');
      let pointClass = new window.BMap.Point( this.classData.longitude , this.classData.latitude ); // 课程
      // let pointClass = new window.BMap.Point( location.lng , location.lat ); // 课程
      let pointMy = new window.BMap.Point( location.lng , location.lat ); // 我现在的位置
      // console.log(pointClass,pointMy);
      let distance = map.getDistance(pointClass,pointMy);
      console.log('距离学校: '+distance.toFixed(2)+'米');*/
      this.locationData.locationShow = false;
      // this.$dialog({
      //   message: '距离学校: '+distance.toFixed(2)+'米'
      // });
      // if(distance > this.classData.signInDistance) { // 我的距离 > 课程距离-不可以签到哦
      //   this.errMessage.errDetailMessage = "不在签到地点"+this.classData.signInDistance+"米范围内"
      //   this.errShow = true
      // } else { // 指定范围内-可以签到
        // let params = {
        //   objectId: this.openClassId,
        //   studentId: this.userInfo.studentId, 
        //   // dateType 时间类型（1代表上午 2代表下午 3代表晚上） 
        //   type: 1,
        //   // studentIdCard: this.userInfo.studentIdCardNo,
        //   longitude: lng, 
        //   latitude: lat,
        //   signInType: 1
        // }
        // // console.log(params)
        // this.api.schedulingSignIn(params).then(res=> {
        //   if(res.code === 0) {
            let formData = {
              signInType: 1, // 1=代表公开课,3=代表项目实施
              userName: this.userInfo.studentName, // 姓名
              userSex: this.openClassWxData.bottom.signForm.sex, // 性别
              userPhone: this.userInfo.mobileNumber, // 手机号
              objectId: this.openClassId,
              longitude: lng,
              latitude: lat,
              // longitude: location.lng,
              // latitude: location.lat,
              // userIdCard: this.userInfo.studentIdCardNo, // 身份证号
              // userCompany: this.openClassWxData.bottom.signForm.company // 公司
            }
            this.api.userSigin(formData).then(data=> {
              if(data.code === 0) {
                this.successMessage.successShow = true;
                this.checkStudentSignIn();
              }else {
                if(data['3964']) {
                  this.errMessage.errDetailMessage = "不在签到地点"+this.classData.signInDistance+"米范围内"
                  this.errShow = true
                }else {
                  this.$toast.fail(data.msg);
                }
              }
            });
        //   }else {
        //     this.$toast.fail(res.msg);
        //   }
        // });
      // }
    },
    close() {
      this.successMessage.successShow = false
      this.errShow = false
      this.errMessage.errDetailMessage = ''
      this.errMessage.errDetailMessage = ''
    },
    classInfo() { // 公开课签到信息
      let params = {
        // openClassId: 4,
        openClassId: this.openClassId,
        studentPhone: this.userInfo.mobileNumber
      }
      this.api.classInfo(params).then(res=> {
        this.rCode = res.code;
        if(res.code === 0) {
          let data = res.data;
          this.classData = data;
          this.openClassWxData.top.topMessage[0].topRight = data.openClassName;
          this.openClassWxData.top.topMessage[1].topRight = data.openClassAddress;

          // this.isSignIn = data.isSignIn;

          // this.openClassWxData.bottom.signForm.username = data.studentName;
          // this.openClassWxData.bottom.signForm.phoneNum = data.studentPhone;
          // this.openClassWxData.bottom.signForm.sex = data.sex;
          this.checkStudentSignIn();
        }else {
          this.$dialog({
            message: res.msg
          });
          let data = res.data;
          this.classData = data;
          this.openClassWxData.top.topMessage[0].topRight = data.openClassName;
          this.openClassWxData.top.topMessage[1].topRight = data.openClassAddress;
        }
      });
    },
    checkStudentSignIn() { // 查询签到状态
      let params = {
        // dateType: 1,
        objectId: this.openClassId,
        signInType: 1,
        // studentId: this.userInfo.studentId,
        userPhone: this.userInfo.mobileNumber
      }
      this.api.checkStudentSignIn(params).then(res=> {
        if(res.code === 0) {
          // let {
          //   disabledIn,inBtn
          // } = this.api.into(1,res.data.signIn);
          // console.log(disabledIn,inBtn)
          // this.isSignIn = disabledIn;
          this.isSignIn = res.data.signIn;
          // this.isSignIn = false;
          // this.openClassWxData.button.signInBtn = inBtn;
          this.openClassWxData.button.signInBtn = res.data.signIn?'已签到':'签到';
        }
      });
    },
    getInfo(userInfo) {
      this.openClassWxData.bottom.signForm.username = userInfo.studentName;
      this.openClassWxData.bottom.signForm.phoneNum = userInfo.mobileNumber;
      // this.openClassWxData.bottom.signForm.idNum = userInfo.studentIdCardNo;
      this.openClassWxData.bottom.signForm.sex = userInfo.studentSex+'';
      this.openClassWxData.bottom.signForm.sexDis = /^[01]{1}$/.test(userInfo.studentSex);
      // this.wxSignData.bottom.signForm.company = userInfo.studentCompany;
      // this.wxSignData.bottom.signForm.companyDis = userInfo.studentCompany?true:false;
      if(userInfo.isNewUser == 1 || !userInfo.studentName || userInfo.studentName == 'KZT') {
        this.nameMessage.nameShow = true
      }else {
        this.classInfo();
      }
    },
    addClue() { // 添加线索人
      let params = {
        name: this.userInfo.studentName || '',
        mobileNumber: this.userInfo.mobileNumber || '',
        companyName: this.userInfo.workUnit || '',
        source: '公开课',
        studentIdCardNo: this.userInfo.studentIdCardNo || '',
      }
      this.api.addClue(params).then(res=> {
        console.log(res)
        });
    },
  },
  created() {
    // 判断用户是否登录-若已登录则获取用户信息
    if(sessionStorage.getItem('userInfo')) {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      this.userInfo = userInfo;
      this.getInfo(this.userInfo);
      // this.classInfo();
      // 判断是否新用户，是新用户则设置姓名
      // isNewUser 判断是否新用户: 0 否,1 是
      // if(userInfo.isNewUser == 1 || !userInfo.studentName || userInfo.studentName == 'KZT') {
      //   this.nameMessage.nameShow = true
      // }
    }else { // 显示登录弹框
      this.loginShow = true
    }
  }
};
</script>

<style scoped>
.open_class_wx {
  overflow: scroll;
  text-align: left;
  height: 100%;
  /*background: url('../../../assets/image/bg_sign_in@2x.png') no-repeat top center #f0f6ff;*/
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;
}
.open_class_wx::-webkit-scrollbar {
  display:none
}
</style>