<template>
  <div class="class_detail">
    <van-dialog
      v-model="classDetailShow"
      :showConfirmButton="false"
      ref="dialog"
      @close="close"
    >
      <img :src="classDetailBgURL" class="bg_img" />
      <div class="class_detail_title">{{ title }}</div>
      <div class="deail_main_box">
        <!-- <slot></slot> -->
        <div class="deail_main_item" v-for="(item, index) in detail" :key="index">
          <div class="class_name">{{item.className}}</div>
          <div class="class_main">
            <div class="class_subject">科目: {{item.subjectName}}</div>
            <div class="class_validity">有效期: {{item.validDate}}天</div>
          </div>
          <van-divider :style="{ borderColor: '#F1F5F8', padding: '0 20px 0 0' }" />
        </div>
        <p v-if="!detail.length" class="data-null">暂无课程</p>
      </div>
      <div @click="close"><van-icon name="cross" class="close_icon" color="#fff" /></div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  props: {
    classDetailShow: {
      type: Boolean,
      default() {
        return false
      }
    },
    orderId: {
      type: Number,
      default: undefined
    },
  },
  data() {
    return {
      classDetailBgURL: require('../../assets/image/bg_check.png'),
      title: "考证通APP课程",
      page: 1, // 页码
      total: 0, // 总数据量
      detail: [],
      isShow: this.classDetailShow
    }
  },
  methods: {
    getData() {
      this.api.courseList(this.orderId,this.page).then(res=> {
        if(res.code === 0) {
          this.detail = res.rows;
          this.total = res.total;
          this.$emit('close',true)
        }else {
          this.$toast(res.msg);
        }
      });
    },
    close() {
      this.$emit('close',false)
    },
  },
  
};
</script>

<style scoped>
div /deep/ .van-dialog {
  top: 50%;
  background-color: rgb(0, 0, 0, 0);
}
div /deep/ .van-dialog__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.bg_img {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.class_detail_title {
  font-size: 20px;
  line-height: 100px;
  color: #fff;
}
.deail_main_box {
  background-color: #fff;
  width: 85%;
  height: 350px;
  border-radius: 16px;
  margin-bottom: 10%;
  padding-top: 15px;
  overflow: scroll;
}
.deail_main_box::-webkit-scrollbar {
  display:none
}
.deail_main_item {
  padding-left: 20px;
  text-align: left;
}
.class_main {
  margin-top: 5px;
  font-size: 12px;
  color: #999;
}

.close_icon {
  position: absolute;
  right: 7%;
  top: 4%;
}
</style>
